import { useState } from "react";
import { getSvgByName } from "../../../../assets/getIcon";
import { InputField } from "../../../InputField/InputField";
import { TextArea } from "../../../TextArea/TextArea";
import { UserForm } from "../../../UserForm/UserForm";
import "./DashboardSectionRequestApp.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import axios from "axios";
import { APIURL } from "../../../../utils/apiUtils";
import toast from "react-hot-toast";
import { DefaultModalSection } from "../../../SectionsModal/DefaultModalSection/DefaultModalSection";

export const DashboardSectionRequestApp = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const [appNameInput, setAppNameInput] = useState("");
  const [appDescription, setAppDescription] = useState("");
  const [contactEmail, setContactEmail] = useState(userInfo?.email || "");

  const handleSubmit = async () => {
    const body = {
      name: appNameInput,
      description: appDescription,
      email: contactEmail,
    };
    await axios
      .post(`${APIURL}/tickets/apprequests/`, body)
      .then((response) => {
        toast.success("We got your request! We will get in touch soon");
        setAppNameInput("");
        setAppDescription("");
      })
      .catch((error) => {
        if (error.response.data.error === "Max3Requests") {
          toast.error("You can only have 3 active requests at a time");
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const description = `
  If the app is approved, we develop it for free. Make sure it's
  something that can fit in a widget, like the other apps. Be
  creative!
  `;

  return (
    <div className="apps-request-section">
      <div className="apps-request-container">
        <DefaultModalSection
          sectionIcon={"add"}
          title={"App request"}
          subTitle={"Do you have an app idea? We build it for you!"}
          description={description}
          formHeader="App request"
          handleFormSubmit={handleSubmit}
          disabledSubmit={appDescription.length < 20 || contactEmail.length < 3}
        >
          <InputField
            input={appNameInput}
            placeholder={"Superchats"}
            label={"Name (Optional) "}
            onChange={(input) => setAppNameInput(input)}
          />
          <TextArea
            input={appDescription}
            placeholder={
              "The app should be X, and when you Y it takes Z, and then it starts flying ..."
            }
            maxLength={1000}
            minLength={20}
            label={"In your words, describe the app"}
            onChange={(input) => setAppDescription(input)}
          />
          <InputField
            input={contactEmail}
            placeholder={"youre@weak.com"}
            label={"Leave us an email to get in touch"}
            onChange={(input) => setContactEmail(input)}
          />
        </DefaultModalSection>
      </div>
    </div>
  );
};
