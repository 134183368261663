// authActions.ts
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIURL, refreshToken } from "../../utils/apiUtils";
import { UserPreferencesType } from "../../utils/helpers";
import { RootState } from "../../store";
import {
  logOutExtension,
  sendTokenToExtension,
} from "../../utils/chromeExtension";

if (!APIURL) {
  console.log("ERROR NO API ENV VARIABLE");
}

export interface RegisterPayload {
  email: string;
  password: string;
  userId?: string;
  username: string;
  inviteCode: string;
}
export interface LoginPayload {
  email: string;
  password: string;
  userId?: string;
}

export const initializeApp = createAsyncThunk(
  "auth/initialize",
  async (_, { rejectWithValue, dispatch }) => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      if (user) {
        await refreshToken("Init");
        return { userInfo: user }; // adjust your Redux slice to handle this action correctly
      }
    }
    return rejectWithValue("No user found");
  }
);

export const loginUser = createAsyncThunk<
  {
    userInfo: {
      email: string;
      username?: string;
      userId: string;
    };
    completedOnboarding: boolean;
    preferences: UserPreferencesType;
  },
  LoginPayload,
  { rejectValue: string }
>("auth/login", async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${APIURL}/user/login`,
      { email, password },
      { withCredentials: true } // Include cookies in the request
    );
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.accessToken}`;
    sendTokenToExtension(response.data.extensionToken);
    return {
      userInfo: {
        email: response.data.email,
        username: response.data.username,
        userId: response.data.userId,
      },
      completedOnboarding: response.data.completedOnboarding,
      preferences: response.data.preferences,
    };
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const logOut = createAsyncThunk<void, void, { state: RootState }>(
  "auth/logout",
  async (_, { getState, dispatch }) => {
    logOutExtension();
    await axios.post(`${APIURL}/user/logout`).then((response) => {
      return true;
    });
  }
);

export const registerUser = createAsyncThunk<
  {
    userInfo: {
      email: string;
      username: string;
      userId: string;
    };
    completedOnboarding: boolean;
    preferences: UserPreferencesType;
  },
  RegisterPayload,
  { rejectValue: string }
>(
  "auth/register",
  async ({ email, password, inviteCode, username }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${APIURL}/user/signup`,
        { email, password, username, inviteCode },
        config
      );

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.accessToken}`;
      sendTokenToExtension(response.data.extensionToken);
      return {
        userInfo: {
          email: response.data.email,
          username: response.data.username,
          userId: response.data.userId,
        },
        completedOnboarding: response.data.completedOnboarding,
        preferences: response.data.preferences,
      };
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
