import React, { useEffect, useState } from "react";
import { PlaylistCard, PlaylistType } from "../PlaylistCard/PlaylistCard";

import "./SpotifyPlaylists.scss";
import { fetchUserPlaylists, loadPlaylist } from "../utils";
import { setSelectedPlaylist } from "../../../../features/spotify/spotifySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";

type SpotifyPlaylistsPropsType = {
  openPlayer: boolean;
  clickedPlaylist: () => void;
};

export const SpotifyPlaylists = ({
  openPlayer,
  clickedPlaylist,
}: SpotifyPlaylistsPropsType) => {
  const [playlists, setPlaylists] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const selectedPlaylist = useSelector(
    (state: RootState) => state.spotify.selectedPlayList
  );
  useEffect(() => {
    if (!openPlayer) {
      dispatch(setSelectedPlaylist(undefined));
    }
  }, [openPlayer]);

  useEffect(() => {
    fetchPlaylists();
  }, []);

  const fetchPlaylists = async () => {
    setIsLoading(true);
    const userPlaylists = await fetchUserPlaylists();
    setPlaylists(userPlaylists);
    setIsLoading(false);
  };

  const handleClickedPlaylist = (playlist: any) => {
    clickedPlaylist();
    dispatch(setSelectedPlaylist(playlist.uri));
    console.log("xx", openPlayer)
    if (openPlayer) {
      console.log("xyy")
      loadPlaylist(playlist.uri);
    }
  };

  const renderSkeletons = () => {
    let skeletons = [];
    for (let i = 0; i < 7; i++) {
      skeletons.push(
        <div key={i} className="spotify-playlist-skeleton">
          <div className="spotify-playlist-skeleton-image skeleton-loading"></div>
          <div className="spotify-playlist-skeleton-text-container">
            <div className="spotify-playlist-skeleton-text-title skeleton-loading"></div>
            <div className="spotify-playlist-skeleton-text-subtitle skeleton-loading"></div>
          </div>
        </div>
      );
    }
    return skeletons;
  };

  return (
    <div className="spotify-playlists-container ">
      {isLoading
        ? renderSkeletons()
        : playlists.map((playlist: PlaylistType, i: number) => {
            if (playlist.tracks.total !== 0) {
              return (
                <div
                  key={playlist.id}
                  onClick={() => handleClickedPlaylist(playlist)}
                >
                  <PlaylistCard
                    isSelected={selectedPlaylist === playlist.uri}
                    playlist={playlist}
                  />
                  {i === 1 && <div className="spotify-playlists-separator" />}
                </div>
              );
            }
          })}
    </div>
  );
};
