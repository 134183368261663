import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CategoryType } from "./categoriesSlice";
import { FolderType } from "../folders/foldersSlice";
import { APIURL } from "../../../utils/apiUtils";

if (!APIURL) {
  console.log("ERROR NO API ENV VARIABLE");
}

export const getCategories = createAsyncThunk<
  CategoryType[],
  void,
  { rejectValue: string; dispatch: any }
>("getCategories", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${APIURL}/categories`);
    const categories = response.data.categories;

    // Dispatch getCategoryFolders for each category to fetch the folders
    categories.forEach((category: CategoryType) => {
      dispatch(getCategoryFolders({ categoryId: category._id }));
    });

    return categories;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getCategoryFolders = createAsyncThunk<
  { categoryId: string; folders: FolderType[] }, // updated return type
  { categoryId: string },
  { rejectValue: string }
>("getCategoryFolders", async ({ categoryId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${APIURL}/categories/${categoryId}`);
    return {
      categoryId,
      folders: response.data.folders,
    }; // returning an object
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const createCategory = createAsyncThunk<
  { message: any }, // updated return type
  { categoryName: string },
  { rejectValue: string }
>("createCategory", async ({ categoryName }, { rejectWithValue, dispatch }) => {
  const body = {
    name: categoryName,
  };
  try {
    const response = await axios.post(`${APIURL}/categories/`, body);
    dispatch(getCategories());
    return {
      message: response.data,
    }; // returning an object
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateCategory = createAsyncThunk<
  { message: any }, // updated return type
  { categoryName: string, categoryId: string },
  { rejectValue: string }
>("updateCategory", async ({ categoryName, categoryId }, { rejectWithValue, dispatch }) => {
  const body = {
    name: categoryName,
  };
  try {
    const response = await axios.put(`${APIURL}/categories/${categoryId}`, body);
    dispatch(getCategories());
    return {
      message: response.data,
    }; // returning an object
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const deleteCategory = createAsyncThunk<
  { message: any }, // updated return type
  { categoryId: string },
  { rejectValue: string }
>("deleteCategory", async ({ categoryId }, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete(`${APIURL}/categories/${categoryId}`);
    dispatch(getCategories());
    return {
      message: response.data,
    }; // returning an object
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
