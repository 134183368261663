import { Button } from "../Button/Button";
import "./UserForm.scss";

type UserFormPropsType = {
  children: any;
  header: string;
  disabledSubmit?: boolean;
  submit: () => void;
};

export const UserForm = ({
  children,
  header,
  disabledSubmit,
  submit,
}: UserFormPropsType) => {
  return (
    <div className="user-form border-radius-l ">
      <div className="user-form-header font-weight-bold ">{header}</div>
      <div className="user-form-content">{children}</div>
      <Button label={"Send"} disabled={disabledSubmit} onClick={submit} />
    </div>
  );
};
