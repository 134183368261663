import { AppHeader } from "../AppHeader/AppHeader";
import { useEffect, useState } from "react";
import { getSvgByName } from "../../../assets/getIcon";
import { SpeakUpRoomButton } from "./SpeakUpRoomButton/SpeakUpRoomButton";
import { SpeakUpRoom } from "./SpeakUpRoom/SpeakUpRoom";
import { getAllRooms } from "./utils";
import { useNavigate } from "react-router-dom";

import "./SpeakUp.scss";
import { getCurrentWeekNumber } from "../../../utils/helpers";
import { AppWrapper } from "../AppWrapper/AppWrapper";

type SpeakUpPropsType = {};

export const SpeakUp = ({}: SpeakUpPropsType) => {
  const [openRoom, setOpenRoom] = useState<any>();
  const [rooms, setRooms] = useState<any>([]);

  const navigate = useNavigate();

  const getAvailableRooms = async () => {
    const availableRooms = await getAllRooms();
    setRooms(availableRooms);
    setOpenRoom(availableRooms[0]);
  };

  useEffect(() => {
    getAvailableRooms();
  }, []);
  const currentWeekNumber = getCurrentWeekNumber();
  return (
    <AppWrapper
      title={`The wall • Week ${currentWeekNumber}`}
      icon={"speakup"}
      openSettings={() => navigate("/speakup/rooms")}
    >
      <div className="speakup-container-wrapper">
        <div className="speakup-container-content">
          {/* <div className="speakup-sidebar">
      <div className="speakup-sidebar-rooms-list">
        {rooms &&
          rooms.map((room: any) => {
            return (
              <SpeakUpRoomButton
                active={room.id === openRoom?.id}
                room={room}
                clickedRoom={() => setOpenRoom(room)}
              />
            );
          })}
      </div>
      <button
        className={`speakup-room-create`}
        title={`Create room`}
        onClick={() => navigate("/speakup/create")}
      >
        {getSvgByName("add")}
      </button>
    </div> */}
          <div className="speakup-container-room">
            {openRoom && <SpeakUpRoom room={openRoom} />}
          </div>
        </div>
      </div>
    </AppWrapper>
  );
};
