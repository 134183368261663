// Dropdown.tsx
import React, { useState } from "react";
import "./Dropdown.scss";
import { ChevronDown } from "lucide-react";

export type DropdownItemType = {
  label: string;
  id: string;
};

interface DropdownProps {
  items: DropdownItemType[];
  label: string;
  placeholder: string;
  selectedItem: string | null;
  emptyOption?: boolean;
  setSelectedItem: (item: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  items,
  label,
  placeholder,
  selectedItem,
  emptyOption,
  setSelectedItem,
}) => {
  return (
    <div className="dropdown-container">
      <label className="label-default ">{label}</label>
      <div className="dropdown">
        <select
          value={selectedItem || ""}
          onChange={(e) => setSelectedItem(e.target.value)}
          className="dropdown-select input-field-style"
        >
          {emptyOption && (
            <option className="dropdown-option" value={"none"}>
              None
            </option>
          )}
          {items.map((item, index) => (
            <option key={index} value={item.id} className="dropdown-option">
              {item.label}
            </option>
          ))}
        </select>
        <ChevronDown />
      </div>
    </div>
  );
};

export default Dropdown;
