export const formatPrice = (price: number) => {
  if (price >= 100) {
    // Price is 100 or more, show without digits after the decimal point, with dollar sign and commas
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  } else if (price >= 1) {
    // Price is between 1 and 99.99, show with two digits after the decimal point, with dollar sign and commas
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  } else {
    // Price is less than 1, dynamically show significant digits after the last zero
    let priceStr = price.toString();
    if (priceStr.includes(".")) {
      let significantDigits = 0;
      let decimalPart = priceStr.split(".")[1];
      for (let i = 0; i < decimalPart.length; i++) {
        if (decimalPart[i] !== "0" || significantDigits > 0) {
          significantDigits++;
        }
        if (
          significantDigits > 1 ||
          (decimalPart[i] === "0" && significantDigits > 0)
        ) {
          // Cut the string to two significant digits after the last zero.
          decimalPart = decimalPart.substring(0, i + 1);
          break;
        }
      }
      priceStr = priceStr.split(".")[0] + "." + decimalPart;
    }
    // Use Intl.NumberFormat to add the dollar sign and handle any needed rounding
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 20,
    }).format(parseFloat(priceStr));
  }
};
