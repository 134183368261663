import { useEffect, useRef, MutableRefObject } from "react";

export const useClickOutside = (
  callback: () => void
): MutableRefObject<HTMLDivElement | null> => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const targetNode = event.target as Node;
      let insideModal = false;

      // Check if the click is inside an element with the class "reactclassmodal"
      if (targetNode instanceof Element) {
        insideModal = targetNode.closest('.ReactModalPortal') != null;
      }

      if (ref.current && !ref.current.contains(targetNode) && !insideModal) {
        callback(); //Clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback]);

  return ref;
};
