/*global chrome*/

export const sendTokenToExtension = (token) => {
  const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  if (!extensionId) {
    console.error("Chrome Extension ID is not set");
    return;
  }
  if (window.chrome && chrome.runtime) {
    chrome.runtime.sendMessage(
      extensionId,
      { token: token, type: "USER_TOKEN" },
      (response) => {
        console.log("Send Token Extension response", response); // Log response from the extension
      }
    );
  }
};

export const logOutExtension = () => {
  console.log("FrontEnd: LOGOUT");

  const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  if (window.chrome && chrome.runtime && chrome.runtime) {
    console.log("Logout extension");
    chrome.runtime.sendMessage(extensionId, { type: "LOGOUT" }, (response) => {
      console.log("Logout extension response", response); // Log response from the extension
    });
  }
};

export const getBookmarks = () => {
  console.log("FrontEnd: Bookamrks");

  const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  if (window.chrome && chrome.runtime && chrome.runtime) {
    chrome.runtime.sendMessage(
      extensionId,
      { type: "FETCH_BOOKMARKS" },
      (response) => {
        console.log("FETCH_BOOKMARKS extension response", response); // Log response from the extension
      }
    );
  }
};
