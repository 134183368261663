import { Link, useLocation, useNavigate } from "react-router-dom";
import "./LegalScreen.scss";
import { useEffect, useState } from "react";
import { getLegalContent } from "./legalContent";
import { getSvgByName } from "../../assets/getIcon";

type LegalScreenPropsType = {};

const legalSections = ["cookies", "termsofservice", "privacy"];

export const LegalScreen = ({}: LegalScreenPropsType) => {
  const [selectedLegalSection, setSelectedLegalSection] = useState("");
  const navigate = useNavigate();
  let location = useLocation();

  const section = location.pathname.split("/")[2];

  const getLegalSectionContent = () => {
    switch (section) {
      case "cookies":
        return {
          title: "Cookies policy",
          content: getLegalContent("cookies"),
        };
      case "termsofservice":
        return {
          title: "Terms of service",
          content: getLegalContent("termsofservice"),
        };
      case "privacy":
        return {
          title: "Privacy",
          content: getLegalContent("privacy"),
        };
      default:
        break;
    }
  };

  useEffect(() => {
    if (legalSections.includes(section)) {
      setSelectedLegalSection(section);
    } else {
      console.log("Legal section not found");
      navigate("/");
    }
  }, []);

  return (
    <div className="legal-screen-container-wrapper">
      <div className="legal-screen-container">
        <div className="legal-screen-sidebar">
          <button
            onClick={() => navigate("/")}
            className="legal-screen-logo"
          >
            {getSvgByName("millerio")}
            <h3>SleekTab</h3>
          </button>
          <Link
            to="/legal/termsofservice"
            className="legal-screen-sidebar-item"
          >
            Terms of service
          </Link>
          <Link to="/legal/cookies" className="legal-screen-sidebar-item">
            Cookies Policy
          </Link>
          <Link to="/legal/privacy" className="legal-screen-sidebar-item">
            Privacy
          </Link>
        </div>
        <div className="legal-screen-section-details">
          <h1>{getLegalSectionContent()?.title}</h1>
          <div className="legal-screen-section-details-content">
            {getLegalSectionContent()?.content}
          </div>
        </div>
      </div>
    </div>
  );
};
