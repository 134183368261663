import { useEffect, useState } from "react";
import "./DashboardSectionNews.scss";
import { getSources } from "../../../../utils/news";
import {
  getPreferencesFromLocalStorage,
  updatePreferencesInLocalStorage,
} from "../../../../utils/helpers";
import { DefaultModalSection } from "../../../SectionsModal/DefaultModalSection/DefaultModalSection";
import axios from "axios";
import { APIURL } from "../../../../utils/apiUtils";

type DashboardSectionNewsPropsType = {};

export const DashboardSectionNews = ({}: DashboardSectionNewsPropsType) => {
  const [allAvailableSources, setAllAvailableSources] = useState([]);
  const [userSources, setUserSources] = useState(
    getPreferencesFromLocalStorage()?.newsSources || []
  );

  const getAllSources = async () => {
    const allSources = await getSources();
    setAllAvailableSources(allSources);
  };

  useEffect(() => {
    getAllSources();
  }, []);

  const handleUpdateNewsSources = async (newArray: any) => {
    const body = {
      userNewsSources: newArray,
    };
    await axios
      .put(`${APIURL}/news/usernews`, body)
      .then((response) => {
        updatePreferencesInLocalStorage(response.data.preferences);
      })
      .catch((error) => {
        console.error("Error updaing crypto widget", error);
      });
  };

  const handleClickedSource = (item: string) => {
    let updatedNewsSources = [];

    if (userSources.includes(item)) {
      updatedNewsSources = userSources.filter((source: any) => source !== item);
    } else {
      updatedNewsSources = [...userSources, item];
    }

    setUserSources(updatedNewsSources);
    handleUpdateNewsSources(updatedNewsSources);
  };

  return (
    <div className="dashboard-section-news-container">
      <DefaultModalSection
        sectionHeader
        sectionIcon={"news"}
        title={"Timeline settings"}
        subTitle={"Pick your sources"}
      />
      {allAvailableSources.map((category: any) => (
        <div
          key={category.category}
          className="dashboard-section-news-category"
        >
          <div className="dashboard-section-news-category-header">
            {category.category}
          </div>
          <div className="dashboard-section-news-category-sources">
            {category.sources.map((source: any) => (
              <button
                key={source.name}
                onClick={() => handleClickedSource(source.name)}
                className={`dashboard-section-news-category-source-button smooth-border ${
                  userSources.includes(source.name)
                    ? "dashboard-section-news-category-source-button__selected"
                    : ""
                } `}
              >
                <img src={source.favicon} />
                {source.name}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
