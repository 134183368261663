import { BookmarksSidebar } from "./BookmarksSidebar/BookmarksSidebar";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import BookmarksListContainer from "./BookmarksListContainer/BookmarksListContainer";
import { useEffect, useState } from "react";
import { getBookmarks } from "../../features/bookmarks/bookmarks/bookmarksActions";
import { AppDispatch, RootState } from "../../store";

import "./Bookmarks.scss";
import { BookmarkType } from "../../features/bookmarks/bookmarks/bookmarksSlice";
import {
  getFolderBookmarks,
  getFolders,
} from "../../features/bookmarks/folders/foldersActions";
import { getCategories } from "../../features/bookmarks/categories/categoriesActions";
import { useClickOutside } from "../../hooks/useClickOutside";
import { searchBookmarks } from "./utils";

type BookmarksPropsType = {};

export const Bookmarks = ({}: BookmarksPropsType) => {
  const [selectedSection, setSelectedSection] = useState<string | undefined>();
  const [sectionTitle, setSectionTitle] = useState<string | undefined>();
  const [bookmarksList, setBookmarksList] = useState<BookmarkType[] | []>([]);

  const [selectedFolderCategory, setSelectedFolderCategory] = useState<
    string | undefined
  >();

  const dispatch: AppDispatch = useDispatch();

  const bookmarksState = useSelector((state: RootState) => state.bookmarks);
  const folderState = useSelector((state: RootState) => state.folders);

  const { bookmarks, loading: loadingBookmarks } = bookmarksState;
  const { folders, folderBookmarks, loading: loadingFolder } = folderState;

  const wrapperRef = useClickOutside(() => {
    setBookmarksList([]);
    setSectionTitle(undefined);
    setSelectedSection(undefined);
  });

  useEffect(() => {
    dispatch(getBookmarks());
    dispatch(getCategories());
    dispatch(getFolders());
  }, []);

  useEffect(() => {
    if (!selectedSection) return;

    handleSectionChange();
  }, [selectedSection, bookmarks, folders]);

  useEffect(() => {
    setBookmarksList(folderBookmarks);
  }, [folderBookmarks]);

  const handleSectionChange = async () => {
    if (selectedSection === "everything") {
      setBookmarksList(bookmarks);
      setSectionTitle("Everything");
    } else if (selectedSection && selectedSection.startsWith("folder-")) {
      const selectedFolderId = selectedSection.split("-")[1];
      const filteredFolder = folders.find(
        (folder) => folder._id === selectedFolderId
      );

      if (filteredFolder) {
        dispatch(getFolderBookmarks({ folderId: selectedFolderId }));
        setBookmarksList(folderBookmarks);
        setSectionTitle(filteredFolder.name);
        setSelectedFolderCategory(filteredFolder.categoryId || undefined);
      } else {
        setSelectedSection("everything");
      }
    } else if (selectedSection && selectedSection.startsWith("search")) {
      setBookmarksList([]);
      setSectionTitle("Search");
      const query = selectedSection.split(":")[1];
      const searchResults = await searchBookmarks(query);
      setBookmarksList(searchResults);
    }
  };

  return (
    <div className="bookmarks-section-wrapper">
      <div className="bookmarks-section">
        <div className="bookmarks-container-wrapper" ref={wrapperRef}>
          <div
            className={`bookmarks-container ${
              selectedSection ? "bookmarks-container-open-list" : ""
            } `}
          >
            <div className={`bookmarks-sidebar-section   `}>
              <div className="bookmarks-sidebar-section-wrapper main-app-background">
                <BookmarksSidebar
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                />
              </div>
            </div>
            <div className="bookmark-list-section-wrapper">
              {selectedSection && (
                <div className="bookmarks-list-section main-app-background">
                  <BookmarksListContainer
                    isListLoading={loadingBookmarks || loadingFolder}
                    selectedSection={selectedSection}
                    sectionTitle={sectionTitle}
                    bookmarksList={bookmarksList}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
