import { useNavigate } from "react-router-dom";
import "./PresentationHeroSection.scss";
import { Button } from "../../Button/Button";
import { PresentationCTA } from "../PresentationCTA/PresentationCTA";

type PresentationHeroSectionPropsType = {};

export const PresentationHeroSection =
  ({}: PresentationHeroSectionPropsType) => {
    const navigate = useNavigate();

    return (
      <div className="presentation-hero-section-container">
        <h1>Your smart homepage.</h1>
        <h2>Powerful, customizable and AI driven.</h2>
        <PresentationCTA />
      </div>
    );
  };
