import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { VariableSizeList as List } from "react-window";

import { BookmarkType } from "../../../features/bookmarks/bookmarks/bookmarksSlice";
import { BookmarksListItem } from "./BookmarkListItem/BookmarkListItem";

import ArrowIcon from "../../../assets/icons/arrow.png";

import { modalCustomStyles } from "../../../utils/helpers";
import { ModalHeader } from "../../ModalHeader/ModalHeader";
import { BookmarkItemModal } from "../BookmarkItemModal/BookmarkItemModal";

import "./BookmarksListContainer.scss";
import { IconButton } from "../../IconButton/IconButtont";
import { getSvgByName } from "../../../assets/getIcon";
import React from "react";
import { Spinner } from "../../Spinner/Spinner";

type BookmarksListContainerPropsType = {
  bookmarksList: BookmarkType[];
  selectedSection: string;
  sectionTitle?: string;
  isListLoading?: boolean;
};

const BookmarksListContainer = ({
  bookmarksList,
  selectedSection,
  sectionTitle,
  isListLoading,
}: BookmarksListContainerPropsType) => {
  const [isOpenAddBookmarkModal, setIsOpenAddBookmarkModal] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [selectedFolderId, setSelectedFolderId] = useState<
    string | undefined
  >();

  const isSearchContainer = sectionTitle === "Search";

  useEffect(() => {
    if (selectedSection.startsWith("folder-")) {
      const selectedFolderId = selectedSection.split("-")[1];
      setSelectedFolderId(selectedFolderId);
    } else {
      setSelectedFolderId(undefined);
    }
  }, [selectedSection]);

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const settingsModalHeader = selectedFolderId
    ? `Save in ${sectionTitle}`
    : "Add bookmark";

  const getHeaderIcon = () => {
    switch (sectionTitle) {
      case "Everything":
        return "save";
      case "Search":
        return "search";
      default:
        return "folder";
    }
  };

  const secondaryHeaderText =
    bookmarksList.length === 1
      ? isSearchContainer
        ? "Result"
        : "Item"
      : isSearchContainer
      ? "Results"
      : "Items";

  const getItemSize = (index: number) => {
    const bookmark = bookmarksList[index];
    return bookmark.description && bookmark.description !== "None" ? 95 : 77;
  };
  const listRef = useRef<any>();

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [bookmarksList]);

  const Row = ({ index, style }: { index: any; style: any }) => (
    <div style={style}>
      <BookmarksListItem
        key={`${bookmarksList[index]._id}`}
        bookmark={bookmarksList[index]}
      />
    </div>
  );

  const listHeight = screenHeight - 50; // Header height

  return (
    <div className="bookmarks-list-container-wrapper border-radius-s ">
      <div className="bookmarks-list-container-header padding-x-s ">
        <div className="bookmarks-list-container-header-section-details">
          {getSvgByName(getHeaderIcon())}
          <p>{sectionTitle}</p>
          <span>•</span>
          <p className="font-size-s">
            {bookmarksList.length} {secondaryHeaderText}
          </p>
        </div>
        {!isSearchContainer && (
          <IconButton
            onClick={() => setIsOpenAddBookmarkModal(true)}
            icon="add-bookmark"
          />
        )}
      </div>
      <div className="bookmarks-list-container">
        <div className="bookmarks-list-container-scroll">
          {isListLoading ? (
            <div className="bookmarks-loading-container">
              <Spinner />
            </div>
          ) : bookmarksList.length > 0 ? (
            <List
              ref={listRef} // Attach the ref to the List
              height={listHeight}
              itemCount={bookmarksList.length}
              itemSize={getItemSize}
              width="100%"
            >
              {Row}
            </List>
          ) : (
            <div className="bookmarks-list-container-scroll-empty">
              {isSearchContainer ? (
                <p className="info-text">No search results</p>
              ) : (
                <>
                  <p className="info-text">
                    Save items with the extension or click here
                  </p>
                  <img alt="indicator arrow" src={ArrowIcon} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpenAddBookmarkModal}
        onRequestClose={() => setIsOpenAddBookmarkModal(false)}
        style={modalCustomStyles}
        contentLabel="Example Modal"
        closeTimeoutMS={100}
      >
        <ModalHeader
          icon={"add-bookmark"}
          title={settingsModalHeader}
          onModalClose={() => setIsOpenAddBookmarkModal(false)}
        />
        <BookmarkItemModal
          folderId={selectedFolderId}
          onModalClose={() => setIsOpenAddBookmarkModal(false)}
        />
      </Modal>
    </div>
  );
};

export default React.memo(BookmarksListContainer);
