import { getSvgByName } from "../../../../assets/getIcon";
import { IconButton } from "../../../IconButton/IconButtont";
import { formatPrice } from "../cryptoUtils";
import "./CryptoListItem.scss";

export type CryptoListItemType = {
  coinMarketCap: number;
  coinPriceChange1H: number;
  coinPriceChange7D: number;
  coinPriceChange24H: number;
  coinPriceChange30D: number;
  convert: string;
  link: string;
  logo: string;
  name: string;
  price: number;
  symbol: string;
  _id: string;
};

type CryptoListItemPropsType = {
  item: CryptoListItemType;
  rank: number;
  isSelected: boolean;
  clickedItem: () => void;
};

export const CryptoListItem = ({
  item,
  rank,
  isSelected,
  clickedItem,
}: CryptoListItemPropsType) => {
  return (
    <div className="widget-crypto-list-item-container">
      <div className="widget-crypto-list-item-rank">{rank}</div>
      <div className="widget-crypto-list-item-name">
        <img src={item.logo} /> {item.name}
      </div>
      <div className="widget-crypto-list-item-price">
        {formatPrice(item.price)}
      </div>
      <div className="widget-crypto-list-item-market-cap">
        {item.coinMarketCap.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        })}
      </div>
      <div
        className={`widget-crypto-list-item-change ${
          item.coinPriceChange1H < 0
            ? "widget-crypto-list-item-change__negative"
            : ""
        }`}
      >
        {getSvgByName("chevron")}
        {Math.abs(item.coinPriceChange1H).toFixed(2)}
      </div>
      <div
        className={`widget-crypto-list-item-change ${
          item.coinPriceChange24H < 0
            ? "widget-crypto-list-item-change__negative"
            : ""
        }`}
      >
        {getSvgByName("chevron")}
        {Math.abs(item.coinPriceChange24H).toFixed(2)}
      </div>
      <div
        className={`widget-crypto-list-item-change ${
          item.coinPriceChange7D < 0
            ? "widget-crypto-list-item-change__negative"
            : ""
        }`}
      >
        {getSvgByName("chevron")}
        {Math.abs(item.coinPriceChange7D).toFixed(2)}
      </div>
      <div
        className={`widget-crypto-list-item-change ${
          item.coinPriceChange30D < 0
            ? "widget-crypto-list-item-change__negative"
            : ""
        }`}
      >
        {getSvgByName("chevron")}
        {Math.abs(item.coinPriceChange30D).toFixed(2)}
      </div>
      <div
        className={`widget-crypto-list-item-select ${
          isSelected ? "widget-crypto-list-item-select__selected" : ""
        } `}
      >
        <IconButton onClick={clickedItem} icon={"favorite"} />
      </div>
    </div>
  );
};
