import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getBookmarks } from "../bookmarks/bookmarksActions";
import { FavoriteType } from "./favoritesSlice";
import { APIURL } from "../../../utils/apiUtils";

if (!APIURL) {
  console.log("ERROR NO API ENV VARIABLE");
}

export const getFavorites = createAsyncThunk<
  FavoriteType[],
  void,
  { rejectValue: string }
>("favorites", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${APIURL}/favorites`);
    return response.data;
  } catch (error: any) {
    // This is just a generic example; you need to adjust it to match the structure of your actual API error response
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const addFavorite = createAsyncThunk<
  FavoriteType,
  { name: string; url: string; bookmarkId?: string },
  { rejectValue: string }
>("addFavorite", async (favoriteItem, { rejectWithValue, dispatch }) => {
  try {
    type AddFavoriteBodyType = {
      name: string;
      url: string;
      bookmarkId: string | null;
    };

    const body: AddFavoriteBodyType = {
      name: favoriteItem.name,
      url: favoriteItem.url,
      bookmarkId: null,
    };

    if (favoriteItem.bookmarkId) {
      body.bookmarkId = favoriteItem.bookmarkId;
    }

    const response = await axios.post(`${APIURL}/favorites`, body);
    dispatch(getFavorites());
    dispatch(getBookmarks());
    return response.data;
  } catch (error: any) {
    // This is just a generic example; you need to adjust it to match the structure of your actual API error response
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const deleteFavorite = createAsyncThunk<
  FavoriteType,
  { id: string },
  { rejectValue: string }
>("deleteFavorite", async (favorite, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete(`${APIURL}/favorites/${favorite.id}`);
    dispatch(getFavorites());
    return response.data.bookmarks; // You should return response.data to get the actual data from the API
  } catch (error: any) {
    // This is just a generic example; you need to adjust it to match the structure of your actual API error response
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateFavorite = createAsyncThunk<
  FavoriteType,
  { id: string; name: string; url: string; folderId?: string | null },
  { rejectValue: string }
>("updateFavorite", async (favorite, { rejectWithValue, dispatch }) => {
  try {
    const body = {
      name: favorite.name,
      url: favorite.url,
    };
    const response = await axios.put(
      `${APIURL}/favorites/${favorite.id}`,
      body
    );
    dispatch(getFavorites());
    return response.data.bookmarks; // You should return response.data to get the actual data from the API
  } catch (error: any) {
    // This is just a generic example; you need to adjust it to match the structure of your actual API error response
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
