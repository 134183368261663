import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createCategory,
  deleteCategory,
  getCategories,
  getCategoryFolders,
  updateCategory,
} from "./categoriesActions";
import { FolderType } from "../folders/foldersSlice";
import toast from "react-hot-toast";

export type CategoryType = {
  name: string;
  _id: string;
  folders: FolderType[];
};

export interface CategoryState {
  loading: boolean;
  categories: CategoryType[];
  error: boolean;
  success: boolean;
}

const initialState: CategoryState = {
  loading: false,
  categories: [],
  error: false,
  success: false,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        getCategories.fulfilled,
        (state, action: PayloadAction<CategoryType[]>) => {
          state.loading = false;
          state.error = false;
          state.success = true;
          state.categories = action.payload;
        }
      )
      .addCase(getCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(getCategoryFolders.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCategoryFolders.fulfilled,
        (
          state,
          action: PayloadAction<{ categoryId: string; folders: FolderType[] }>
        ) => {
          state.loading = false;
          const { categoryId, folders } = action.payload;
          const categoryIndex = state.categories.findIndex(
            (c) => c._id === categoryId
          );
          if (categoryIndex !== -1) {
            state.categories[categoryIndex].folders = folders;
          }
        }
      )
      .addCase(getCategoryFolders.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCategory.fulfilled, (state) => {
        toast.dismiss();
        toast.success("Category created");
        state.loading = false;
        state.error = false;
        state.success = true;
      })
      .addCase(createCategory.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, please try again");
        state.loading = false;
        state.error = true;
      })
      .addCase(updateCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCategory.fulfilled, (state) => {
        toast.dismiss();
        toast.success("Saved");
        state.loading = false;
        state.error = false;
        state.success = true;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, please try again");
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCategory.fulfilled, (state) => {
        toast.dismiss();
        toast.success("Category deleted");
        state.loading = false;
        state.error = false;
        state.success = true;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, please try again");
        state.loading = false;
        state.error = true;
      });
  },
});

export default categoriesSlice.reducer;
