import { useEffect, useState } from "react";
import { getSvgByName } from "../../../assets/getIcon";
import "./PresentationNavbar.scss";
import { useNavigate } from "react-router-dom";
import { PresentationCTA } from "../PresentationCTA/PresentationCTA";

type PresentationNavbarPropsType = { loginScreen?: boolean };

export const PresentationNavbar = ({
  loginScreen,
}: PresentationNavbarPropsType) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <div
      className={`presentation-navbar-wrapper ${
        isScrolled ? "presentation-navbar-wrapper__is-scrolled" : ""
      }`}
    >
      <div className="presentation-navbar">
        <button
          onClick={() => loginScreen && navigate("/home")}
          className="presentation-navbar-logo"
        >
          {getSvgByName("millerio")}
          <h2>SleekTab</h2>
        </button>
        {!loginScreen && <PresentationCTA />}
      </div>
    </div>
  );
};
