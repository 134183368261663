import { useEffect, useState } from "react";
import { InputField } from "../../InputField/InputField";
import "./BookmarkItemModal.scss";
import { Button } from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addBookmark,
  deleteBookmark,
  updateBookmark,
} from "../../../features/bookmarks/bookmarks/bookmarksActions";
import { AppDispatch, RootState } from "../../../store";
import {
  addFavorite,
  deleteFavorite,
  updateFavorite,
} from "../../../features/bookmarks/favorites/favoritesAction";
import Dropdown, { DropdownItemType } from "../../Dropdown/Dropdown";
import toast from "react-hot-toast";
import { IconButton } from "../../IconButton/IconButtont";
import { TextArea } from "../../TextArea/TextArea";

type BookmarkItemModalPropsType = {
  editLogic?: boolean;
  currentName?: string;
  id?: string;
  currentUrl?: string;
  description?: string;
  folderId?: string | null;
  fromFavoritesBar?: boolean;
  onModalClose: () => void;
};

export const BookmarkItemModal = ({
  editLogic,
  currentName,
  currentUrl,
  description,
  fromFavoritesBar,
  folderId,
  id,
  onModalClose,
}: BookmarkItemModalPropsType) => {
  const [nameInputValue, setNameInputValue] = useState(currentName || "");
  const [descriptionInputValue, setDescriptionInputValue] = useState(
    description || ""
  );
  const [urlInputValue, setUrlInputValue] = useState(currentUrl || "");
  const [isAddFavoriteLoading, setIsAddFavorite] = useState(false);
  const [isAddBookmarkLoading, setIsAddBookmarkLoading] = useState(false);
  const [folderSelectedIdValue, setFolderSelectedIdValue] = useState<
    string | null
  >(folderId || null);
  const [isSavedInFavoritesBar, setIsSavedInFavoritesBar] = useState(false);

  const folderState = useSelector((state: RootState) => state.folders);
  const favoritesState = useSelector((state: RootState) => state.favorites);
  const bookmarksState = useSelector((state: RootState) => state.bookmarks);
  const dispatch: AppDispatch = useDispatch();

  const { folders } = folderState;
  const { favorites } = favoritesState;

  useEffect(() => {
    const bookmarkIdsInFavorites = favorites.map((item) => item.bookmarkId);
    if (id && bookmarkIdsInFavorites.includes(id)) {
      setIsSavedInFavoritesBar(true);
    } else {
      setIsSavedInFavoritesBar(false);
    }
  }, [favorites, id]);

  useEffect(() => {
    if (isAddFavoriteLoading && favoritesState.success) {
      onModalClose();
      setIsAddFavorite(false);
    }
  }, [isAddFavoriteLoading, favoritesState.success, onModalClose]);

  useEffect(() => {
    if (isAddBookmarkLoading && bookmarksState.success) {
      onModalClose();
      setIsAddBookmarkLoading(false);
    }
  }, [isAddBookmarkLoading, bookmarksState.success, onModalClose]);

  const handleAddFavorite = () => {
    if (urlInputValue.length > 0) {
      let favorite: { name: string; url: string; bookmarkId?: string } = {
        name: nameInputValue,
        url: urlInputValue,
      };
      if (id) {
        favorite.bookmarkId = id;
      }
      setIsAddFavorite(true);
      dispatch(addFavorite(favorite));
    } else {
      toast.error("The url is required");
    }
  };

  const handleAddBookmark = () => {
    if (urlInputValue.length > 0) {
      const bookmark: {
        name: string;
        url: string;
        folderId: null | string;
      } = {
        name: nameInputValue,
        url: urlInputValue,
        folderId: null,
      };
      if (folderSelectedIdValue && folderSelectedIdValue !== "none") {
        bookmark.folderId = folderSelectedIdValue;
      }
      setIsAddBookmarkLoading(true);
      dispatch(addBookmark(bookmark));
    } else {
      toast.error("The url is required");
    }
  };

  const handleEditBookmark = () => {
    const notChanged =
      currentName === nameInputValue &&
      currentUrl === urlInputValue &&
      description === descriptionInputValue &&
      folderId === folderSelectedIdValue;
    if (notChanged) {
      onModalClose();
    } else {
      if (nameInputValue.length > 0 && urlInputValue.length > 0 && id) {
        const apiInfo: {
          id: string;
          name: string;
          description: string;
          url: string;
          folderId: null | string;
        } = {
          id: id,
          name: nameInputValue,
          description: descriptionInputValue,
          url: urlInputValue,
          folderId: null,
        };
        if (folderSelectedIdValue) {
          apiInfo.folderId = folderSelectedIdValue;
        }
        if (fromFavoritesBar) {
          dispatch(updateFavorite(apiInfo));
        } else {
          dispatch(updateBookmark(apiInfo));
        }
        onModalClose();
      } else {
        toast.error("Name and URL are required");
      }
    }
  };

  const handleDeleteBookmark = () => {
    if (id) {
      dispatch(deleteBookmark({ id: id }));
    } else {
      console.log("Missing id");
    }
  };

  const handleRemovefromFavoritesBar = () => {
    if (fromFavoritesBar) {
      if (id) {
        dispatch(deleteFavorite({ id: id }));
        onModalClose();
      } else {
        console.log("Missing id");
      }
    }
  };

  const dropdownItems: DropdownItemType[] = folders.map((folder) => {
    return {
      label: folder.name,
      id: folder._id,
    };
  });

  return (
    <div
      className={`add-bookmark-container-wrapper ${
        !editLogic ? "add-bookmark-container-wrapper-single-button" : ""
      }`}
    >
      <div className="add-bookmark-container">
        <InputField
          label="Name"
          input={nameInputValue}
          autoFocus
          placeholder={"Add name"}
          onChange={(input: string) => setNameInputValue(input)}
        />
        {!fromFavoritesBar && editLogic && (
          <TextArea
            label="Notes"
            maxLength={1000}
            input={descriptionInputValue}
            placeholder={"Your notes"}
            onChange={(input: string) => setDescriptionInputValue(input)}
          />
        )}
        <InputField
          label="Url"
          input={urlInputValue}
          placeholder={"Add url"}
          onChange={(input: string) => setUrlInputValue(input)}
        />
        {!fromFavoritesBar && (
          <Dropdown
            items={dropdownItems}
            label={"Folder"}
            placeholder={"Select a folder"}
            emptyOption
            selectedItem={folderSelectedIdValue}
            setSelectedItem={(item) => setFolderSelectedIdValue(item)}
          />
        )}
        <div className="add-bookmark-buttons-container">
          {editLogic ? (
            <div className="add-bookmark-buttons-container-edit">
              <IconButton
                onClick={
                  fromFavoritesBar
                    ? handleRemovefromFavoritesBar
                    : handleDeleteBookmark
                }
                remove
                title={fromFavoritesBar ? "Remove favorite" : "Delete"}
                icon={fromFavoritesBar ? "unfavorite" : "trash"}
              />

              {!fromFavoritesBar && !isSavedInFavoritesBar && (
                <div className="add-bookmark-buttons-container-edit__favorite">
                  <IconButton
                    title="Add to favorites"
                    icon="favorite"
                    onClick={handleAddFavorite}
                  />
                </div>
              )}
              <Button submitButton onClick={handleEditBookmark} label="Save" />
            </div>
          ) : (
            <Button
              loading={
                fromFavoritesBar ? isAddFavoriteLoading : isAddBookmarkLoading
              }
              submitButton
              onClick={fromFavoritesBar ? handleAddFavorite : handleAddBookmark}
              label="Save"
            />
          )}
        </div>
      </div>
    </div>
  );
};
