import "./SpeakUpRoomFilters.scss";

export type SpeakUpFilterType = "new" | "top";

type SpeakUpRoomFiltersPropsType = {
  selectedFilter: SpeakUpFilterType;
  setFilter: (filter: SpeakUpFilterType) => void;
};

export const SpeakUpRoomFilters = ({
  setFilter,
  selectedFilter,
}: SpeakUpRoomFiltersPropsType) => {
  return (
    <div
      className={`speakup-room-header-settings-filter  ${
        selectedFilter === "new"
          ? "speakup-room-header-settings-filter__new"
          : ""
      } `}
    >
      <div className="speakup-room-header-settings-filter-buttons">
        <button
          onClick={() => setFilter("new")}
          className={`speakup-room-header-settings-filter-button`}
        >
          New
        </button>
        <button
          onClick={() => setFilter("top")}
          className={`speakup-room-header-settings-filter-button`}
        >
          Top
        </button>
      </div>
    </div>
  );
};
