import QuotesIcon from "../../assets/appsIcons/quote.png";
import ClockIcon from "../../assets/icons/widgets/clock.png";
import SpeakUpIcon from "../../assets/appsIcons/board.png";
import WeatherIcon from "../../assets/icons/widgets/weather.png";
import CryptoIcon from "../../assets/appsIcons/crypto.png";
import SpotifyIcon from "../../assets/appsIcons/spotify.png";
import GoogleIcon from "../../assets/appsIcons/google-white.png";
import AssitantLogo from "../../assets/appsIcons/assistant.png";
import BookmarksIcon from "../../assets/appsIcons/bookmarks.png";

export const topCenter = ["quotes"];
export const bottomCenter = ["assistant"];

export const availableWidgets = [
  {
    id: "clock",
    title: "Clock",
    icon: ClockIcon,
  },
  {
    id: "weather",
    title: "Weather",
    icon: WeatherIcon,
  },
  {
    id: "google",
    title: "Google",
    icon: GoogleIcon,
  },
  {
    id: "assistant",
    title: "Assistant",
    icon: AssitantLogo,
  },
  {
    id: "quotes",
    title: "Quotes",
    icon: QuotesIcon,
  },
];

export const availableApps = [
  {
    id: "bookmarks",
    title: "Bookmarks",
    icon: <img src={BookmarksIcon} />,
  },
  {
    id: "crypto",
    title: "Crypto",
    icon: <img src={CryptoIcon} />,
  },
  {
    id: "spotify",
    title: "Spotify",
    icon: <img src={SpotifyIcon} />,
  },
  {
    id: "speakup",
    title: "The wall",
    icon: <img src={SpeakUpIcon} />,
  },
  {
    id: "assistant",
    title: "Assistant",
    icon: <img src={AssitantLogo} />,
  },
];
