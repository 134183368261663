import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { modalCustomStylesSections } from "../../utils/helpers";
import { ModalHeader } from "../ModalHeader/ModalHeader";
import { DashboardModal } from "../Apps/Dashboard/DashboardModal";
import { PublicProfile } from "../PublicProfile/PublicProfile";
import { SpeakUpDashboard } from "../Apps/SpeakUp/SpeakUpDashboard/SpeakUpDashboard";
import {
  Onboarding,
  onBoardingModalStyles,
  onBoardingModalStylesApps,
  onBoardingModalStylesBookmarks,
  onBoardingModalStylesTimeline,
} from "../Onboarding/Onboarding";
import { useEffect, useState } from "react";
import { Customization } from "../Customization/Customization";
import { Market } from "../Market/Market";

export const ModalComponent = () => {
  const [selectedModalStyle, setSelectedModalStyle] = useState(
    modalCustomStylesSections
  );
  const navigate = useNavigate();
  const params = useParams();
  const sectionId = params["*"];
  const modalId = params["modalId"];

  useEffect(() => {
    switch (modalId) {
      case "onboarding":
        switch (sectionId) {
          case "1":
            setSelectedModalStyle(onBoardingModalStyles);
            break;
          case "2":
            setSelectedModalStyle(onBoardingModalStylesBookmarks);
            break;
          case "3":
            setSelectedModalStyle(onBoardingModalStylesApps);
            break;
          case "4":
            setSelectedModalStyle(onBoardingModalStylesTimeline);
            break;
          default:
            setSelectedModalStyle(onBoardingModalStyles);
            break;
        }
        break;
      default:
        setSelectedModalStyle(modalCustomStylesSections);
        break;
    }
  }, [params, modalId]);

  const closeModal = () => {
    navigate("/");
  };

  const getModalConfig = () => {
    let config;
    switch (modalId) {
      case "dashboard":
        config = {
          icon: "settings",
          title: "Dashboard",
          component: <DashboardModal />,
        };
        break;
      case "profile":
        config = {
          icon: "user",
          title: "Profile",
          component: <PublicProfile />,
        };
        break;
      case "market":
        config = {
          icon: "store",
          title: "Market",
          component: <Market />,
        };
        break;
      case "customization":
        config = {
          icon: "theme",
          title: "Customization",
          component: <Customization />,
        };
        break;
      case "speakup":
        config = {
          icon: "speakup",
          title: "The Wall",
          component: <SpeakUpDashboard />,
        };
        break;
      case "onboarding":
        config = {
          icon: "hug",
          title: "Onboarding",
          component: <Onboarding />,
        };
        break;
      default: {
        config = {
          icon: "settings",
          title: "You got lost! This section doesn't exist",
        };
      }
    }
    return config;
  };

  const modalConfig = getModalConfig();

  return (
    <Modal
      isOpen={true}
      onRequestClose={() =>
        modalId === "onboarding" ? undefined : closeModal()
      }
      style={selectedModalStyle}
      contentLabel={`${modalId} Modal`}
      closeTimeoutMS={200}
    >
      <ModalHeader
        icon={modalConfig?.icon}
        title={modalConfig.title}
        hideClose={modalId === "onboarding"}
        onModalClose={() =>
          modalId === "onboarding" ? undefined : closeModal()
        }
      />
      {modalConfig.component}
    </Modal>
  );
};
