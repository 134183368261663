import { getVideoThumbnail } from "../../utils/helpers";
import { IconButton } from "../IconButton/IconButtont";
import "./WallpaperPackItem.scss";

export type WallpaperPackType = {
  id: string;
  live: boolean;
  name: string;
  thumbnail: string;
  description: string;
  creator: string;
  ownedByUser?: boolean;
  cost?: number | string;
  wallpapersCount: number;
};

type WallpaperPackItemPropsType = {
  banner?: string;
  inLibrary?: boolean;
  wallpaperPack: WallpaperPackType;
  clickedDelete?: () => void;
  clicked: () => void;
};

export const WallpaperPackItem = ({
  wallpaperPack,
  banner,
  inLibrary,
  clickedDelete,
  clicked,
}: WallpaperPackItemPropsType) => {
  return (
    <div
      className={`wallpaper-settings-library-item border-radius-s font-size-xl font-weight-semi-bold icon-button-item-hoverered ${
        banner ? "wallpaper-settings-library-item__selected" : ""
      }  `}
      role="button"
      onClick={() => clicked()}
      style={{
        backgroundImage: wallpaperPack.live
          ? `url(${getVideoThumbnail(wallpaperPack.name)})`
          : `url(${wallpaperPack.thumbnail})`,
      }} // Applying background image here
    >
      {inLibrary ? (
        <IconButton
          onClick={() =>
            clickedDelete
              ? clickedDelete()
              : console.log("missing delete function")
          }
          remove
          title={"Remove"}
          itemHover
          icon={"trash"}
        />
      ) : (
        <div className="wallpaper-price-tag font-size-s font-weight-semi-bold ">
          {wallpaperPack.cost || "Free"}
        </div>
      )}
      <div className="wallpaper-applied-banner font-size-m ">{banner}</div>
      <p className="wallpaper-item-name ">{wallpaperPack.name}</p>
      <p className="wallpaper-item-description font-size-s italic">
        {" "}
        {wallpaperPack.description}
      </p>
      <div className="wallpaper-settings-library-item-details font-size-s ">
        {wallpaperPack.live ? (
          <p className="live-wallpaper-tag font-size-s">Live </p>
        ) : (
          <span>{wallpaperPack.wallpapersCount} Items</span>
        )}
        <span>{wallpaperPack.creator}</span>
      </div>
    </div>
  );
};
