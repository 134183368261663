import { ReactNode, useState } from "react";
import { getSvgByName } from "../../assets/getIcon";
import "./SectionsModal.scss";
import { Link, useParams } from "react-router-dom";

type ModalNavigationItemType = {
  id: string;
  label: string;
  icon: string;
  content: ReactNode;
};

type SectionsModalPropsType = {
  navigationItems: ModalNavigationItemType[];
  separatorIndex?: number;
};

export const SectionsModal = ({
  navigationItems,
  separatorIndex,
}: SectionsModalPropsType) => {
  const params = useParams();
  const sectionId = params["*"];

  return (
    <div className="sections-modal-container-wrapper">
      <div className="sections-modal-navigation">
        {navigationItems.map((item, i) => {
          return (
            <div key={item.id}>
              <Link
                key={`sections-modal-item-nav-${item.id}`}
                className={`sections-modal-navigation-item font-size-s ${
                  sectionId === item.id
                    ? "sections-modal-navigation-item--active"
                    : ""
                }`}
                to={`/${params.modalId}/${item.id}`}
              >
                {getSvgByName(item.icon)}

                {item.label}
              </Link>
              {separatorIndex === i && <div className="navigation-separator" />}
            </div>
          );
        })}
      </div>
      <div className="sections-modal-content">
        {
          navigationItems.filter((item) => item.id === sectionId)[0]
            .content
        }
      </div>
    </div>
  );
};
