import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../store";
import { getSvgByName } from "../assets/getIcon";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const auth = useSelector((state: RootState) => state.auth);
  let location = useLocation();

  useEffect(() => {
    const isLoggedIn = auth.userInfo ? true : false;
    setIsAuthLoading(auth.loading);
    setIsLoggedIn(isLoggedIn);
  }, [auth]);

  if (isAuthLoading) {
    return <div className="loading-app" >{getSvgByName('millerio')}</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  return <>{children}</>; 
};

export default ProtectedRoute;
