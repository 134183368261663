import "./PresentationWall.scss";

import PresentationWallPic from "../../../assets/sleektab.png";
import { Button } from "../../Button/Button";
import { PresentationCTA } from "../PresentationCTA/PresentationCTA";

type PresentationWallPropsType = {};

export const PresentationWall = ({}: PresentationWallPropsType) => {
  return (
    <div className="presentation-screen-wall-container">
      <div>
        <h1>You lead the way.</h1>
        <h2>The community shapes the future of SleekTab.</h2>
        <p>
          Use "The wall" to suggest and vote ideas that decide the path of
          SleekTab.
        </p>
        <p>
          When users lead the way, products transcend their basic purpose. They
          become extensions of the community's collective will, embodying our
          aspirations and dreams.
        </p>
        <p>
          The SleekTab community doesn't just deserve credit for what SleekTab
          is today—it's the heartbeat of everything we aspire to be.
        </p>
        <PresentationCTA />
      </div>
      <div className="presentation-screen-wall-image-wrapper" >
        <img src={PresentationWallPic} />
      </div>
    </div>
  );
};
