import { Assistant } from "../components/Apps/Assistant/Assistant";
import { Crypto } from "../components/Apps/Crypto/Crypto";
import { SpeakUp } from "../components/Apps/SpeakUp/SpeakUp";
import { Spotify } from "../components/Apps/Spotify/Spotify";
import { Bookmarks } from "../components/Bookmarks/Bookmarks";

export const getOpenApp = (app: string | undefined) => {
  switch (app) {
    case "crypto":
      return <Crypto />;
    case "spotify":
      return <Spotify />;
    case "speakup":
      return <SpeakUp />;
    case "bookmarks":
      return <Bookmarks />;
    case "assistant":
      return <Assistant />;
    default:
      break;
  }
};
