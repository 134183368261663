import { getSvgByName } from "../../assets/getIcon";
import "./Tabs.scss";

type TabsPropsType = {
  tabs: { id: string; label: string; icon?: string }[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
};

export const Tabs = ({ tabs, selectedTab, setSelectedTab }: TabsPropsType) => {
  return (
    <div className="tabs-container">
      {tabs.map((tab, i) => (
        <button
          key={`${tab.label}#${i}`}
          className={`tab-button ${
            selectedTab === tab.id ? "tab-button__selected" : ""
          } `}
          onClick={() => setSelectedTab(tab.id)}
        >
          {tab.icon && getSvgByName(tab.icon)}
          {tab.label}
        </button>
      ))}
    </div>
  );
};
