import { useState } from "react";
import {
  availableBlurStyle,
  availableThemeColors,
  changeUserBlurStyle,
  changeUserColor,
} from "../../../styles/styleUtils";
import { DefaultModalSection } from "../../SectionsModal/DefaultModalSection/DefaultModalSection";
import "./CustomizationTheme.scss";
import { getPreferencesFromLocalStorage } from "../../../utils/helpers";

type CustomizationThemePropsType = {};

export const CustomizationTheme = ({}: CustomizationThemePropsType) => {
  const [currentSelectedColor, setCurrentSelectedColor] = useState(
    getPreferencesFromLocalStorage()?.theme?.color
  );
  const [currentSelectedBlurStyle, setCurrentSelectedBlurStyle] = useState(
    getPreferencesFromLocalStorage()?.theme?.style || "transparent"
  );

  const handleThemeColorChange = (themeColor: any) => {
    changeUserColor(themeColor);
    setCurrentSelectedColor(themeColor);
  };
  const handleThemeBlurStyle = (style: any) => {
    changeUserBlurStyle(style);
    setCurrentSelectedBlurStyle(style);
  };

  return (
    <div className="customize-theme-container">
      <DefaultModalSection
        sectionIcon={"theme"}
        title={"Customize your theme"}
        sectionHeader
        subTitle="This section is under construction"
      />
      <div className="customize-theme-content-container">
        <h2>Accent color</h2>
        <div className="customize-theme-theme-color">
          {availableThemeColors.map((themeColor, i) => {
            return (
              <button
                className={`${
                  currentSelectedColor &&
                  currentSelectedColor === themeColor.name
                    ? "customize-theme-theme-color__active"
                    : ""
                }`}
                onClick={() => handleThemeColorChange(themeColor.name)}
                key={themeColor.name}
                style={{ borderColor: themeColor.rgb, color: themeColor.rgb }}
              ></button>
            );
          })}
        </div>
        {/* <h2>Theme style</h2>
        <div className="customize-theme-theme-style">
          {availableBlurStyle.map((style, i) => {
            return (
              <button
                className={`font-size-m ${
                  currentSelectedBlurStyle &&
                  currentSelectedBlurStyle === style.name
                    ? "customize-theme-theme-style__active"
                    : ""
                }`}
                onClick={() => handleThemeBlurStyle(style.name)}
                key={style.name}
              >
                {style.name}
              </button>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};
