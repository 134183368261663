import { useNavigate } from "react-router-dom";
import "./PresentationCTA.scss";
import { Button } from "../../Button/Button";

type PresentationCTAPropsType = {};

export const PresentationCTA = ({}: PresentationCTAPropsType) => {
  const navigate = useNavigate();

  return (
    <Button
      label={"Join beta for free"}
      onClick={() => navigate("/login")}
    />
  );
};
