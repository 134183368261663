import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";
import Modal from "react-modal";

import "./Crypto.scss";
import {
  getPreferencesFromLocalStorage,
  modalCustomStyles,
  updatePreferencesInLocalStorage,
} from "../../../utils/helpers";
import { ModalHeader } from "../../ModalHeader/ModalHeader";
import { AddCryptoModal } from "./AddCryptoModal/AddCryptoModal";
import { formatPrice } from "./cryptoUtils";
import toast from "react-hot-toast";
import { Spinner } from "../../Spinner/Spinner";
import { AppHeader } from "../AppHeader/AppHeader";
import { Button } from "../../Button/Button";
import { CryptoListItemType } from "./CryptoListItem/CryptoListItem";
import { getSvgByName } from "../../../assets/getIcon";
import { AppWrapper } from "../AppWrapper/AppWrapper";

export const Crypto = () => {
  const [selectedCrypto, setSelectedCrypto] = useState<string[]>(
    getPreferencesFromLocalStorage().cryptoWidget || []
  );

  const [cryptoData, setCryptoData] = useState<
    CryptoListItemType[] | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenAddCryptoModal, setIsOpenAddCryptoModal] = useState<any>();
  console.log(cryptoData);
  useEffect(() => {
    const getCryptoInfo = async () => {
      setIsLoading(true);
      await axios
        .get(`${APIURL}/widgetCrypto`, {
          params: {
            selectedSymbols: selectedCrypto,
          },
        })
        .then((response) => {
          setCryptoData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("We can't load the crypto data now");
          console.log(error);
        });
    };
    getCryptoInfo();
  }, [selectedCrypto]);

  const handleUpdateCryptoWidget = async (newArray: any) => {
    const body = {
      selectedCryptos: newArray,
    };
    await axios
      .put(`${APIURL}/widgetCrypto/preferences`, body)
      .then((response) => {
        updatePreferencesInLocalStorage(response.data.preferences);
      })
      .catch((error) => {
        console.error("Error updaing crypto widget", error);
      });
  };

  const handleAddSymbol = (item: string) => {
    const updatedSelectedCrypto = [...selectedCrypto, item];
    setSelectedCrypto(updatedSelectedCrypto);
    handleUpdateCryptoWidget(updatedSelectedCrypto);
  };

  const handleDeleteSymbol = (item: string) => {
    const updatedSelectedCrypto = selectedCrypto.filter(
      (crypto) => crypto !== item
    );
    setSelectedCrypto(updatedSelectedCrypto);
    handleUpdateCryptoWidget(updatedSelectedCrypto);
  };

  const handleClickedModalItem = (item: string) => {
    if (selectedCrypto.includes(item)) {
      handleDeleteSymbol(item);
    } else {
      handleAddSymbol(item);
    }
  };

  return (
    <AppWrapper
      title={"Crypto"}
      icon={"widget-crypto"}
      openSettings={() => setIsOpenAddCryptoModal(true)}
    >
      <div className="widget-crypto-container">
        <div className="widget-crypto">
          <div className="widget-crypto-list-header font-size-xs font-weight-semi-bold ">
            <p>Name</p>
            <p>24h %</p>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            cryptoData && (
              <div className="widget-crypto-list  padding-y-s">
                {selectedCrypto.length > 0 ? (
                  cryptoData
                    .filter((crypto: any) =>
                      selectedCrypto.includes(crypto.symbol)
                    )
                    .map((item: CryptoListItemType, i: any) => {
                      return (
                        <a
                          key={`${item.name}${i}`}
                          href={item.link}
                          title={`${item.name} in CoinMarketCap`}
                          target="_blank"
                          className="widget-crypto-list-item"
                        >
                          <img className="widget-crypto-logo" src={item.logo} />
                          <div className="widget-crypto-info">
                            <div className="widget-crypto-info-header">
                              <p className="font-size-s font-weight-semi-bold">
                                {item.name}
                              </p>
                              <p className="font-size-s">
                                {formatPrice(item.price)}
                              </p>
                            </div>
                            <div
                              className={`widget-crypto-list-item-change font-size-xs font-weight-semi-bold ${
                                item.coinPriceChange30D < 0
                                  ? "widget-crypto-list-item-change__negative"
                                  : ""
                              }`}
                            >
                              {getSvgByName("chevron")}
                              {Math.abs(item.coinPriceChange24H).toFixed(2)}
                            </div>
                          </div>
                        </a>
                      );
                    })
                ) : (
                  <div className="widget-crypto-empty-state">
                    No crypto selected
                    <Button
                      label={"Open settings"}
                      secondary
                      onClick={() => setIsOpenAddCryptoModal(true)}
                    />
                  </div>
                )}
              </div>
            )
          )}
        </div>
        <Modal
          isOpen={isOpenAddCryptoModal}
          onRequestClose={() => setIsOpenAddCryptoModal(false)}
          style={modalCustomStyles}
          contentLabel="Example Modal"
          closeTimeoutMS={100}
        >
          <ModalHeader
            icon={"widget-crypto"}
            title={"Crypto"}
            onModalClose={() => setIsOpenAddCryptoModal(false)}
          />
          <AddCryptoModal
            clickedItem={(item: string) => handleClickedModalItem(item)}
            cryptoData={cryptoData}
            selectedCrypto={selectedCrypto}
          />
        </Modal>
      </div>
    </AppWrapper>
  );
};
