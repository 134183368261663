import { useEffect, useState } from "react";
import "./BookmarksCategoryItem.scss";

import React from "react";
import { IconButton } from "../../../IconButton/IconButtont";
import { ChevronDown, Folder } from "lucide-react";
import { BookmarksSidebarItem } from "../BookmarksSidebarItem/BookmarksSidebarItem";
import { FolderType } from "../../../../features/bookmarks/folders/foldersSlice";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from "react-beautiful-dnd";
import { getPreferencesFromLocalStorage } from "../../../../utils/helpers";
import {
  sortItemsByOrder,
  updateSidebarCategoryFoldersOrder,
} from "../sidebarUtils";

type BookmarksCategoryItemPropsType = {
  name: string;
  id: string;
  categoryFolders?: FolderType[];
  selectedSidebarSection?: string;
  provided: DraggableProvided;
  selectedFolder?: (folderId: string) => void;
  editFolder?: (folderId: string, folderName: string) => void;
  editCategory: () => void;
  addFolder: () => void;
};

export const BookmarksCategoryItem = ({
  name,
  id,
  categoryFolders,
  selectedSidebarSection,
  provided,
  editFolder,
  selectedFolder,
  editCategory,
  addFolder,
}: BookmarksCategoryItemPropsType) => {
  const [orderedCategoryFolders, setOrderedCategoryFolders] = useState<
    FolderType[] | undefined
  >();
  const localStorageOpenStateString = localStorage.getItem(
    "openCategoriesStates"
  );
  let localStorageOpenState: string[] = [];
  if (localStorageOpenStateString) {
    localStorageOpenState = JSON.parse(localStorageOpenStateString);
  }

  const [isCategoryOpen, setIsCategoryOpen] = useState(
    localStorageOpenState?.includes(id) || false
  );

  useEffect(() => {
    const localStorageOrder =
      getPreferencesFromLocalStorage()?.sidebarCategoryFoldersOrder;

    if (localStorageOrder) {
      const localStorageCategoryOrder = localStorageOrder.filter(
        (category: any) => category.categoryId === id
      );

      let categoryFoldersState = categoryFolders;
      if (localStorageCategoryOrder.length && categoryFolders) {
        const orderedItems = sortItemsByOrder(
          categoryFolders,
          localStorageCategoryOrder[0].order
        );
        const orderedIds = new Set(orderedItems.map((item: any) => item._id));
        const unorderedItems = categoryFolders.filter(
          (item: any) => !orderedIds.has(item._id)
        );
        categoryFoldersState = [...unorderedItems, ...orderedItems];
      }

      setOrderedCategoryFolders(categoryFoldersState);
    }
  }, [categoryFolders]);

  const handleCategoryClick = () => {
    setIsCategoryOpen(!isCategoryOpen);

    if (!isCategoryOpen) {
      if (!localStorageOpenState.includes(id)) {
        localStorageOpenState.push(id);
        localStorage.setItem(
          "openCategoriesStates",
          JSON.stringify(localStorageOpenState)
        );
      }
    } else {
      localStorageOpenState = localStorageOpenState.filter(
        (storedId) => storedId !== id
      );
      localStorage.setItem(
        "openCategoriesStates",
        JSON.stringify(localStorageOpenState)
      );
    }
  };

  const onEndDrag = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    const items = orderedCategoryFolders
      ? Array.from(orderedCategoryFolders)
      : [];
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    const orderOfIds = items.map((item: any) => item._id);
    setOrderedCategoryFolders(items);
    updateSidebarCategoryFoldersOrder(id, orderOfIds);
  };

  return (
    <div
      className={`bookmarks-category-item-wrapper   ${
        !isCategoryOpen ? "bookmarks-category-item-wrapper-closed" : ""
      } `}
    >
      <div className="bookmarks-category-item-container">
        <div
          onClick={handleCategoryClick}
          role="button"
          {...provided.dragHandleProps}
          tabIndex={0}
          className="bookmarks-category-item-header icon-button-item-hoverered"
        >
          <div className="bookmarks-category-item-header-chevron">
            <ChevronDown />
          </div>
          <span>{name}</span>
          <div className="bookmarks-category-item-header-buttons-container">
            <IconButton itemHover icon="add-folder" onClick={addFolder} />
            <IconButton itemHover icon="edit" onClick={editCategory} />
          </div>
        </div>
        <DragDropContext onDragEnd={onEndDrag}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="bookmarks-category-item-folders-container"
              >
                {orderedCategoryFolders &&
                  orderedCategoryFolders.length > 0 &&
                  orderedCategoryFolders.map((categoryFolder: any, i: any) => (
                    <Draggable
                      key={categoryFolder._id}
                      draggableId={categoryFolder._id}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <BookmarksSidebarItem
                            key={`folder-${categoryFolder._id}${i}`}
                            onClick={() =>
                              selectedFolder &&
                              selectedFolder(categoryFolder._id)
                            }
                            editFolder={() =>
                              editFolder &&
                              editFolder(
                                categoryFolder._id,
                                categoryFolder.name
                              )
                            }
                            selected={
                              selectedSidebarSection ===
                              `folder-${categoryFolder._id}`
                            }
                            label={categoryFolder.name}
                            icon={<Folder />}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
