import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { initializeApp, logOut } from "../../features/auth/authActions";
import { AppDispatch, RootState } from "../../store";

import { InputField } from "../InputField/InputField";
import { Button } from "../Button/Button";
import { APIURL } from "../../utils/apiUtils";
import { getSvgByName } from "../../assets/getIcon";
import { DefaultModalSection } from "../SectionsModal/DefaultModalSection/DefaultModalSection";

import "./Account.scss";

export const SettingsProfile = () => {
  const dispatch: AppDispatch = useDispatch();
  const [userNameInput, setUserNameInput] = useState("");
  const [showEditUsername, setShowEditUsername] = useState(false);
  const [username, setUsername] = useState<undefined | string>();

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/home");
    dispatch(logOut());
  };

  const { userInfo } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!username) {
      setShowEditUsername(true);
    } else {
      setShowEditUsername(false);
    }
  }, [username]);

  useEffect(() => {
    let localStorageUserString = localStorage.getItem("user");
    let localStorageUser;
    if (localStorageUserString) {
      localStorageUser = JSON.parse(localStorageUserString);
      const localStorageUsername = localStorageUser.username;
      if (localStorageUsername) {
        setUsername(localStorageUsername);
        setUserNameInput(localStorageUsername);
      }
    } else {
      setUsername(userInfo?.username);
      if (userInfo && userInfo.username) {
        setUserNameInput(userInfo.username);
      }
    }
  }, []);

  const handleSaveUsername = async () => {
    if (userNameInput.length < 3) {
      setShowEditUsername(false);
    } else if (username === userNameInput) {
      setShowEditUsername(false);
    } else {
      await axios
        .post(
          `${APIURL}/user/update_username`,
          { username: userNameInput },
          { withCredentials: true }
        )
        .then((response) => {
          let localStorageUserString = localStorage.getItem("user");
          let localStorageUser;
          if (localStorageUserString) {
            localStorageUser = JSON.parse(localStorageUserString);
          } else {
            console.log("No local storage user");
          }

          localStorageUser.username = userNameInput;

          localStorage.setItem("user", JSON.stringify(localStorageUser));

          dispatch(initializeApp());

          setShowEditUsername(false);
          setUsername(userNameInput);
        })
        .catch((error) => {
          console.error(error); //TODO check duplicates
        });
    }
  };

  return (
    <div className="profile-settings-container">
      <DefaultModalSection
        sectionIcon={"user"}
        sectionHeader
        title={"Your profile"}
        subTitle="Edit and customize your profile. More settings will soon be available."
      />
      <div className="profile-settings-container-username">
        {!showEditUsername ? (
          <div className="profile-settings-container-username-text">
            <h2>{username}</h2>
            <button onClick={() => setShowEditUsername(true)}>
              Edit username {getSvgByName("pencil")}
            </button>
          </div>
        ) : (
          <div className="profile-settings-container-username-field-wrapper">
            <InputField
              input={userNameInput}
              autoFocus
              placeholder={"Username"}
              label={""}
              onChange={(input) => setUserNameInput(input)}
            />
            <div className="profile-settings-container-username-field-buttons-container">
              <Button
                secondary
                label={"Cancel"}
                onClick={() => {
                  setUserNameInput(userNameInput);
                  setShowEditUsername(false);
                }}
              />
              <Button label={"Save"} onClick={handleSaveUsername} />
            </div>
          </div>
        )}
      </div>
      <p className="profile-settings-email">{userInfo?.email}</p>
      <Button secondary label={"Logout"} onClick={handleLogout} />
    </div>
  );
};
