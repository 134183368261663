import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { BookmarkType } from "./bookmarksSlice";
import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";
import { getFolderBookmarks, getFolders } from "../folders/foldersActions";

if (!APIURL) {
  console.log("ERROR NO API ENV VARIABLE");
}

export const getBookmarks = createAsyncThunk<
  BookmarkType[],
  void,
  { rejectValue: string }
>("getBookmarks", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${APIURL}/bookmarks`);
    return response.data.bookmarks;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const addBookmark = createAsyncThunk<
  BookmarkType,
  { name: string; url: string; folderId?: string | null; timeline?: boolean },
  { rejectValue: string }
>("addBookmark", async (bookmark, { rejectWithValue, dispatch }) => {
  try {
    const body = bookmark;
    const response = await axios.post(`${APIURL}/bookmarks`, body);
    dispatch(getBookmarks());
    if (bookmark.folderId) {
      dispatch(getFolderBookmarks({ folderId: bookmark.folderId }));
    }
    if (bookmark.timeline) {
      dispatch(getFolders());
    }
    return response.data.bookmarks;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateBookmark = createAsyncThunk<
  BookmarkType,
  {
    id: string;
    name: string;
    url: string;
    description?: string;
    folderId?: string | null;
  },
  { rejectValue: string }
>("updateBookmark", async (bookmark, { rejectWithValue, dispatch }) => {
  try {
    const body: {
      name: string;
      url: string;
      description?: string;
      folderId?: string | null;
    } = {
      name: bookmark.name,
      url: bookmark.url,
    };

    if (bookmark.folderId) {
      body.folderId = bookmark.folderId;
    }
    if (bookmark.description) {
      body.description = bookmark.description;
    }

    const response = await axios.put(
      `${APIURL}/bookmarks/${bookmark.id}`,
      body
    );
    dispatch(getBookmarks());
    return response.data.bookmarks; // You should return response.data to get the actual data from the API
  } catch (error: any) {
    // This is just a generic example; you need to adjust it to match the structure of your actual API error response
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const deleteBookmark = createAsyncThunk<
  BookmarkType,
  { id: string },
  { rejectValue: string }
>("deleteBookmark", async (bookmark, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete(`${APIURL}/bookmarks/${bookmark.id}`);
    dispatch(getBookmarks());
    return response.data.bookmarks; // You should return response.data to get the actual data from the API
  } catch (error: any) {
    // This is just a generic example; you need to adjust it to match the structure of your actual API error response
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
