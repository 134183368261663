import axios from "axios";
import { APIURL } from "../utils/apiUtils";
import {
  getPreferencesFromLocalStorage,
  updatePreferencesInLocalStorage,
} from "../utils/helpers";

const greenColor = "rgb(66, 219, 0)";
const pinkColor = "rgb(255, 59, 92)";
const orangeColor = "rgb(255, 86, 13)";
const blueColor = "rgb(0, 115, 246)";
const yellowColor = "rgb(246, 221, 0)";
const redColor = "rgb(255, 51, 15)";

export const availableThemeColors = [
  { name: "green", rgb: greenColor },
  { name: "pink", rgb: pinkColor },
  { name: "orange", rgb: orangeColor },
  { name: "blue", rgb: blueColor },
  { name: "yellow", rgb: yellowColor },
  { name: "red", rgb: redColor },
];

export const availableBlurStyle = [
  { name: "dark", color: "rgba(0, 0, 0, 0.5)" },
  { name: "light", color: "color: rgba(0, 0, 0, 0)" },
];

export const setPrimaryColor = (color) => {
  let choosenColor;
  if (!color) {
    choosenColor = getPreferencesFromLocalStorage()?.theme?.color || "pink";
  } else {
    choosenColor = color;
  }

  let selectedColor = availableThemeColors.filter(
    (item) => item.name === choosenColor
  )[0].rgb;

  document.documentElement.style.setProperty("--primary", selectedColor);
};

export const setBlurStyle = (style) => {
  let choosenStyle;
  if (!style) {
    choosenStyle =
      getPreferencesFromLocalStorage()?.theme?.style || "light";
  } else {
    choosenStyle = style;
  }

  let selectedColor = availableBlurStyle.filter(
    (item) => item.name === choosenStyle
  )[0].color;

  document.documentElement.style.setProperty(
    "--background-blur-theme",
    selectedColor
  );
};

export const changeUserColor = async (color) => {
  const body = {
    newColor: color,
  };

  await axios
    .post(`${APIURL}/user/theme_color`, body)
    .then((response) => {
      setPrimaryColor(response.data.preferences.theme.color);
      updatePreferencesInLocalStorage(response.data.preferences);
    })
    .catch((error) => {
      console.error("Error updaing theme colore", error);
    });
};

export const changeUserBlurStyle = async (style) => {
  const body = {
    newStyle: style,
  };

  await axios
    .post(`${APIURL}/user/blur_style`, body)
    .then((response) => {
      setBlurStyle(response.data.preferences.theme.style);
      updatePreferencesInLocalStorage(response.data.preferences);
    })
    .catch((error) => {
      console.error("Error updaing theme colore", error);
    });
};
