import { useEffect, useState } from "react";
import Modal from "react-modal";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";

import {
  formatUrl,
  getPreferencesFromLocalStorage,
  modalCustomStyles,
} from "../../../utils/helpers";
import { ModalHeader } from "../../ModalHeader/ModalHeader";
import { BookmarkItemModal } from "../BookmarkItemModal/BookmarkItemModal";

import "./FavoritesBar.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  sortFavoritesByLocalStorageOrder,
  updateFavoritesbarItemsOrder,
} from "./favoritesBarUtils";
import { FloatingButton } from "../../FloatingButton/FloatingButton";
import { Globe } from "lucide-react";
import { getSvgByName } from "../../../assets/getIcon";
import { FavoriteType } from "../../../features/bookmarks/favorites/favoritesSlice";
import { getFavorites } from "../../../features/bookmarks/favorites/favoritesAction";

export const FavoritesBar = () => {
  const [favoritesItems, setFavoritesItems] = useState<FavoriteType[]>([]);
  const [isItemModalOpen, setIsItemModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<FavoriteType | undefined>();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const FavoritesStyle: any = "frame"; //floating or frame

  const favoritesState = useSelector((state: RootState) => state.favorites);
  const { favorites } = favoritesState;

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getFavorites());
  }, []);

  useEffect(() => {
    const localStorageOrder =
      getPreferencesFromLocalStorage().favoritesBarItemsOrder || [];
    const sortedFavorites = sortFavoritesByLocalStorageOrder(
      favorites,
      localStorageOrder
    );
    setFavoritesItems(sortedFavorites);
  }, [favorites]);

  useEffect(() => {
    if (!isItemModalOpen && selectedItem) {
      setSelectedItem(undefined);
    }
  }, [isItemModalOpen, selectedItem]);

  const checkScrollPosition = () => {
    if (window.scrollY > 200) {
      setIsScrolled(true);
    }
    if (window.scrollY < 200) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);

    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  const handleEditItemButtonClick = (item: FavoriteType) => {
    setSelectedItem(item);
    setIsItemModalOpen(true);
  };

  const ondEndDrag = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = Array.from(favoritesItems);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    setFavoritesItems(items);
    const orderOfIds = items.map((item: any) => item._id);
    setFavoritesItems(items);
    updateFavoritesbarItemsOrder(orderOfIds);
  };
  return (
    <div
      className={`favorites-bar-container-wrapper favorites-bar-container-style-${FavoritesStyle} ${
        isScrolled ? "favorites-bar-container-wrapper__scrolled main-app-background " : ""
      } `}
    >
      <div className="favorites-bar-container">
        <DragDropContext onDragEnd={ondEndDrag}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className={`favorites-bar-items-list `}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {favoritesItems.map((item, i) => {
                  return (
                    <Draggable key={item._id} draggableId={item._id} index={i}>
                      {(provided, snapshot) => (
                        <a
                          key={`favorites-item-id-${item.name}-${i}`}
                          className={`favorites-bar-items-item floating-button-icon-wrapper  ${
                            FavoritesStyle === "frame"
                              ? "main-app-background"
                              : ""
                          } border-radius-s`}
                          rel="noreferrer"
                          title={item.name}
                          href={formatUrl(item.url)}
                          target="_blank"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="favorites-bar-items-item-name  font-size-xs">
                            {item.name}
                          </div>
                          {!item.iconUrl ? (
                            <div className="favorites-bar-items-item-favicon-default">
                              <Globe />
                            </div>
                          ) : (
                            <img
                              className="favorites-bar-items-item-favicon"
                              src={item.iconUrl}
                              alt={item.name}
                            />
                          )}

                          <FloatingButton
                            onClick={() => handleEditItemButtonClick(item)}
                          />
                        </a>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {favoritesItems.length < 16 && (
          <button
            onClick={() => setIsItemModalOpen(true)}
            title="Add favorite"
            className={` border-radius-l favorites-bar-items-item smooth-border main-app-background background-blur-style ;
            ${
              favoritesItems.length < 1
                ? "favorites-bar-items-item-add-button-empty"
                : ""
            } `}
          >
            {getSvgByName("favorite")}
            <div className="favorites-bar-items-item-name font-size-xs">
              New
            </div>
          </button>
        )}
      </div>
      <Modal
        isOpen={isItemModalOpen}
        onRequestClose={() => setIsItemModalOpen(false)}
        style={modalCustomStyles}
        contentLabel="Example Modal"
        closeTimeoutMS={100}
      >
        <ModalHeader
          icon={"favorite"}
          title={"Favorite"}
          onModalClose={() => setIsItemModalOpen(false)}
        />
        <BookmarkItemModal
          fromFavoritesBar={true}
          editLogic={selectedItem ? true : false}
          currentName={selectedItem?.name}
          folderId={selectedItem?.folderId}
          currentUrl={selectedItem?.url}
          id={selectedItem?._id}
          onModalClose={() => setIsItemModalOpen(false)}
        />
      </Modal>
    </div>
  );
};
