import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";
import toast from "react-hot-toast";

export const speakUpCreatePost = async (text: string, roomId: string) => {
  const requestBody = {
    text,
  };
  try {
    return await axios
      .post(`${APIURL}/speakup/post/${roomId}`, requestBody)
      .then((response) => {
        toast.success("Posted!");
        return response.data.post;
      });
  } catch (error: any) {
    console.log("Error creating post", error);
  }
};
export const likePost = async (
  roomId: string,
  postId: string,
  voteType: string
) => {
  const requestBody = {
    voteType,
  };
  try {
    return await axios
      .post(`${APIURL}/speakup/vote/${roomId}/${postId}`, requestBody)
      .then((response) => response.data.post);
  } catch (error: any) {
    console.log("Error creating post", error);
  }
};
export const getAllRooms = async () => {
  try {
    return await axios.get(`${APIURL}/speakup/rooms`).then((response) => {
      return response.data;
    });
  } catch (error: any) {}
};
