import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { loginUser, registerUser } from "../../features/auth/authActions";
import { RootState, AppDispatch } from "../../store";
import { Button } from "../Button/Button";
import { InputField } from "../InputField/InputField";
import "./AuthForm.scss";
import { Tabs } from "../Tabs/Tabs";
import { AuthFormRequestHeader } from "./AuthFormRequestHeader/AuthFormRequestHeader";
import { TextArea } from "../TextArea/TextArea";
import Dropdown from "../Dropdown/Dropdown";
import axios from "axios";
import { APIURL } from "../../utils/apiUtils";

type AuthFormPropsType = {};

type AuthFormStateType = "login" | "register" | "request";

export const AuthForm = ({}: AuthFormPropsType) => {
  const [formState, setFormState] = useState<AuthFormStateType>("login");
  const [emailInput, setEmailInput] = useState<string>("");
  const [usernameInput, setUsernameInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState<string>("");
  const [invitationCodeInput, setInvitationCodeInput] = useState<string>("");
  const [socialMediaInput, setSocialMediaInput] = useState<string>("");
  const [userTypeInput, setUserTypeInput] = useState<string>("User");
  const { error, success, loading } = useSelector(
    (state: RootState) => state.auth
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { path, code } = useParams();

  useEffect(() => {
    if (path === "request") {
      setFormState("request");
    } else if (path === "invitation") {
      setFormState("register");
      if (code) {
        const invitationEmail = code.split("-")[0];
        const invitationCode = code.split("-")[1];
        setEmailInput(invitationEmail);
        setInvitationCodeInput(invitationCode);
      }
    } else {
      setFormState("login");
    }
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "bottom-right",
        duration: 6000,
      });
      setPasswordInput("");
    }
    if (success) {
      navigate("/");
    }
  }, [error, success, navigate]);

  const handleLogin = (test?: boolean) => {
    if ((emailInput.length > 0 && passwordInput.length > 0) || test) {
      const emailLowercase = emailInput.toLowerCase();
      const data = {
        email: test ? "test@test.com" : emailLowercase,
        password: test ? "Test1234!" : passwordInput,
      };
      dispatch(loginUser(data));
    } else {
      toast.dismiss();
      toast.error("All fields are required", {
        position: "bottom-right",
        duration: 4000,
      });
    }
  };

  const handleRegister = () => {
    if (passwordInput !== confirmPasswordInput) {
      toast.error("Passwords don't match", {
        position: "bottom-right",
      });
    } else {
      if (
        emailInput.length > 0 &&
        passwordInput.length > 0 &&
        passwordInput.length > 0
      ) {
        const emailLowercase = emailInput.toLowerCase();
        const invitationCodeInputLowerCase = invitationCodeInput.toLowerCase();
        const data = {
          email: emailLowercase,
          password: passwordInput,
          username: usernameInput,
          inviteCode: invitationCodeInputLowerCase,
        };
        dispatch(registerUser(data));
      } else {
        toast.dismiss();
        toast.error("All fields are required", {
          position: "bottom-right",
          duration: 6000,
        });
      }
    }
  };

  const submitRequest = async () => {
    if (
      emailInput.length > 0 &&
      socialMediaInput.length > 0 &&
      userTypeInput.length > 0
    ) {
      const requestBody = {
        email: emailInput,
        socialPlatform: socialMediaInput,
        userType: userTypeInput,
      };
      try {
        await axios.post(`${APIURL}/tickets/accessRequest`, requestBody);
        userTypeInput !== "guser"
          ? toast.success("Done! We will process it as soon as we can.", {
              position: "bottom-right",
              duration: 5000,
            })
          : toast.success("True NGL. We will process it as soon as we can.", {
              position: "bottom-right",
              duration: 5000,
            });
        setEmailInput("");
        setSocialMediaInput("");
      } catch (error: any) {
        toast.error(error.response.data.error, {
          position: "bottom-right",
          duration: 5000,
        });
      }
    } else {
      toast.error("All fields are required", {
        position: "bottom-right",
      });
    }
  };

  const tabs: { id: AuthFormStateType; label: string }[] = [
    { id: "login", label: "Login" },
    { id: "register", label: "Register" },
  ];

  const typeOfUserOptions = [
    { label: "Just try SleekTab", id: "user" },
    { label: "Build an integrated app", id: "developer" },
    { label: "Create content", id: "creator" },
    { label: "Publish or sell art", id: "artist" },
    {
      label: "How am I supposed to know without trying the app ? 😡",
      id: "guser",
    },
  ];

  const disabledRequestSubmit =
    !emailInput.length || !socialMediaInput || !userTypeInput;

  const disabledLogin = !emailInput.length || !passwordInput;

  const disabledRegister =
    !emailInput.length ||
    !usernameInput.length ||
    !passwordInput ||
    !confirmPasswordInput ||
    !invitationCodeInput;

  const disabledButton =
    formState === "request"
      ? disabledRequestSubmit
      : formState === "login"
      ? disabledLogin
      : disabledRegister;

  return (
    <div className="auth-form-container border-radius-m">
      <form onSubmit={(e) => e.preventDefault()}>
        {formState !== "request" ? (
          <div className="auth-form-container-change-form">
            <Tabs
              tabs={tabs}
              selectedTab={formState}
              setSelectedTab={(tab: any) => setFormState(tab)}
            />
          </div>
        ) : (
          <AuthFormRequestHeader />
        )}
        <InputField
          input={emailInput}
          autoComplete="email"
          placeholder={"who@dis.com"}
          label={"Email"}
          onChange={(email) => setEmailInput(email)}
        />
        {formState === "register" && (
          <InputField
            input={usernameInput}
            placeholder={"Username"}
            autoComplete="username"
            label={"Username (You can change this later)"}
            onChange={(text) => setUsernameInput(text)}
          />
        )}
        {formState === "request" && (
          <InputField
            input={socialMediaInput}
            placeholder={"Discord / X / TikTok / Instagram ..."}
            label={"Social platform ( Username or URL )"}
            onChange={(text) => setSocialMediaInput(text)}
          />
        )}
        {formState === "request" && (
          <Dropdown
            label={"Have a specific goal ?"}
            setSelectedItem={(type: any) => setUserTypeInput(type)}
            items={typeOfUserOptions}
            selectedItem={userTypeInput}
            placeholder={"User"}
          />
        )}
        {formState !== "request" && (
          <InputField
            input={passwordInput}
            placeholder={"Password"}
            label={"Password"}
            autoComplete="current-password"
            type="password"
            onChange={(password) => setPasswordInput(password)}
          />
        )}

        {formState === "register" && (
          <InputField
            input={confirmPasswordInput}
            placeholder={"Confirm Password"}
            autoComplete="current-password"
            label={"Confirm Password"}
            type="password"
            onChange={(password) => setConfirmPasswordInput(password)}
          />
        )}

        {formState === "register" && (
          <InputField
            input={invitationCodeInput}
            placeholder={"The early access invitation code"}
            label={"Invitation Code"}
            onChange={(code) => setInvitationCodeInput(code)}
          />
        )}
        {(formState === "register" || formState === "request") && (
          <p className="legal-register-notice font-size-xs ">
            By clicking Sign up or Apply, you agree to our{" "}
            <a
              href="https://www.sleektab.app/legal/termsofservice"
              target="_blank"
            >
              Terms
            </a>
            . Learn how we collect, use and share your data in our{" "}
            <a href="https://www.sleektab.app/legal/privacy" target="_blank">
              Privacy Policy
            </a>{" "}
            and how we use cookies and similar technology in our{" "}
            <a href="https://www.sleektab.app/legal/cookies" target="_blank">
              Cookies Policy
            </a>
            .
          </p>
        )}
        <div className="auth-form-container-buttons-container">
          {formState === "request" && (
            <Button
              loading={loading}
              secondary
              label={"Back"}
              onClick={() => {
                setFormState("register");
              }}
            />
          )}
          <Button
            loading={loading}
            submitButton
            disabled={disabledButton}
            label={
              formState === "login"
                ? "Login"
                : formState === "register"
                ? "Register"
                : "Apply"
            }
            onClick={() => {
              formState === "request"
                ? submitRequest()
                : formState === "login"
                ? handleLogin()
                : handleRegister();
            }}
          />
        </div>

        {process.env.NODE_ENV !== "production" && formState === "login" && (
          <Button
            label={"Login test@test.com"}
            onClick={() => handleLogin(true)}
          />
        )}
        {formState === "register" && (
          <div className="auth-form-container-info-box font-size-s ">
            You don't have a code ?{" "}
            <button
              onClick={() => setFormState("request")}
              className="font-size-s"
            >
              Apply for early access
            </button>
          </div>
        )}
      </form>
    </div>
  );
};
