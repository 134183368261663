import { useState } from "react";
import CheersIcon from "../../assets/cheers.png";
import {
  availableBlurStyle,
  availableThemeColors,
  changeUserBlurStyle,
  changeUserColor,
} from "../../styles/styleUtils";
import {
  getPreferencesFromLocalStorage,
  modalCustomStylesSections,
  setOnboardingCompletedLocalStorage,
} from "../../utils/helpers";
import { DefaultModalSection } from "../SectionsModal/DefaultModalSection/DefaultModalSection";
import "./Onboarding.scss";
import { Button } from "../Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { getSvgByName } from "../../assets/getIcon";
import ArrowIcon from "../../assets/icons/arrow.png";
import { APIURL } from "../../utils/apiUtils";
import axios from "axios";

type OnboardingPropsType = {};

export const onBoardingModalStyles = {
  ...modalCustomStylesSections,
  content: {
    ...modalCustomStylesSections.content,
    height: "fit-content",
  },
  overlay: {
    ...modalCustomStylesSections.overlay,
    backdropFilter: "blur(0px)",
  },
};

export const onBoardingModalStylesBookmarks = {
  ...onBoardingModalStyles,
  content: {
    ...onBoardingModalStyles.content,
    left: "30%",
  },
};

export const onBoardingModalStylesApps = {
  ...onBoardingModalStyles,
  content: {
    ...onBoardingModalStyles.content,
    left: "unset",
    right: "50%",
  },
};

export const onBoardingModalStylesTimeline = {
  ...onBoardingModalStyles,
  content: {
    ...onBoardingModalStyles.content,
    right: "unset",
    top: "unset",
    bottom: "-20%",
  },
};

export const Onboarding = ({}: OnboardingPropsType) => {
  const navigate = useNavigate();
  const params = useParams();
  const sectionId = params["*"] || "1";
  const [currentSelectedColor, setCurrentSelectedColor] = useState(
    getPreferencesFromLocalStorage()?.theme?.color
  );
  const [currentSelectedBlurStyle, setCurrentSelectedBlurStyle] = useState(
    getPreferencesFromLocalStorage()?.theme?.style || "transparent"
  );

  const handleThemeColorChange = (themeColor: any) => {
    changeUserColor(themeColor);
    setCurrentSelectedColor(themeColor);
  };

  const handleThemeBlurStyle = (style: any) => {
    changeUserBlurStyle(style);
    setCurrentSelectedBlurStyle(style);
  };

  const getOnboardingPageContent = () => {
    switch (sectionId) {
      case "1":
        return (
          <div className="onboarding-page-content-wrapper">
            <h2 className="customize-sectiont-title font-weight-semi-bold ">
              Pick your accent color
            </h2>
            <div className="customize-theme-theme-color">
              {availableThemeColors.map((themeColor, i) => {
                return (
                  <button
                    className={`${
                      currentSelectedColor &&
                      currentSelectedColor === themeColor.name
                        ? "customize-theme-theme-color__active"
                        : ""
                    }`}
                    onClick={() => handleThemeColorChange(themeColor.name)}
                    key={themeColor.name}
                    style={{
                      borderColor: themeColor.rgb,
                      color: themeColor.rgb,
                    }}
                  ></button>
                );
              })}
            </div>
            <h2 className="customize-sectiont-title font-weight-semi-bold">
              Theme style
            </h2>
            <div className="customize-theme-theme-style">
              {availableBlurStyle.map((style, i) => {
                return (
                  <button
                    className={`font-size-s border-radius-s blur-background-style blur-background-style-button blur-background-style-button__${
                      style.name
                    }  ${
                      currentSelectedBlurStyle &&
                      currentSelectedBlurStyle === style.name
                        ? "blur-background-style-button__active"
                        : ""
                    }`}
                    onClick={() => handleThemeBlurStyle(style.name)}
                    key={style.name}
                  >
                    <div className="border-radius-m font-size-xl blur-background-style">
                      {style.name}
                    </div>
                  </button>
                );
              })}
            </div>
            <p className="onboarding-page-secondary-info-text">
              You can also change your Wallpapers Packs{" "}
              {getSvgByName("wallpaper")} in the dashboard
            </p>
          </div>
        );
      case "2":
        return (
          <div className="onboarding-page-content-wrapper">
            <img
              className="onboarding-page-indicator-arrow onboarding-page-indicator-arrow__left "
              src={ArrowIcon}
            />
            <p className="onboarding-page-main-info-text font-weight-semi-bold italic ">
              Your smart bookmarks {getSvgByName("save")} are on the left side
              of the screen
            </p>
            <p className="onboarding-page-main-info-number">1</p>
            <p className="onboarding-page-main-info-text">
              Save {getSvgByName("add-bookmark")} anything you like using the
              chrome extension
            </p>
            <p className="onboarding-page-secondary-info-text">
              or manually in the bookmark section
            </p>
            <p className="onboarding-page-main-info-number">2</p>
            <p className="onboarding-page-main-info-text">
              Create categories {getSvgByName("category")} and folders{" "}
              {getSvgByName("add-folder")} to keep everything organized
            </p>
            <p className="onboarding-page-secondary-info-text">
              or let the AI do it for you.
            </p>
            <p className="onboarding-page-main-info-number">3</p>
            <p className="onboarding-page-main-info-text">
              Add sites to favorites {getSvgByName("heart")} for quick access
            </p>
            <p className="onboarding-page-secondary-info-text">
              like a desktop or homescreen
            </p>
          </div>
        );
      case "3":
        return (
          <div className="onboarding-page-content-wrapper">
            <img
              className="onboarding-page-indicator-arrow onboarding-page-indicator-arrow__right "
              src={ArrowIcon}
            />
            <p className="font-weight-semi-bold italic ">
              The dock is on the right side of the screen
            </p>
            <p className="onboarding-page-main-info-number">1</p>
            <p className="onboarding-page-main-info-text">
              Available widgets are at the top of the dock
            </p>
            <p className="onboarding-page-main-info-number">2</p>
            <p className="onboarding-page-main-info-text">
              The dock also contains your installed apps
            </p>
            <p className="onboarding-page-main-info-number">3</p>
            <p className="onboarding-page-main-info-text">
              The {getSvgByName("settings")} button opens your Dashboard
            </p>
            <p className="onboarding-page-secondary-info-text">
              All your settings and preferences are here
            </p>
          </div>
        );
      case "4":
        return (
          <div className="onboarding-page-content-wrapper">
            <img
              className="onboarding-page-indicator-arrow onboarding-page-indicator-arrow__down "
              src={ArrowIcon}
            />
            <p className="font-weight-semi-bold italic ">
              The timeline is at the bottom
            </p>
            <p className="onboarding-page-main-info-number">1</p>
            <p className="onboarding-page-main-info-text">
              Chose your sources in the dashboard {getSvgByName("settings")}
            </p>
            <p className="onboarding-page-main-info-number">2</p>
            <p className="onboarding-page-main-info-text">
              Scroll and see the content you like, nothing else
            </p>
            <p className="onboarding-page-main-info-number">3</p>
            <p className="onboarding-page-main-info-text">
              The {getSvgByName("heart")} button will save what you like
            </p>
            <p className="onboarding-page-secondary-info-text">
              This items are saved in the Timeline folder
            </p>
          </div>
        );
      case "5":
        return (
          <div className="onboarding-page-content-wrapper">
            <p className="font-weight-semi-bold italic ">
              SleekTab is the extensions of the community's collective will
            </p>
            <p className="onboarding-page-secondary-info-text">
              Feel free to use The Wall {getSvgByName("speakup")} to decide the
              future of SleekTab
            </p>
            <p className="onboarding-page-main-info-number"></p>
            <p className="onboarding-page-secondary-info-text">
              Also, make sure to use the Wallpapers you like
            </p>
          </div>
        );
    }
  };

  const getOnboardingPageHeader = () => {
    switch (sectionId) {
      case "1":
        return (
          <DefaultModalSection
            sectionHeader
            sectionIcon={"hug"}
            title={"Welcome on board!"}
            subTitle={"Let's quickly setup your preferences."}
          />
        );
      case "2":
        return (
          <DefaultModalSection
            sectionHeader
            sectionIcon={"save"}
            title={"Smart Bookmarks"}
            subTitle={"Bookmarks can be messy, but not here!"}
          />
        );
      case "3":
        return (
          <DefaultModalSection
            sectionHeader
            sectionIcon={"apps"}
            title={"Apps"}
            subTitle={"Your homepage, you decide what it does!"}
          />
        );
      case "4":
        return (
          <DefaultModalSection
            sectionHeader
            sectionIcon={"news"}
            title={"Timeline"}
            subTitle={"Your favorite content, news and more, in one place"}
          />
        );
      case "5":
        return (
          <DefaultModalSection
            sectionHeader
            sectionIcon={"hug"}
            title={"That's it!"}
            subTitle={"Enjoy the experience and let us know what you think"}
          />
        );
      default:
        break;
    }
  };

  const handleNavigationButton = (type: "next" | "previous") => {
    const numberSectionId = parseInt(sectionId);
    if (type === "next") {
      navigate(`/onboarding/${numberSectionId + 1}`);
    } else {
      navigate(`/onboarding/${numberSectionId - 1}`);
    }
  };

  const completeUserOnboarding = async (leaveType: "wallpapers" | "home") => {
    setOnboardingCompletedLocalStorage();
    try {
      await axios.post(`${APIURL}/user/complete_user_onboarding`);
    } catch (error: any) {
      console.log("Couldn't update onboarding flag");
    }
    if (leaveType === "home") {
      navigate("/");
    }
    if (leaveType === "wallpapers") {
      navigate("/market/backgrounds");
    }
  };

  return (
    <div className="onboarding-container">
      {getOnboardingPageHeader()}
      {getOnboardingPageContent()}
      {sectionId === "5" ? (
        <div className="onboarding-navigation-buttons-wrapper">
          <Button
            secondary
            label={"Leave me alone"}
            onClick={() => completeUserOnboarding("home")}
          />
          <Button
            label={"Chose your wallpapers"}
            icon="wallpaper"
            onClick={() => completeUserOnboarding("wallpapers")}
          />
        </div>
      ) : (
        <div className="onboarding-navigation-buttons-wrapper">
          {sectionId !== "1" && (
            <Button
              secondary
              label={"Back"}
              onClick={() => handleNavigationButton("previous")}
            />
          )}
          <Button
            label={"Next"}
            onClick={() => handleNavigationButton("next")}
          />
        </div>
      )}
    </div>
  );
};
