import { useEffect, useState } from "react";
import "./PublicProfile.scss";
import { useParams } from "react-router-dom";
import { APIURL } from "../../utils/apiUtils";
import DefaultProfilePicture from "../../assets/profile.jpg";
import axios from "axios";

type PublicProfilePropsType = {};

export const PublicProfile = ({}: PublicProfilePropsType) => {
  const [profile, setProfile] = useState<any>();

  const params = useParams();
  const username = params["*"];

  const getProfile = async () => {
    try {
      const response = await axios.get(`${APIURL}/profile/${username}`);
      setProfile(response.data.profileData);
    } catch (error: any) {
      setProfile(undefined);
      console.log("Could not fetch profile", error);
    }
  };

  useEffect(() => {
    if (username) {
      getProfile();
    }
  }, [username]);


  return (
    <div className="profile-modal-container">
      {profile ? (
        <div className="profile-container">
          <div className="profile-picture-container">
            <img
              className="profile-picture"
              src={
                profile.picture === "default"
                  ? DefaultProfilePicture
                  : profile.picture
              }
            />
          </div>
          <div className="profile-username-container">
            <h1 className="profile-username"> {profile.username}</h1>
          </div>
        </div>
      ) : (
        <div>User not found</div>
      )}
    </div>
  );
};
