import { useNavigate } from "react-router-dom";
import WhyGae from "../../../assets/whygae.jpg";
import { Button } from "../../Button/Button";
import { DefaultModalSection } from "../../SectionsModal/DefaultModalSection/DefaultModalSection";
import "./SettingsHelp.scss";

export const SettingsHelp = () => {
  const navigate = useNavigate();
  return (
    <div className="help-settings-container">
      <DefaultModalSection
        sectionIcon={"help"}
        title={"Help"}
        subTitle={"The help section is under construction"}
      />
      <Button
        label={"Open onboarding"}
        onClick={() => navigate("/onboarding/1")}
      />
    </div>
  );
};
