import "./AuthFormRequestHeader.scss";

type AuthFormRequestHeaderPropsType = {};

export const AuthFormRequestHeader = ({}: AuthFormRequestHeaderPropsType) => {
  return (
    <div className="auth-form-request-header-container">
      <img src="https://media1.giphy.com/media/yx400dIdkwWdsCgWYp/giphy.gif?cid=ecf05e47a172r6mlcg8uo711haqyi9cdto5wj1ecxgkk4e7p&ep=v1_gifs_search&rid=giphy.gif&ct=g" />
      <p>
        We build whatever users want, but our team is small (1 guy), and our
        resources are limited <span>🥲</span>
      </p>
      <p>
        Invitations are sent gradually to guarantee a secure and pleasant
        experience for everyone.
      </p>
    </div>
  );
};
