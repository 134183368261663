import React, { useState, useEffect } from "react";
import { getValidSpotifyToken, loadPlaylist, loadSpotifySDK } from "../utils";

import "./SpotifyPlayer.scss";
import { IconButton } from "../../../IconButton/IconButtont";
import { Spinner } from "../../../Spinner/Spinner";
import axios from "axios";
import { APIURL } from "../../../../utils/apiUtils";
import { FloatingButton } from "../../../FloatingButton/FloatingButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setPlayerOpen,
  setSelectedPlaylist,
} from "../../../../features/spotify/spotifySlice";
import { RootState } from "../../../../store";

export const SpotifyPlayer = ({ close }: { close: () => void }) => {
  const [currentTrack, setCurrentTrack] = useState<any>();
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isShuffle, setIsShuffle] = useState<boolean>(false);
  const [player, setPlayer] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentTrackIsLiked, setCurrentTrackIsLiked] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const selectedPlayList = useSelector(
    (state: RootState) => state.spotify.selectedPlayList
  );

  useEffect(() => {
    if (!player) {
      (window as any).onSpotifyWebPlaybackSDKReady = () => {
        initializeSpotifyPlayer();
      };

      loadSpotifySDK().catch((error) =>
        console.error("Failed to load Spotify SDK", error)
      );
    }

    return () => {
      if (player) {
        console.log("disconneting");
        player.disconnect();
        setPlayer(undefined);
        dispatch(setSelectedPlaylist(undefined));
      }
      delete (window as any).onSpotifyWebPlaybackSDKReady;
    };
  }, [player]);

  const initializeSpotifyPlayer = async () => {
    const token = await getValidSpotifyToken();
    if ((window as any).Spotify) {
      const player = new (window as any).Spotify.Player({
        name: "SleekTab Player",
        getOAuthToken: (cb: any) => {
          cb(token);
        },
      });

      player.addListener("ready", ({ device_id }: { device_id: string }) => {
        window.localStorage.setItem("spotify_device_id", device_id);
        if (selectedPlayList) {
          loadPlaylist(selectedPlayList);
        }
      });

      player.addListener("player_state_changed", (state: any) => {
        if (state && state.track_window && state.track_window.current_track) {
          setIsShuffle(state.shuffle);
          setCurrentTrack(state.track_window.current_track);
          setIsPaused(state.paused);
          setIsLoading(state.loading);
        }
      });

      player.connect();

      setPlayer(player);
    }
  };

  useEffect(() => {
    if (currentTrack && currentTrack.id) {
      checkIfSongLiked(currentTrack.id);
    }
  }, [currentTrack]);

  const handleClickControlls = async (
    action: "next" | "previous" | "pause" | "play"
  ) => {
    const token = await getValidSpotifyToken();
    try {
      await axios.post(`${APIURL}/spotify/controlPlayback`, {
        action,
        token: token,
      });
    } catch (error) {
      console.error(`Error controlling playback: ${action}`, error);
    }
  };

  const handleShuffleClick = async () => {
    const newShuffleState = !isShuffle;
    try {
      const token = await getValidSpotifyToken();

      await axios.post(`${APIURL}/spotify/toggleShuffle`, {
        shuffleState: newShuffleState,
        token: token,
      });
    } catch (error) {
      console.error("Error checking if song is liked:", error);
    }
  };

  const checkIfSongLiked = async (id: string) => {
    try {
      const token = await getValidSpotifyToken();

      const response = await axios.post(`${APIURL}/spotify/checkIfSongLiked`, {
        trackId: id,
        token: token,
      });
      setCurrentTrackIsLiked(response.data.isLiked);
    } catch (error) {
      console.error("Error checking if song is liked:", error);
    }
  };

  const handleClickLikeButton = async () => {
    try {
      const token = await getValidSpotifyToken();

      await axios.post(`${APIURL}/spotify/likeOrDislikeSong`, {
        trackId: currentTrack.id,
        token: token,
        like: !currentTrackIsLiked,
      });
      setCurrentTrackIsLiked(!currentTrackIsLiked);
    } catch (error) {
      console.error("Error liking/disliking song:", error);
    }
  };

  const imageToShow =
    currentTrack &&
    currentTrack.album &&
    currentTrack.album.images &&
    currentTrack.album.images[0] &&
    currentTrack.album.images[0].url
      ? currentTrack.album.images[0].url
      : "nothing";

  const artists =
    currentTrack &&
    currentTrack.artists.map((artist: any) => artist.name).join(", ");

  const handleClosePlayer = async () => {
    if (player && !isPaused) {
      const token = await getValidSpotifyToken();

      try {
        await axios.post(`${APIURL}/spotify/controlPlayback`, {
          action: "pause",
          token: token,
        });
      } catch (error) {
        console.error("Error pausing playback during close:", error);
      }
    }
    dispatch(setSelectedPlaylist(undefined));
    if (player) {
      player.disconnect();
    }
    dispatch(setPlayerOpen(false));
  };
  return (
    <div className="smooth-border spotify-player-container  floating-button-icon-wrapper main-app-background border-radius-m padding-s icon-button-item-hoverered ">
      <div className="spotify-player-image-wrapper">
        <IconButton
          icon={"heart"}
          disabled={isLoading}
          config={{
            fill: currentTrackIsLiked ? "#1ed760" : "transparent",
            color: currentTrackIsLiked ? "#1ed760" : "white",
          }}
          onClick={handleClickLikeButton}
        />
        {isLoading ? (
          <div className="spotify-player-loading">
            <Spinner />
          </div>
        ) : (
          <img src={imageToShow} />
        )}
        <IconButton icon="close" onClick={handleClosePlayer} />
      </div>

      <div className="spotify-player-track-info">
        <div className="spotify-player-track-info-text">
          <a
            target="_blank"
            href={`https://open.spotify.com/track/${
              currentTrack?.uri.split(":")[2]
            }`}
          >
            <p>{currentTrack?.name}</p>
            <p>{artists}</p>
          </a>
        </div>{" "}
        <IconButton
          icon={"shuffle"}
          disabled={isLoading}
          config={{
            color: isShuffle ? "#1ed760" : "white",
          }}
          onClick={() => handleShuffleClick()}
        />
      </div>

      <div className="spotify-player-track-controls">
        <div className="spotify-player-track-controls-playback">
          <IconButton
            icon={"skip-back"}
            disabled={isLoading}
            onClick={() => handleClickControlls("previous")}
          />
          <IconButton
            icon={isPaused ? "play" : "pause"}
            disabled={isLoading}
            onClick={() => handleClickControlls(isPaused ? "play" : "pause")}
          />
          <IconButton
            icon={"skip-forward"}
            disabled={isLoading}
            onClick={() => handleClickControlls("next")}
          />
        </div>
      </div>
    </div>
  );
};
