import { useState } from "react";
import { DefaultModalSection } from "../../SectionsModal/DefaultModalSection/DefaultModalSection";
import "./SettingsBugReport.scss";
import { TextArea } from "../../TextArea/TextArea";
import toast from "react-hot-toast";
import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";

type SettingsBugReportPropsType = {};

export const SettingsBugReport = ({}: SettingsBugReportPropsType) => {
  const [descriptionInput, setDescriptionInput] = useState("");

  const handleBugReportSubmit = async () => {
    if (descriptionInput.length > 9) {
      toast.success("Thank you! ❤️ We will take a look.");
      setDescriptionInput("");
      const body = {
        description: descriptionInput,
      };
      await axios
        .post(`${APIURL}/tickets/bugreport/`, body)
        .then((response) => {})
        .catch((error) => {
          if (error.response.data.error === "Max20Requests") {
            //toast.error("You can only have 20 active bug reports at a time");
          } else {
            //toast.error("Something went wrong");
          }
        });
    }
  };
  return (
    <div className="settings-bug-report-section">
      <DefaultModalSection
        sectionIcon={"bug"}
        title={"Bug Report"}
        subTitle={"You found a bug ? Let us know and be rewarded!"}
        formHeader="Bug report"
        description="War on bugs!"
        handleFormSubmit={handleBugReportSubmit}
        disabledSubmit={descriptionInput.length < 10}
      >
        <TextArea
          input={descriptionInput}
          placeholder={
            "What went wrong ? Where in the App did it happen ? What was the action before the bug ? Where are the aliens ? ..."
          }
          maxLength={1000}
          minLength={10}
          label={"In your words, describe the bug"}
          onChange={(input) => setDescriptionInput(input)}
        />
      </DefaultModalSection>
    </div>
  );
};
