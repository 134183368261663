import { getSvgByName } from "../../../../assets/getIcon";
import SpotifyLogo from "../../../../assets/icons/widgets/spotify.png";

import "./PlaylistCard.scss";

export type PlaylistType = {
  description: string;
  externalUrls: { spotify: string };
  href: string;
  id: string;
  images: { url: string }[];
  uri: string;
  name: string;
  owner: { display_name: string; external_urls: { spotify: string } };
  tracks: { href: string; total: number };
};

type PlaylistCardPropsType = {
  playlist: PlaylistType;
  isSelected: boolean;
};

export const PlaylistCard = ({
  playlist,
  isSelected,
}: PlaylistCardPropsType) => {
  const description =
    playlist.name === "Release Radar" || playlist.name === "Discover Weekly"
      ? playlist.description
      : `Playlist - ${playlist.owner.display_name}`;

  return (
    <button
      className={`playlist-card-container ${
        isSelected ? "playlist-card-container-playing" : ""
      } `}
      title={playlist.name || playlist.owner.display_name}
    >
      <div className="playlist-card-container-image-wrapper">
        {playlist.images[0] ? (
          <img src={playlist.images[0].url} />
        ) : (
          getSvgByName("music")
        )}
      </div>
      <div className="playlist-card-info-container">
        <p className="playlist-card-info-container__name font-size-s font-weight-semi-bold">
          {playlist.name}
        </p>
        <div className="playlist-card-info-details font-size-xs ">
          <p>{description}</p>
        </div>
      </div>
    </button>
  );
};
