import React, { useState, useEffect } from "react";

import "./Clock.scss";

export const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => {
      clearInterval(intervalId); // Cleanup the interval on component unmount
    };
  }, []); // Empty dependency array means this useEffect runs once when component mounts

  return (
    <div className="widget-clock-container">
      <p className="font-size-xxxxl">
        {currentTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </p>
    </div>
  );
};



