import { useState, useEffect } from "react";
import { ContextMenuType } from "../components/ContextMenu/ContextMenu";

const useContextMenu = () => {
  const [contextMenuState, setContextMenuState] = useState<
    ContextMenuType | undefined
  >();

  useEffect(() => {
    const handleContextMenuEvent = (event: any) => {
      const { detail } = event;
      setContextMenuState({
        component: detail.component,
        position: detail.position,
      });
    };

    window.addEventListener("openContextMenu", handleContextMenuEvent);

    return () => {
      window.removeEventListener("openContextMenu", handleContextMenuEvent);
    };
  }, []);

  const closeContextMenu = () => {
    setContextMenuState(undefined);
  };

  return { contextMenuState, closeContextMenu };
};

export default useContextMenu;
