import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";
import { updatePreferencesInLocalStorage } from "../../../utils/helpers";

export const sortItemsByOrder = (items: any, order: any) => {
  const itemMap = new Map(items.map((item: any) => [item._id, item]));
  return order
    .map((id: any) => itemMap.get(id))
    .filter((item: any) => item !== undefined);
};

export const updateUserSidebarOrder = async (order: any) => {
  const body = {
    newOrder: order,
  };
  await axios
    .post(`${APIURL}/user/sidebar_items_order`, body)
    .then((response) => {
      updatePreferencesInLocalStorage(response.data.preferences);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateSidebarCategoryFoldersOrder = async (categoryId: string, order: any) => {
  const body = {
    categoryId: categoryId,
    newOrder: order,
  };
  await axios
    .post(`${APIURL}/user/sidebar_category_order`, body)
    .then((response) => {
      updatePreferencesInLocalStorage(response.data.preferences);
    })
    .catch((error) => {
      console.log(error);
    });
};
