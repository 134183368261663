import { useState } from "react";
import {
  availableBlurStyle,
  availableThemeColors,
  changeUserBlurStyle,
  changeUserColor,
} from "../../../styles/styleUtils";
import { DefaultModalSection } from "../../SectionsModal/DefaultModalSection/DefaultModalSection";
import "./CustomizationSections.scss";
import {
  getPreferencesFromLocalStorage,
  getSectionsStateFromLocalStorage,
  updateSectionsStateInLocalStorage,
} from "../../../utils/helpers";
import { Button } from "../../Button/Button";

type CustomizationSectionsPropsType = {};

export const CustomizationSections = ({}: CustomizationSectionsPropsType) => {
  const [activeSections, setActiveSections] = useState(
    getSectionsStateFromLocalStorage() || []
  );

  const allAvailableSections = [
    { id: "section-crypto", name: "Crypto overview", icon: "" },
    { id: "section-popcorn", name: "Popcorn", icon: "" },
    { id: "section-random-websites", name: "Random websites", icon: "" },
    { id: "section-statistics", name: "Statistics", icon: "" },
  ];

  const handleSectionClick = (widget: string) => {
    if (activeSections.includes(widget)) {
      const newActiveWidgets = activeSections.filter(
        (activeWidget: string) => activeWidget !== widget
      );
      setActiveSections(newActiveWidgets);
      updateSectionsStateInLocalStorage(newActiveWidgets);
    } else {
      const newActiveWidgets = [...activeSections, widget];
      setActiveSections(newActiveWidgets);
      updateSectionsStateInLocalStorage(newActiveWidgets);
    }
  };

  return (
    <div className="customize-widgets-container">
      <DefaultModalSection
        sectionIcon={"theme"}
        sectionHeader
        title={"Customize your Sections"}
        subTitle="Sections"
      />
      <div className="customize-widgets-list">
        {allAvailableSections.map((section) => (
          <Button
            label={section.name}
            icon={section.icon}
            secondary
            className={`smooth-border ${
              activeSections.includes(section.id)
                ? "customize-widgets-button-active"
                : ""
            }`}
            onClick={() => handleSectionClick(section.id)}
          />
        ))}
      </div>
    </div>
  );
};
