import {
  CryptoListItem,
  CryptoListItemType,
} from "../CryptoListItem/CryptoListItem";
import "./AddCryptoModal.scss";

type AddCryptoModalPropsType = {
  selectedCrypto: string[];
  cryptoData: CryptoListItemType[] | undefined;
  clickedItem: (item: string) => void;
};

export const AddCryptoModal = ({
  selectedCrypto,
  cryptoData,
  clickedItem,
}: AddCryptoModalPropsType) => {
  const sortedCryptoData = cryptoData
    ? [...cryptoData].sort((a, b) => b.coinMarketCap - a.coinMarketCap)
    : undefined;
  return (
    <div className="settings-crypto-modal-container">
      <div className="settings-crypto-modal-header">
        <div className="settings-crypto-modal-header-rank">#</div>
        <div className="settings-crypto-modal-header-name">Name</div>
        <div className="settings-crypto-modal-header-price">Price</div>
        <div className="settings-crypto-modal-header-market-cap">
          Market cap
        </div>
        <div className={`settings-crypto-modal-header-change`}>1h %</div>
        <div className={`settings-crypto-modal-header-change `}>24h %</div>
        <div className={`settings-crypto-modal-header-change `}>7d %</div>
        <div className={`settings-crypto-modal-header-change`}>30d %</div>
      </div>
      <div className="settings-crypto-modal-list">
        {sortedCryptoData &&
          sortedCryptoData.map((item: CryptoListItemType, i: number) => {
            return (
              <CryptoListItem
                isSelected={selectedCrypto.includes(item.symbol)}
                clickedItem={() => clickedItem(item.symbol)}
                rank={i + 1}
                item={item}
              />
            );
          })}
      </div>
    </div>
  );
};
