import { getSvgByName } from "../../assets/getIcon";
import { useClickOutside } from "../../hooks/useClickOutside";
import "./ContextMenu.scss";
import { getContextMenu } from "./utils";

export type ContextMenuComponents = "sidebar";

export type ContextMenuType = {
  component: ContextMenuComponents;
  position: { x: number; y: number };
};

type ContextMenuPropsType = {
  contextMenu: ContextMenuType;
  close: () => void;
};

export const ContextMenu = ({ contextMenu, close }: ContextMenuPropsType) => {
  const handleContextMenuAction = (actionType: string) => {
    const event = new CustomEvent("contextMenuAction", {
      detail: {
        actionType,
      },
    });
    window.dispatchEvent(event);
    close();
  };
  const wrapperRef = useClickOutside(close);

  const distanceToBottom = window.innerHeight - contextMenu.position.y;

  const contextMenuPosition = {
    top: contextMenu.position.y,
    left: contextMenu.position.x,
    transform: distanceToBottom < 250 ? "translateY(-100%)" : "translateY(0%)",
  };

  return (
    <div
      ref={wrapperRef}
      style={contextMenuPosition}
      className="custom-context-menu smooth-border"
    >
      <ul className="custom-context-menu-list">
        {getContextMenu(contextMenu.component).map((item, i) => (
          <button
            onClick={() => handleContextMenuAction(item.action)}
            key={`${item.action}${i}`}
          >
            {getSvgByName(item.icon)}
            {item.label}
          </button>
        ))}
      </ul>
    </div>
  );
};
