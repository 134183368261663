import { useEffect, useRef } from "react";
import "./Button.scss";
import { getSvgByName } from "../../assets/getIcon";
import { Spinner } from "../Spinner/Spinner";

type ButtonPropsType = {
  label: string;
  secondary?: boolean;
  submitButton?: boolean;
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
  className?: string;
  onClick: () => void;
};

export const Button = ({
  label,
  icon,
  secondary,
  disabled,
  loading,
  submitButton,
  className,
  onClick,
}: ButtonPropsType) => {
  const addButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (submitButton) {
      const handleEnterKey = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
          if (addButtonRef.current) {
            addButtonRef.current.click();
          }
        }
      };

      window.addEventListener("keydown", handleEnterKey); // add event listener

      return () => {
        window.removeEventListener("keydown", handleEnterKey); // cleanup
      };
    }
  }, [submitButton]);

  return (
    <button
      ref={addButtonRef}
      disabled={disabled || loading}
      onClick={onClick}
      className={`button-container-wrapper font-size-s font-weight-semi-bold ${
        secondary ? "button-container-wrapper-secondary" : "" 
      } ${className} `}
    >
      {icon && getSvgByName(icon)}
      {loading ? <Spinner size={20} /> : label}
    </button>
  );
};
