import { useState } from "react";
import { DefaultModalSection } from "../SectionsModal/DefaultModalSection/DefaultModalSection";
import { WallpaperMarket } from "./WallpaperMarket/WallpaperMarket";
import { SectionsModal } from "../SectionsModal/SectionsModal";

import "./Market.scss";

export const Market = () => {
  const navigationItems = [
    {
      id: "backgrounds",
      label: "Backgrounds",
      icon: "wallpaper",
      content: <WallpaperMarket />,
    },
    {
      id: "Apps",
      label: "Apps",
      icon: "apps",
      content: (
        <DefaultModalSection
          sectionIcon={"store"}
          title={"Apps Market"}
          subTitle={"Discover apps from the community"}
          comingSoonMessage
        />
      ),
    },
  ];

  return <SectionsModal navigationItems={navigationItems} />;
};
