import {
  ArrowLeftToLine,
  BadgeInfo,
  BadgePlus,
  Bookmark,
  BookmarkPlus,
  Bot,
  BrainCog,
  Bug,
  ChevronUp,
  ChevronsUp,
  Clock,
  Code,
  Cog,
  Droplet,
  FileEdit,
  FileHeart,
  Flame,
  Folder,
  FolderPlus,
  Forward,
  GanttChart,
  Globe,
  Hand,
  Heart,
  HeartCrack,
  LayoutGrid,
  LayoutPanelLeft,
  LayoutTemplate,
  LibrarySquare,
  Music,
  Paintbrush,
  PauseCircle,
  Pencil,
  Play,
  Quote,
  Save,
  Scale,
  Search,
  Settings,
  Shuffle,
  SkipBack,
  SkipForward,
  Store,
  SunMoon,
  Trash,
  User,
  Wallpaper,
  X,
  XCircle,
} from "lucide-react";
import WidgetCryptoIcon from "./appsIcons/crypto.png";
import WidgetViralIcon from "./appsIcons/fire.png";
import WidgetWeatherIcon from "./icons/widgets/weather.png";
import AppsIcon from "./icons/widgets/apps.png";
import MillerioLogo from "./appsIcons/millerio.png";
import AssitantLogo from "./appsIcons/assistant.png";
import SpotifyLogo from "./appsIcons/spotify.png";
import SpeakUpIcon from "./appsIcons/board.png";
import BookmarksIcon from "./appsIcons/bookmarks.png";

export const getSvgByName = (
  iconName: string,
  config?: { fill?: string; color?: string; size?: number }
) => {
  switch (iconName) {
    case "widget-crypto":
      return <img alt="crypto" src={WidgetCryptoIcon} />;
    case "widget-viral":
      return <img alt="fire" src={WidgetViralIcon} />;
    case "widget-weather":
      return <img alt="weather" src={WidgetWeatherIcon} />;
    case "widget-apps":
      return <img alt="apps" src={AppsIcon} />;
    case "millerio":
      return <img alt="millerio" src={MillerioLogo} />;
    case "spotify":
      return <img alt="spotify" src={SpotifyLogo} />;
    case "speakup":
      return <img alt="speakup" src={SpeakUpIcon} />;
    case "bookmarks-app":
      return <img alt="bookmarks" src={BookmarksIcon} />;
    case "assistant":
      return <img alt="assistant" src={AssitantLogo} />;
    case "edit":
      return <Settings {...config} />;
    case "add-folder":
      return <FolderPlus {...config} />;
    case "add-bookmark":
      return <BookmarkPlus {...config} />;
    case "save":
      return <Save {...config} />;
    case "folder":
      return <Folder {...config} />;
    case "bookmark":
      return <Bookmark {...config} />;
    case "category":
      return <LibrarySquare {...config} />;
    case "favorite":
      return <Heart {...config} />;
    case "search":
      return <Search {...config} />;
    case "settings":
      return <Cog {...config} />;
    case "pencil":
      return <Pencil {...config} />;
    case "close":
      return <X {...config} />;
    case "flame":
      return <Flame {...config} />;
    case "apps":
      return <LayoutGrid {...config} />;
    case "add":
      return <BadgePlus {...config} />;
    case "theme":
      return <Paintbrush {...config} />;
    case "user":
      return <User {...config} />;
    case "help":
      return <BadgeInfo {...config} />;
    case "library":
      return <LayoutPanelLeft {...config} />;
    case "store":
      return <Store {...config} />;
    case "code":
      return <Code {...config} />;
    case "send":
      return <Forward {...config} />;
    case "bug":
      return <Bug {...config} />;
    case "heart":
      return <Heart {...config} />;
    case "skip-forward":
      return <SkipForward {...config} />;
    case "skip-back":
      return <SkipBack {...config} />;
    case "play":
      return <Play {...config} />;
    case "pause":
      return <PauseCircle {...config} />;
    case "legal":
      return <Scale {...config} />;
    case "music":
      return <Music {...config} />;
    case "shuffle":
      return <Shuffle {...config} />;
    case "delete":
      return <XCircle {...config} />;
    case "unfavorite":
      return <HeartCrack {...config} />;
    case "trash":
      return <Trash {...config} />;
    case "globe":
      return <Globe {...config} />;
    case "wallpaper":
      return <Wallpaper {...config} />;
    case "arrow-to-left":
      return <ArrowLeftToLine {...config} />;
    case "droplet":
      return <Droplet {...config} />;
    case "file-edit":
      return <FileEdit />;
    case "news":
      return <GanttChart />;
    case "double-chevron":
      return <ChevronsUp />;
    case "chevron":
      return <ChevronUp />;
    case "hand":
      return <Hand />;
    case "bot":
      return <Bot />;
    case "sections":
      return <LayoutTemplate />;
    case "weather":
      return <SunMoon />;
    case "clock":
      return <Clock />;
    case "quotes":
      return <Quote />;
    case "hug":
      return (
        <span
          className="icon-emoji"
          style={config && { fontSize: `${config.size}px` }}
        >
          🤗
        </span>
      );
    case "feature-bookmark":
      return <FileHeart />;
  }
};
