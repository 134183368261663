export const CookiesPolicy = (
  <div className="policy-content-container-text">
    <h3>We updated our policy on January 1, 2024.</h3>
    <h3>What are cookies, and what does this policy cover?</h3>
    <p>
      Cookies are small pieces of text used to store information on web
      browsers. Cookies are used to store and receive identifiers and other
      information on computers, phones and other devices. Other technologies,
      including data that we store on your web browser or device, identifiers
      associated with your device and other software, are used for similar
      purposes. In this policy, we refer to all of these technologies as
      “cookies”.
    </p>
    <p>
      We use cookies for SleekTab. Cookies enable SleekTab to understand the
      information that we receive about you, including information about your
      use of other websites and apps, whether or not you are registered or
      logged in.
    </p>
    <p>
      This policy explains how we use cookies and the choices you have and how
      it will apply to our processing of the data that we collect via cookies.
    </p>
    <h3>Why do we use cookies?</h3>
    <p>
      Cookies help us provide, protect and improve the SleekTab, such as
      providing a safer experience. The cookies that we use include session
      cookies, which are deleted when you close your browser, and persistent
      cookies, which stay in your browser until they expire or you delete them.
      The cookies we use or how we use them may change over time as we improve
      and update SleekTab, we typically use them for the following purposes:
    </p>
    <h3>Authentication</h3>
    <p>
      We use cookies to verify your account and determine when you’re logged in
      so that we can make it easier for you to access SleekTab and show you the
      appropriate experience and features.
    </p>
    <ul>
      <li>
        For example: We use cookies to keep you logged in as you navigate
        between Tabs. Cookies also help us remember your browser so you don't
        have to keep logging in to SleekTab.
      </li>
    </ul>
    <h3>Security, site and product integrity</h3>
    <p>
      We use cookies to help us keep your account, data and SleekTab safe and
      secure.
    </p>
    <ul>
      <li>
        For example: Cookies can help us identify and impose additional security
        measures when someone may be attempting to access a SleekTab account
        without authorisation, for instance, by rapidly guessing different
        passwords. We also use cookies to store information that allows us to
        recover your account in the event that you forget your password or to
        require additional authentication if you tell us that your account has
        been hacked. This includes, for example, our "sb" and "dbln" cookies,
        which enable us to identify your browser securely, as well as “datr."
        "Datr" is a unique identifier for your browser that, amongst other
        things, helps us protect you from fraud. For example, it helps us
        identify trusted browsers where you have logged in before. “Datr” has a
        lifespan of 400 days.
      </li>
    </ul>
    <p>
      We also use cookies to combat activity that violates our policies or
      otherwise degrades our ability to provide our products.
    </p>
    <ul>
      <li>
        For example: Cookies help us fight spam and phishing attacks by enabling
        us to identify computers that are used to create large numbers of fake
        SleekTab accounts. We also use cookies to detect computers infected with
        malware and to take steps to prevent them from causing further harm. Our
        "csrf" cookie, for example, helps us prevent cross-site request forgery
        attacks. The “datr” cookie also helps us to identify the browsers used
        by malicious actors and to prevent cyber-security attacks, such as a
        denial of service attack that could prevent you from accessing the
        SleekTab. Cookies also help us prevent underage people from registering
        for SleekTab accounts.
      </li>
    </ul>
    <h3>
      Cookies help us serve and measure ads across different browsers and
      devices used by the same person.
    </h3>
    <ul>
      <li>
        For example: We can use cookies to prevent you from seeing the same ad
        over and over again across the different devices that you use.
      </li>
    </ul>
    <h3>Site features and services</h3>
    <p>
      We use cookies to enable the functionality that helps us provide a good
      experience.
    </p>
    <ul>
      <li>
        For example: Cookies help us store preferences, know when you’ve seen or
        interacted with SleekTab content and provide you with customised content
        and experiences. For instance, cookies allow us to make suggestions to
        you and others, and to customise content on third-party sites that
        integrate our plugins.
      </li>
    </ul>
    <h3>Analytics and research</h3>
    <p>
      We use cookies to better understand how people use SleekTab so that we can
      improve them.
    </p>
    <ul>
      <li>
        For example: Cookies can help us understand how people use the SleekTab
        service, analyse which parts of our Products people find most useful and
        engaging, and identify features that could be improved.
      </li>
    </ul>
    <h3>How can you control your Information?</h3>
    <p>
      We use cookies to help personalise and improve content and services,
      provide a safer experience and to show you useful and relevant content on
      SleekTab.
    </p>
  </div>
);

export const TermsOfService = (
  <div className="policy-content-container-text">
    <h3>Updated: August 24, 2023</h3>
    <h3>Effective Date: January 12, 2024</h3>
    <p>
      Welcome to SleekTab! We build technologies and services that enable users
      to organize their digital life more efficiently. These Terms of Service
      ("Terms") govern your use of SleekTab and the various features, apps,
      services, technologies, and software we offer, except where we expressly
      state that separate terms apply.
    </p>

    <h3>1. The Services We Provide</h3>
    <p>
      At SleekTab, our mission is to streamline your online experience. Our
      services include:
    </p>
    <ul>
      <li>A central dashboard for easy access to bookmarks and timelines.</li>
      <li>
        The ability to install various applications, such as those for crypto
        management.
      </li>
      <li>
        An AI assistant to facilitate your digital interactions (Note: We are
        not responsible for the AI assistant's responses).
      </li>
    </ul>

    <h3>2. Privacy and Data Usage</h3>
    <ul>
      <li>
        <strong>No Ads</strong>: Unlike other platforms, we do not show you ads.
        We do not work with advertisers or third-party companies for ad
        displays.
      </li>
      <li>
        <strong>Data Use</strong>: We do not sell or share your personal data
        (like your name, email address, or contact information) with outside
        companies. Our use of your data is strictly for providing and improving
        our services.
      </li>
    </ul>

    <h3>3. User Responsibilities</h3>
    <ul>
      <li>
        <strong>Account Security</strong>: You're responsible for maintaining
        the security of your account.
      </li>
      <li>
        <strong>Acceptable Use</strong>: Use our services responsibly and do not
        engage in unlawful activities or violate others' rights.
      </li>
    </ul>

    <h3>4. Intellectual Property</h3>
    <p>
      <strong>Content Ownership</strong>: You retain ownership of your
      intellectual property. By using our services, you grant SleekTab a license
      to use this content solely for the purpose of providing our services.
    </p>

    <h3>5. Disclaimer of Warranties</h3>
    <p>
      SleekTab provides its services "as is" without any express or implied
      warranties, including but not limited to, warranties of merchantability,
      fitness for a particular purpose, and non-infringement.
    </p>

    <h3>6. Limitation of Liability</h3>
    <p>
      SleekTab is not liable for any indirect, incidental, special,
      consequential or punitive damages, or any loss of profits or revenues,
      whether incurred directly or indirectly, or any loss of data, use,
      goodwill, or other intangible losses.
    </p>

    <h3>7. General Terms</h3>
    <ul>
      <li>
        <strong>Revisions</strong>: We may modify these Terms from time to time
        to reflect changes to our service or for legal, regulatory, or security
        reasons. Changes will be effective no less than 30 days after they are
        posted.
      </li>
      <li>
        <strong>Termination</strong>: Accounts may be terminated for violations
        of these Terms.
      </li>
      <li>
        <strong>Governing Law</strong>: These Terms are governed by [Applicable
        Law].
      </li>
    </ul>

    <h3>8. Contact Us</h3>
    <p>For any questions about these Terms, please contact our support team.</p>
  </div>
);

export const PrivacyPolicy = (
  <div className="policy-content-container-text">
    <h3>Privacy Policy for SleekTab</h3>
    <p>Last Updated: 1 January 2024</p>
    <p>
      At SleekTab, we are committed to protecting the privacy and security of
      our users. This Privacy Policy outlines our practices regarding the
      collection, use, and disclosure of your information when you use our
      platform and the choices you have associated with that information.
    </p>

    <h3>1. Information Collection</h3>
    <p>
      We collect several different types of information for various purposes to
      provide and improve our service to you.
    </p>
    <ul>
      <li>
        <strong>Personal Data</strong>: While using our Service, we may ask you
        to provide us with certain personally identifiable information that can
        be used to contact or identify you.
      </li>
      <li>
        <strong>Usage Data</strong>: We may also collect information on how the
        Service is accessed and used.
      </li>
      <li>
        <strong>Tracking & Cookies Data</strong>: We use cookies and similar
        tracking technologies to track activity on our Service and hold certain
        information.
      </li>
    </ul>

    <h3>2. Use of Data</h3>
    <p>SleekTab uses the collected data for various purposes:</p>
    <ul>
      <li>To provide and maintain our Service.</li>
      <li>To notify you about changes to our Service.</li>
      <li>
        To allow you to participate in interactive features of our Service when
        you choose to do so.
      </li>
      <li>To provide customer support.</li>
      <li>
        To gather analysis or valuable information so that we can improve our
        Service.
      </li>
      <li>To monitor the usage of our Service.</li>
      <li>To detect, prevent and address technical issues.</li>
    </ul>

    <h3>3. Data Transfer</h3>
    <p>
      Your information, including Personal Data, may be transferred to — and
      maintained on — computers located outside of your state, province, country
      or other governmental jurisdiction where the data protection laws may
      differ from those of your jurisdiction.
    </p>

    <h3>4. Disclosure of Data</h3>
    <p>
      We may disclose your personal data in the good faith belief that such
      action is necessary to:
    </p>
    <ul>
      <li>To comply with a legal obligation.</li>
      <li>To protect and defend the rights or property of SleekTab.</li>
      <li>
        To prevent or investigate possible wrongdoing in connection with the
        Service.
      </li>
      <li>
        To protect the personal safety of users of the Service or the public.
      </li>
      <li>To protect against legal liability.</li>
    </ul>

    <h3>5. Security of Data</h3>
    <p>
      The security of your data is important to us, but remember that no method
      of transmission over the Internet or method of electronic storage is 100%
      secure.
    </p>

    <h3>6. Service Providers</h3>
    <p>
      We may employ third-party companies and individuals to facilitate our
      Service ("Service Providers"), to provide the Service on our behalf, to
      perform Service-related services or to assist us in analyzing how our
      Service is used.
    </p>

    <h3>7. Links to Other Sites</h3>
    <p>
      Our Service may contain links to other sites that are not operated by us.
      If you click a third-party link, you will be directed to that third
      party's site. We strongly advise you to review the Privacy Policy of every
      site you visit.
    </p>

    <h3>8. Changes to This Privacy Policy</h3>
    <p>
      We may update our Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page.
    </p>

    <h3>9. Contact Us</h3>
    <p>
      If you have any questions about this Privacy Policy, please contact us.
    </p>
  </div>
);
