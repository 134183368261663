import { useState } from "react";

import { Link } from "react-router-dom";

import "./BookmarkListItem.scss";
import Modal from "react-modal";
import {
  extractDomain,
  formatUrl,
  modalCustomStyles,
} from "../../../../utils/helpers";
import { ModalHeader } from "../../../ModalHeader/ModalHeader";
import { BookmarkItemModal } from "../../BookmarkItemModal/BookmarkItemModal";
import { IconButton } from "../../../IconButton/IconButtont";
import { Globe } from "lucide-react";
import { BookmarkType } from "../../../../features/bookmarks/bookmarks/bookmarksSlice";
import { getSvgByName } from "../../../../assets/getIcon";

type BookmarksListItemProps = {
  bookmark: BookmarkType;
};

export const BookmarksListItem = ({ bookmark }: BookmarksListItemProps) => {
  const [isOpenEditMenu, setIsOpenEditMenu] = useState(false);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    // Months array for converting month number to name
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the day, month, and year from the date
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear().toString().substring(2); // Last two digits of the year

    // Format the date as "day month, year"
    return `${day} ${months[monthIndex]}, ${year}`;
  };

  const formattedUrl = formatUrl(bookmark.url);

  return (
    <div className="bookmarks-list-item-wrapper icon-button-item-hoverered">
      <Link
        to={formattedUrl}
        target="_blank"
        title={bookmark.name}
        className="bookmarks-list-item-link"
      >
        <div className="bookmarks-list-item-header">
          <div className="bookmarks-list-item-header-favicon">
            {!bookmark.iconUrl ? (
              <Globe />
            ) : (
              <img alt={`favicon ${bookmark.name}`} src={bookmark.iconUrl} />
            )}
            <h5 className="font-size-s font-weight-semi-bold">
              {bookmark.name}
            </h5>
          </div>
          <IconButton
            itemHover
            icon="file-edit"
            onClick={() => setIsOpenEditMenu(true)}
          />
        </div>
        {bookmark.description !== "None" && (
          <div className="bookmarks-list-item-text">
            <p className="font-size-xs">{bookmark.description}</p>
          </div>
        )}
        <div className="bookmarks-list-item-footer font-size-xs">
          <div className="bookmarks-list-item-footer-item">
            <p>{extractDomain(bookmark.url)}</p>
          </div>
          {bookmark.currentFolderName && (
            <div className="bookmarks-list-item-footer-item">
              <span>•</span>
              {getSvgByName("folder")} <p>{bookmark.currentFolderName}</p>
            </div>
          )}
          <p className="bookmarks-list-item-footer-folder-date">
            {formatDate(bookmark.createdAt)}
          </p>
        </div>
      </Link>

      <Modal
        isOpen={isOpenEditMenu}
        onRequestClose={() => setIsOpenEditMenu(false)}
        style={modalCustomStyles}
        contentLabel="Example Modal"
        closeTimeoutMS={100}
      >
        <ModalHeader
          icon={"bookmark"}
          title={"Bookmark"}
          onModalClose={() => setIsOpenEditMenu(false)}
        />
        <BookmarkItemModal
          editLogic
          currentName={bookmark.name}
          folderId={bookmark.folderId}
          description={bookmark.description}
          currentUrl={bookmark.url}
          id={bookmark._id}
          onModalClose={() => setIsOpenEditMenu(false)}
        />
      </Modal>
    </div>
  );
};
