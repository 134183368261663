import { useEffect, useState } from "react";
import "./SpeakUpRoom.scss";
import { timeAgo } from "../../../../utils/helpers";
import { SpeakUpPost, SpeakUpPostType } from "../SpeakUpPost/SpeakUpPost";
import { TextArea } from "../../../TextArea/TextArea";
import { Button } from "../../../Button/Button";
import { speakUpCreatePost } from "../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  SpeakUpFilterType,
  SpeakUpRoomFilters,
} from "./SpeakUpRoomFilters/SpeakUpRoomFilters";

type SpeakUpRoomPropsType = {
  room: any;
};

export const SpeakUpRoom = ({ room }: SpeakUpRoomPropsType) => {
  const [selectedFilter, setSelectedFilter] =
    useState<SpeakUpFilterType>("new");
  const [roomPosts, setRoomPosts] = useState<any>(room.posts);
  const [newPostInput, setNewPostInput] = useState<string>("");
  const [alreadyPosted, setAlreadyPosted] = useState<boolean>(false);

  const { userInfo } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const createdPost = roomPosts.filter(
      (post: SpeakUpPostType) =>
        post.username.toLowerCase() === userInfo?.username?.toLowerCase()
    );
    if (createdPost.length > 0 && !alreadyPosted) {
      setAlreadyPosted(true);
    }
  }, [roomPosts]);

  useEffect(() => {
    let sortedPosts = [...room.posts]; // Create a copy of the posts

    if (selectedFilter === "top") {
      sortedPosts.sort((a, b) => b.votes - a.votes);
    } else if (selectedFilter === "new") {
      sortedPosts.sort(
        (a, b) => new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
      );
    }

    setRoomPosts(sortedPosts);
  }, [selectedFilter, room.posts]);

  const handleCreatePost = async () => {
    const newPost = await speakUpCreatePost(newPostInput, room.id);
    const newPostArray = [newPost, ...roomPosts];
    setRoomPosts(newPostArray);
  };

  return (
    <div className="speakup-room-container-wrapper">
      <div className="speakup-room-header">
        <div className="speakup-room-header-description font-size-xs ">
          {room.description}
        </div>
        <div className="speakup-room-settings font-size-xs ">
          <SpeakUpRoomFilters
            selectedFilter={selectedFilter}
            setFilter={(filter) => setSelectedFilter(filter)}
          />
          Refresh: {room.refreshTime}
        </div>
      </div>
      <div className="speakup-room-content-cotnainer">
        {!alreadyPosted && (
          <div className="speakup-room-content-new-post">
            <TextArea
              input={newPostInput}
              placeholder={"What should we do next ?"}
              label={""}
              onChange={(input) => setNewPostInput(input)}
            />
            <div className="speakup-room-content-new-post-footer">
              {newPostInput.length > 180 && (
                <span className="font-weight-semi-bold">
                  -{newPostInput.length - 180}
                </span>
              )}
              <div className="speakup-room-new-post-limit font-size-xs">
                Limit: {room.postLimit} {room.refreshTime}
              </div>
              <Button
                label={"Post"}
                disabled={newPostInput.length < 3 || newPostInput.length > 180}
                onClick={() => handleCreatePost()}
              />
            </div>
          </div>
        )}
        {roomPosts.map((post: SpeakUpPostType) => {
          const postVoted = post.votedBy.filter(
            (vote) => vote.userId === userInfo?.userId
          )[0];
          let voteType;
          if (postVoted) {
            voteType = postVoted.voteType;
          }
          return (
            <SpeakUpPost
              key={post._id}
              myPost={post.userId.toString() === userInfo?.userId?.toString()}
              isVoted={voteType}
              roomId={room.id}
              post={post}
            />
          );
        })}
      </div>
    </div>
  );
};
