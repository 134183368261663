import { useState } from "react";

import "./Customization.scss";

import { Tabs } from "../Tabs/Tabs";
import { CustomizationWallpapers } from "./CustomizationWallpapers/CustomizationWallpapers";
import { CustomizationTheme } from "./CustomizationTheme/CustomizationTheme";
import { SectionsModal } from "../SectionsModal/SectionsModal";
import { CustomizationWidgets } from "./CustomizationWidgets/CustomizationWidgets";
import { CustomizationSections } from "./CustomizationSections/CustomizationSections";
import { DashboardSectionNews } from "../Apps/Dashboard/DashboardSectionNews/DashboardSectionNews";

type CustomizationPropsType = {};

export const Customization = ({}: CustomizationPropsType) => {
  const navigationItems = [
    {
      id: "backgrounds",
      label: "Backgrounds",
      icon: "wallpaper",
      content: <CustomizationWallpapers />,
    },
    {
      id: "theme",
      label: "Theme",
      icon: "theme",
      content: <CustomizationTheme />,
    },
    {
      id: "widgets",
      label: "Widgets",
      icon: "weather",
      content: <CustomizationWidgets />,
    },
    {
      id: "sections",
      label: "Sections",
      icon: "sections",
      content: <CustomizationSections />,
    },
    {
      id: "feed",
      label: "Feed",
      icon: "news",
      content: <DashboardSectionNews />,
    },
  ];

  return <SectionsModal navigationItems={navigationItems} />;
};
