import { IconButton } from "../IconButton/IconButtont";
import "./FloatingButton.scss";

type FloatingButtonPropsType = {
  icon?: string;
  remove?: boolean;
  onClick: () => void;
};

export const FloatingButton = ({
  icon,
  remove,
  onClick,
}: FloatingButtonPropsType) => {
  return (
    <div className="floating-button-wrapper">
      <IconButton remove={remove} icon={icon || "edit"} onClick={onClick} />
    </div>
  );
};
