import React, { useEffect, useState } from "react";
import "./Spotify.scss";
import { SpotifyPlaylists } from "./SpotifyPlayLists/SpotifyPlaylists";
import { AppHeader } from "../AppHeader/AppHeader";
import { getValidSpotifyToken, handleSpotifyLogin } from "./utils";
import { Button } from "../../Button/Button";
import { AppWrapper } from "../AppWrapper/AppWrapper";
import { useDispatch, useSelector } from "react-redux";
import { setPlayerOpen } from "../../../features/spotify/spotifySlice";
import { RootState } from "../../../store";

export const Spotify = ({}: {}) => {
  const spotifyLocalStorageToken = window.localStorage.getItem("spotify_token");

  const dispatch = useDispatch();
  const isPlayerOpen = useSelector(
    (state: RootState) => state.spotify.isPlayerOpen
  );

  const openPlayer = () => {
    dispatch(setPlayerOpen(true));
  };
  useEffect(() => {
    getValidSpotifyToken();
  }, []);

  return (
    <AppWrapper title={"Spotify"} icon={"spotify"}>
      <div className="spotify-container">
        {spotifyLocalStorageToken ? (
          <SpotifyPlaylists
            openPlayer={isPlayerOpen}
            clickedPlaylist={() => !isPlayerOpen && openPlayer()}
          />
        ) : (
          <Button label={"Login to Spotify"} onClick={handleSpotifyLogin} />
        )}
      </div>
    </AppWrapper>
  );
};
