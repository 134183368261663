import { useEffect, useState } from "react";
import { getWidgetsStateFromLocalStorage } from "../../utils/helpers";
import { Clock } from "../Apps/Clock/Clock";
import { Weather } from "../Apps/Weather/Weather";
import { SpotifyPlayer } from "../Apps/Spotify/SpotifyPlayer/SpotifyPlayer";

import "./WidgetsBar.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type WidgetsBarPropsType = {};

export const WidgetsBar = ({}: WidgetsBarPropsType) => {
  const widgetState = getWidgetsStateFromLocalStorage();

  const isPlayerOpen = useSelector(
    (state: RootState) => state.spotify.isPlayerOpen
  );

  return (
    <div className="widget-bar-container">
      <div className="widget-bar-sidebar">
        <div className="home-section-clock ">
          {widgetState && widgetState.includes("weather") && <Weather />}
          {widgetState && widgetState.includes("clock") && <Clock />}
        </div>
        <div className="home-section-bottom ">
          {isPlayerOpen && <SpotifyPlayer close={() => console.log("s")} />}
        </div>
      </div>
    </div>
  );
};
