import { useState } from "react";
import { InputField } from "../../InputField/InputField";
import "./BookmarkFolderModal.scss";
import { Button } from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  createFolder,
  deleteFolder,
  updateFolder,
} from "../../../features/bookmarks/folders/foldersActions";
import Dropdown from "../../Dropdown/Dropdown";
import {
  CategoryState,
  CategoryType,
} from "../../../features/bookmarks/categories/categoriesSlice";
import { IconButton } from "../../IconButton/IconButtont";

type BookmarkFolderModalPropsType = {
  editLogic?: boolean;
  currentName?: string;
  id?: string;
  categoryId?: string;
  currentUrl?: string;
  onModalClose: () => void;
};

export const BookmarkFolderModal = ({
  editLogic,
  currentName,
  categoryId,
  id,
  onModalClose,
}: BookmarkFolderModalPropsType) => {
  const [nameInputValue, setNameInputValue] = useState(currentName || "");
  const [selectedCategory, setSelectedCategory] = useState(categoryId || null);

  const categoriesState = useSelector((state: RootState) => state.categories);

  const { categories } = categoriesState;
  const dispatch: AppDispatch = useDispatch();

  const handleCreateFolder = () => {
    if (nameInputValue.length > 0) {
      dispatch(
        createFolder({
          name: nameInputValue,
          categoryId: selectedCategory ? selectedCategory : undefined,
        })
      );
      onModalClose();
    }
  };

  const handleEditFolder = () => {
    if (currentName !== nameInputValue || categoryId !== selectedCategory) {
      if (id) {
        dispatch(
          updateFolder({
            folderId: id,
            name: nameInputValue,
            categoryId: selectedCategory,
            oldCategory: categoryId,
          })
        );
        onModalClose();
      } else {
        console.log("Folder ID missing for update");
      }
    }
  };

  const handleDeleteFolder = () => {
    if (id) {
      dispatch(
        deleteFolder({
          folderId: id,
          categoryId: selectedCategory ? selectedCategory : undefined,
        })
      );
      onModalClose();
    } else {
      console.log("Missing id to delete");
    }
  };

  return (
    <div
      className={`add-folder-container-wrapper ${
        !editLogic ? "add-folder-container-wrapper-single-button" : ""
      }`}
    >
      <div className="add-folder-container">
        <InputField
          label="Name"
          autoFocus
          input={nameInputValue}
          placeholder={"Add name"}
          onChange={(input: string) => setNameInputValue(input)}
        />
        <Dropdown
          items={categories.map((item) => ({ label: item.name, id: item._id }))}
          label={"Category"}
          emptyOption
          placeholder={"Category"}
          selectedItem={selectedCategory}
          setSelectedItem={(item) =>
            setSelectedCategory(item === "none" ? null : item)
          }
        />
        <div className="add-folder-buttons-container">
          {editLogic && (
            <IconButton onClick={handleDeleteFolder} remove icon={"trash"} />
          )}
          <div>
            <Button
              submitButton
              onClick={editLogic ? handleEditFolder : handleCreateFolder}
              icon="folder"
              label="Save"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
