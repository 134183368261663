import axios from "axios";
import { APIURL } from "./apiUtils";

export const getNews = async (pageNum) => {
  try {
    const response = await axios.get(`${APIURL}/news/usernews/${pageNum}`);
    return response.data;
  } catch (error) {
    console.log("Could not fetch profile", error);
  }
};

export const getSources = async () => {
  try {
    const response = await axios.get(`${APIURL}/news/sources`);
    return response.data;
  } catch (error) {
    console.log("Could not fetch profile", error);
  }
};

//For bad quality images
export const excludeArticleMedia = ["Dailymail Showbiz", "Skynews Entertainment", "Motorsport Magazin", "Dailymail Travel"];