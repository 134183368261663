import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { Toaster } from "react-hot-toast";

import { AppDispatch } from "./store";
import { initializeApp } from "./features/auth/authActions";
import { MainScreen } from "./components/MainScreen/MainScreen";
import ProtectedRoute from "./utils/ProtectedRoute";
import { PresentationScreen } from "./components/PresentationScreen/PresentationScreen";
import {
  defaultToastStyles,
} from "./utils/helpers";
import { SpotifyAuthScreen } from "./components/SpotifyAuthScreen/SpotifyAuthScreen";

import "./App.scss";
import { setBlurStyle, setPrimaryColor } from "./styles/styleUtils";
import { ModalComponent } from "./components/ModalComponent/ModalComponent";
import { getNews } from "./utils/news";
import { LegalScreen } from "./components/LegalScreen/LegalScreen";
import { LoginScreen } from "./components/LoginScreen/LoginScreen";

function App() {
  Modal.setAppElement(document.getElementById("root") || "#root");

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(initializeApp());
  }, []);

  useEffect(() => {
    setPrimaryColor();
    setBlurStyle();
  }, []);

  return (
    <Router>
      <main
        onContextMenu={(e) => {
          e.preventDefault(); // prevent the default behaviour when right clicked
        }}
        className="container content"
      >
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainScreen />
              </ProtectedRoute>
            }
          >
            <Route path={`/:modalId/*`} element={<ModalComponent />} />
          </Route>
          <Route
            path="/spotify_auth_user"
            element={
              <ProtectedRoute>
                <SpotifyAuthScreen />
              </ProtectedRoute>
            }
          />
          <Route path="/home" element={<PresentationScreen />} />
          <Route path="/login/:path?/:code?" element={<LoginScreen />} />
          <Route path="/legal/:section" element={<LegalScreen />} />
        </Routes>
        <Toaster toastOptions={defaultToastStyles} />
      </main>
    </Router>
  );
}

export default App;
