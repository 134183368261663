import {
  createSlice,
  PayloadAction,
  SerializedError,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import {
  registerUser,
  RegisterPayload,
  initializeApp,
  loginUser,
  logOut,
} from "./authActions";
import { UserPreferencesType } from "../../utils/helpers";

interface AuthState {
  loading: boolean;
  userInfo: {
    email: string;
    username?: string;
    userId: string;
    preferences?: { wallpaperCollection: string; cryptoWidget?: string[] };
  } | null;
  error: string | null;
  success: boolean;
}

const initialState: AuthState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        registerUser.fulfilled,
        (
          state,
          action: PayloadAction<{
            userInfo: {
              email: string;
              username: string;
              userId: string;
            };
            preferences: UserPreferencesType;
            completedOnboarding: boolean;
          }>
        ) => {
          state.loading = false;
          state.success = true;
          state.userInfo = action.payload.userInfo;
          localStorage.setItem(
            "onboarding_completed",
            JSON.stringify(action.payload.completedOnboarding)
          );
          localStorage.setItem("user", JSON.stringify(action.payload.userInfo));
          localStorage.setItem(
            "preferences",
            JSON.stringify(action.payload.preferences)
          );
        }
      )
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload as string;
        } else if (action.error.message) {
          state.error = action.error.message;
        } else {
          state.error = "Unknown error";
        }
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loginUser.fulfilled,
        (
          state,
          action: PayloadAction<{
            userInfo: {
              email: string;
              username?: string;
              userId: string;
            };
            completedOnboarding: boolean;
            preferences: UserPreferencesType;
          }>
        ) => {
          state.loading = false;
          state.success = true;
          state.userInfo = action.payload.userInfo;
          localStorage.setItem(
            "onboarding_completed",
            JSON.stringify(action.payload.completedOnboarding)
          );
          localStorage.setItem("user", JSON.stringify(action.payload.userInfo));
          localStorage.setItem(
            "preferences",
            JSON.stringify(action.payload.preferences)
          );
        }
      )
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload as string;
        } else if (action.error.message) {
          state.error = action.error.message;
        } else {
          state.error = "Unknown error";
        }
      })
      .addCase(initializeApp.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        initializeApp.fulfilled,
        (
          state,
          action: PayloadAction<{
            userInfo: {
              email: string;
              username?: string;
              userId: string;
            };
          }>
        ) => {
          state.userInfo = action.payload.userInfo;
          state.loading = false;
        }
      )
      .addCase(initializeApp.rejected, (state) => {
        state.loading = false;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.userInfo = null;
        state.loading = false;
        state.success = false;
        state.error = null;
        localStorage.removeItem("preferences");
        localStorage.removeItem("widgetState");
        localStorage.removeItem("user");
        localStorage.removeItem("onboarding_completed");
        localStorage.removeItem("openCategoriesStates");
      });
  },
});

export default authSlice.reducer;
