import { ReactNode } from "react";
import { AppHeader } from "../AppHeader/AppHeader";
import "./AppWrapper.scss";

type AppWrapperPropsType = {
  title: string;
  icon: string;
  children: ReactNode;
  openSettings?: () => void;
};

export const AppWrapper = ({
  title,
  icon,
  children,
  openSettings,
}: AppWrapperPropsType) => {
  return (
    <div className="app-wrapper-container app-wrapper main-app-background">
      <AppHeader openSettings={openSettings} title={title} widgetIcon={icon} />
      <div className="app-wrapper-content-container">{children}</div>
    </div>
  );
};
