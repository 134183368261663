import { useEffect } from "react";

type SpotifyAuthScreenPropsType = {};

export const SpotifyAuthScreen = ({}: SpotifyAuthScreenPropsType) => {
  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1)); // remove the '#' symbol
    const accessToken = params.get("access_token");
    const refreshToken = params.get("refresh_token");
    const expires_in = params.get("expires_in");
    if (refreshToken) {
      localStorage.setItem("spotify_refresh_token", refreshToken);
    }
    if (expires_in) {
      const expirationTime = new Date().getTime() + parseInt(expires_in) * 1000;
      localStorage.setItem("spotify_expires_at", expirationTime.toString());
    }
    if (accessToken) {
      localStorage.setItem("spotify_token", accessToken);
      window.location.replace("/");
    }
  }, []);

  return <div></div>;
};
