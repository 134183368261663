import { SectionsModal } from "../../SectionsModal/SectionsModal";
import { SettingsBugReport } from "../../Settings/SettingsBugReport/SettingsBugReport";
import { SettingsHelp } from "../../Settings/SettingsHelp/SettingsHelp";
import { DashboardSectionRequestApp } from "./DashboardSectionRequestApp/DashboardSectionRequestApp";
import { DashboardSectionDevelopers } from "./DashboardSectionDevelopers/DashboardSectionDevelopers";

import "./DashboardModal.scss";
import { DashboardLegalSection } from "./DashboardLegalSection/DashboardLegalSection";
import { SettingsProfile } from "../../Account/Account";

type DashboardModalPropsType = {};

export const DashboardModal = ({}: DashboardModalPropsType) => {
  const navigationItems = [
    {
      id: "account",
      label: "Account",
      icon: "user",
      content: <SettingsProfile />,
    },
    {
      id: "request-app",
      label: "Request app",
      icon: "add",
      content: <DashboardSectionRequestApp />,
    },
    {
      id: "developers",
      label: "Developers",
      icon: "code",
      content: <DashboardSectionDevelopers />,
    },
    {
      id: "bug-report",
      label: "Bug Report",
      icon: "bug",
      content: <SettingsBugReport />,
    },
    {
      id: "help",
      label: "Help",
      icon: "help",
      content: <SettingsHelp />,
    },
    {
      id: "legal",
      label: "Legal",
      icon: "legal",
      content: <DashboardLegalSection />,
    },
  ];
  return <SectionsModal separatorIndex={3} navigationItems={navigationItems} />;
};
