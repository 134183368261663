import "./TextArea.scss";
import { getSvgByName } from "../../assets/getIcon";

type TextAreaPropsType = {
  input: string;
  placeholder: string;
  label: string;
  maxLength?: number;
  minLength?: number;
  searchButton?: () => void;
  onChange: (input: string) => void;
};

export const TextArea = ({
  input,
  placeholder,
  label,
  maxLength,
  minLength,
  onChange,
}: TextAreaPropsType) => {
  return (
    <div className="text-area-container-wrapper">
      <div className="text-area-container">
        <label className="label-default">{label}</label>
        <div className="text-area-input-wrapper">
          <textarea
            maxLength={maxLength}
            onContextMenu={(e) => e.stopPropagation()}
            minLength={minLength}
            placeholder={placeholder}
            value={input}
            onChange={(e) => onChange(e.target.value)}
            className="input-field-style input-field-style__textarea "
          />
        </div>
        {(minLength || maxLength) && (
          <div className="character-count">
            {minLength && input.length < minLength ? (
              <span className="character-count__red">
                -{minLength - input.length}
              </span>
            ) : (
              maxLength && `${input.length} / ${maxLength}`
            )}
          </div>
        )}
      </div>
    </div>
  );
};
