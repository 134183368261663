import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addFavorite,
  deleteFavorite,
  getFavorites,
  updateFavorite,
} from "./favoritesAction";
import toast from "react-hot-toast";

export type FavoriteType = {
  name: string;
  url: string;
  iconUrl: string;
  folderId: string;
  userId: string;
  description: string;
  _id: string;
  bookmarkId: string;
};

export interface FavoritesState {
  loading: boolean;
  favorites: FavoriteType[];
  error: boolean;
  success: boolean;
}

const initialState: FavoritesState = {
  loading: false,
  favorites: [],
  error: false,
  success: false,
};

const favoritesSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFavorites.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        getFavorites.fulfilled,
        (state, action: PayloadAction<FavoriteType[]>) => {
          state.loading = false;
          state.error = false;
          state.success = true;
          state.favorites = action.payload;
        }
      )
      .addCase(getFavorites.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(addFavorite.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(addFavorite.fulfilled, (state, action) => {
        toast.dismiss();
        toast.success("Saved");
        state.loading = false;
        state.error = false;
        state.success = true;
      })
      .addCase(addFavorite.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, please try again");
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(updateFavorite.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(updateFavorite.fulfilled, (state, action) => {
        toast.dismiss();
        toast.success("Saved");
        state.loading = false;
        state.error = false;
        state.success = true;
      })
      .addCase(updateFavorite.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, please try again");
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(deleteFavorite.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(deleteFavorite.fulfilled, (state, action) => {
        toast.dismiss();
        toast.success("Removed from favorites bar");
        state.loading = false;
        state.error = false;
        state.success = true;
      })
      .addCase(deleteFavorite.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, please try again");
        state.loading = false;
        state.error = true;
        state.success = false;
      });
  },
});

export default favoritesSlice.reducer;
