import { getSvgByName } from "../../../assets/getIcon";
import { IconButton } from "../../IconButton/IconButtont";
import "./AppHeader.scss";

type AppHeaderPropsType = {
  widgetIcon: string;
  title: string;
  openSettings?: () => void;
};

export const AppHeader = ({
  widgetIcon,
  title,
  openSettings,
}: AppHeaderPropsType) => {
  return (
    <div className="widget-header-container overtitle">
      <>
        {getSvgByName(widgetIcon)}
        <p className="font-weight-semi-bold" >{title}</p>
      </>
      {openSettings && (
        <IconButton onClick={() => openSettings()} icon="edit" />
      )}
    </div>
  );
};
