import {  createAsyncThunk } from "@reduxjs/toolkit";
import { FolderType } from "./foldersSlice";
import axios from "axios";
import { BookmarkType } from "../bookmarks/bookmarksSlice";
import {
  getCategoryFolders,
} from "../categories/categoriesActions";
import { APIURL } from "../../../utils/apiUtils";
import { getBookmarks } from "../bookmarks/bookmarksActions";

if (!APIURL) {
  console.log("ERROR NO API ENV VARIABLE");
}

export const getFolders = createAsyncThunk<
  FolderType[],
  void,
  { rejectValue: string }
>("getFolders", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${APIURL}/folders`);
    return response.data.folders;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getFolderBookmarks = createAsyncThunk<
  BookmarkType[],
  { folderId: string },
  { rejectValue: string }
>("getFolderBookmarks", async (folderDetails, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${APIURL}/folders/${folderDetails.folderId}`
    );
    return response.data.folderBookmarks;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const createFolder = createAsyncThunk<
  FolderType[],
  { name: string; categoryId?: string },
  { rejectValue: string }
>("createFolder", async (folder, { rejectWithValue, dispatch }) => {
  try {
    const body: { name: string; categoryId?: string } = {
      name: folder.name,
    };

    if (folder.categoryId) {
      body.categoryId = folder.categoryId;
    }

    const response = await axios.post(`${APIURL}/folders/`, body);
    dispatch(getFolders());
    if (folder.categoryId) {
      dispatch(getCategoryFolders({ categoryId: folder.categoryId }));
    }
    return response.data.folderBookmarks;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateFolder = createAsyncThunk<
  FolderType[],
  {
    folderId: string;
    name: string;
    categoryId?: string | null;
    oldCategory?: string;
  },
  { rejectValue: string }
>("updateFolder", async (folder, { rejectWithValue, dispatch }) => {
  try {
    const body: { name: string; categoryId?: string | null } = {
      name: folder.name,
    };

    if (folder.categoryId || folder.categoryId === null) {
      body.categoryId = folder.categoryId;
    }

    const response = await axios.put(
      `${APIURL}/folders/${folder.folderId}`,
      body
    );
    dispatch(getFolders());
    if (folder.categoryId) {
      dispatch(
        getCategoryFolders({
          categoryId: folder.categoryId,
        })
      );
    }
    if (folder.oldCategory) {
      dispatch(
        getCategoryFolders({
          categoryId: folder.oldCategory,
        })
      );
    }
    if (response.data.hadBookmarks) {
      dispatch(getBookmarks());
    }
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const deleteFolder = createAsyncThunk<
  FolderType[],
  { folderId: string; categoryId?: string },
  { rejectValue: string }
>("deleteFolder", async (folder, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.delete(`${APIURL}/folders/${folder.folderId}`);
    dispatch(getFolders());
    if (folder.categoryId) {
      dispatch(getCategoryFolders({ categoryId: folder.categoryId }));
    }
    if (response.data.hadBookmarks) {
      dispatch(getBookmarks());
    }
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      return rejectWithValue(error.response.data.error);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
