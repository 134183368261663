import "./InputField.scss";
import { getSvgByName } from "../../assets/getIcon";
import { handleOpenContextMenu } from "../../utils/helpers";

type InputFieldPropsType = {
  input: string;
  placeholder: string;
  label: string;
  type?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  searchButton?: () => void;
  onChange: (input: string) => void;
};

export const InputField = ({
  input,
  placeholder,
  label,
  type,
  autoComplete,
  autoFocus,
  searchButton,
  onChange,
}: InputFieldPropsType) => {
  return (
    <div className="input-field-container-wrapper">
      <div className="input-field-container">
        <label className="label-default">{label}</label>
        <div className="input-field-input-wrapper">
          <input
            onContextMenu={(e) => e.stopPropagation()}
            autoFocus={autoFocus}
            type={type || "text"}
            autoComplete={autoComplete}
            placeholder={placeholder}
            value={input}
            onChange={(e) => onChange(e.target.value)}
            className="input-field-style"
            onKeyDown={(event) =>
              event.key === "Enter" && searchButton && searchButton()
            }
          />
          {searchButton && (
            <button disabled={input.length < 3} onClick={searchButton}>
              {getSvgByName("search")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
