import { ContextMenuComponents } from "./ContextMenu";

const sidebarContextMenu = [
  { label: "New folder", icon: "add-folder", action: "sidebar:open-folder-modal" },
  {
    label: "New category",
    icon: "category",
    action: "sidebar:open-category-modal",
  },
];

export const getContextMenu = (component: ContextMenuComponents) => {
  switch (component) {
    case "sidebar":
      return sidebarContextMenu;
  }
};
