import { DefaultModalSection } from "../../../SectionsModal/DefaultModalSection/DefaultModalSection";
import { SectionsModal } from "../../../SectionsModal/SectionsModal";
import "./SpeakUpDashboard.scss";

type SpeakUpDashboardPropsType = {};

export const SpeakUpDashboard = ({}: SpeakUpDashboardPropsType) => {
  // const navigationItems = [
  //   {
  //     id: "rooms",
  //     label: "Rooms",
  //     icon: "theme",
  //     content: <h1>This is under construction</h1>,
  //   },
  //   {
  //     id: "create",
  //     label: "Create room",
  //     icon: "apps",
  //     content: <h1>Working on it!</h1>,
  //   },
  //   {
  //     id: "help",
  //     label: "Help",
  //     icon: "store",
  //     content: <h1>20 pushups</h1>,
  //   },
  // ];
  // return <SectionsModal navigationItems={navigationItems} />;
  return (
    <div className="speakup-dashboard-container">
      <DefaultModalSection
        sectionIcon={"speakup"}
        title={"The Wall - Beta "}
        description="When users lead the way, products transcend their basic purpose. They become extensions of the community's collective will, embodying our aspirations and dreams. The SleekTab community doesn't just deserve credit for what SleekTab is today—it's the heartbeat of everything we aspire to be."
        subTitle={
          "The wall is where the community shapes the future of SleekTab"
        }
        comingSoonMessage
      />
    </div>
  );
};
