import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SpotifyState {
  isPlayerOpen: boolean;
  selectedPlayList: string | undefined;
}

const initialState: SpotifyState = {
  isPlayerOpen: false,
  selectedPlayList: undefined,
};

const spotifySlice = createSlice({
  name: "spotify",
  initialState,
  reducers: {
    setPlayerOpen: (state, action: PayloadAction<boolean>) => {
      state.isPlayerOpen = action.payload;
    },
    setSelectedPlaylist: (state, action: PayloadAction<string | undefined>) => {
      state.selectedPlayList = action.payload;
    },
  },
});

export const { setPlayerOpen, setSelectedPlaylist } = spotifySlice.actions;

export default spotifySlice.reducer;
