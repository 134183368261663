import { useState } from "react";
import BookmarksVideo from "../../../assets/demo/bookmarks.gif";
import AiVideo from "../../../assets/demo/ai.gif";
import AppsVideo from "../../../assets/demo/apps.gif";
import CustomizationVideo from "../../../assets/demo/customization.gif";
import TimelineVideo from "../../../assets/demo/timeline.gif";
import { PresentationCard } from "../PresentationCard/PresentationCard";

import "./PresentationDemoSection.scss";
import { getSvgByName } from "../../../assets/getIcon";

type PresentationDemoSectionPropsType = {};

export const PresentationDemoSection =
  ({}: PresentationDemoSectionPropsType) => {
    const [selectedSection, setSelectedSection] = useState("Super Bookmarks");

    const featuresDisplayList = [
      {
        icon: "feature-bookmark",
        title: "Super Bookmarks",
        description: "Easy to save, simple and quick to find and access.",
      },
      {
        icon: "apps",
        title: "Apps & Widgets",
        description:
          "Discover and try the apps and widgets created by the community.",
      },
      {
        icon: "wallpaper",
        title: "Customization",
        description:
          "Style it! Wallpapers packs, animated backgrounds, themes and more.",
      },
      {
        icon: "news",
        title: "Timeline",
        description:
          "News or viral content, you pick the sources, only what is relevant.",
      },
      {
        icon: "bot",
        title: "AI",
        description:
          "Your AI assistant in your homepage, to organize your things or chat.",
      },
    ];

    const demoSources: any = {
      "Super Bookmarks": BookmarksVideo,
      "Apps & Widgets": AppsVideo,
      Customization: CustomizationVideo,
      Timeline: TimelineVideo,
      AI: AiVideo,
    };

    const getSectionContent = () => {
      return <img src={demoSources[selectedSection]} />;
    };

    const getSectionIcon = (sectionName: string) => {
      const sectionIcon = featuresDisplayList.find(
        (item) => item.title === sectionName
      )?.icon;
      if (sectionIcon) {
        return getSvgByName(sectionIcon);
      }
    };

    return (
      <div className="presentation-demo-container">
        <div className="presentation-demo-sections">
          {featuresDisplayList.map((card, i) => (
            <PresentationCard
              key={card.title}
              selected={card.title === selectedSection}
              onClick={() => {
                setSelectedSection(card.title);
              }}
              description={card.description}
              icon={card.icon}
              title={card.title}
            />
          ))}
        </div>
        <h3 className="presentation-demo-title">
          {getSectionIcon(selectedSection)} {selectedSection}
        </h3>
        <div className="presentation-demo-image-container">
          {getSectionContent()}
        </div>
      </div>
    );
  };
