import { Link } from "react-router-dom";
import { DefaultModalSection } from "../../SectionsModal/DefaultModalSection/DefaultModalSection";
import "./CustomizationWallpapers.scss";
import { useEffect, useState } from "react";
import {
  getPreferencesFromLocalStorage,
  updatePreferencesInLocalStorage,
} from "../../../utils/helpers";
import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";
import {
  WallpaperPackItem,
  WallpaperPackType,
} from "../../WallpaperPackItem/WallpaperPackItem";

type CustomizationWallpapersPropsType = {};

export const CustomizationWallpapers =
  ({}: CustomizationWallpapersPropsType) => {
    const [userWallpaperPacks, setUserWallpaperPacks] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWallpaperCollection, setSelectedWallpaperCollection] =
      useState(getPreferencesFromLocalStorage().wallpaperCollection);

    const getMyCollections = async () => {
      setIsLoading(true);
      await axios
        .get(`${APIURL}/wallpapers/mycollections`)
        .then((response) => {
          setUserWallpaperPacks(response.data.wallpaperPacks);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error getting mywallpaper", error);
        });
    };

    useEffect(() => {
      getMyCollections();
    }, []);

    const handleCollectionClick = async (packId: string) => {
      setSelectedWallpaperCollection(packId);
      const body = {
        packId: packId,
      };
      await axios
        .put(`${APIURL}/wallpapers/`, body)
        .then((response) => {
          updatePreferencesInLocalStorage(response.data.preferences);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error changing wallpaper", error);
        });
    };
    const handleDeleteClick = async (packId: string) => {
      if (
        userWallpaperPacks.length === 2 &&
        selectedWallpaperCollection === "mix"
      ) {
        await handleCollectionClick("default");
      }
      const body = {
        wallpaperId: packId,
      };
      await axios
        .put(`${APIURL}/wallpapers/library`, body)
        .then((response) => {
          getMyCollections();
        })
        .catch((error) => {
          console.error("Error changing wallpaper", error);
        });
    };

    return (
      <div className="wallpaper-settings-library">
        <DefaultModalSection
          sectionIcon={"wallpaper"}
          sectionHeader
          title={"Change your wallpaper"}
          subTitle={
            <p>
              You can find more collections in
              <Link to="/market/backgrounds"> the Market</Link>
            </p>
          }
        />
        <div className="wallpaper-settings-library-buttons">
          <button
            disabled={selectedWallpaperCollection === "default"}
            className={
              selectedWallpaperCollection === "default"
                ? "wallpaper-settings-library-buttons__selected"
                : ""
            }
            onClick={() => handleCollectionClick("default")}
          >
            <p>Random wallpapers</p>
          </button>
          {userWallpaperPacks.length > 1 && (
            <button
              disabled={selectedWallpaperCollection === "mix"}
              className={
                selectedWallpaperCollection === "mix"
                  ? "wallpaper-settings-library-buttons__selected"
                  : ""
              }
              onClick={() => handleCollectionClick("mix")}
            >
              <p>Mix your collections</p>
            </button>
          )}
        </div>
        <div className="wallpaper-settings-library-list">
          {isLoading ? (
            <div className="wallpaper-loading-container">
              <div className="wallpaper-settings-library-loading border-radius-s skeleton-loading "></div>
              <div className="wallpaper-settings-library-loading border-radius-s skeleton-loading "></div>
              <div className="wallpaper-settings-library-loading border-radius-s skeleton-loading "></div>
              <div className="wallpaper-settings-library-loading border-radius-s skeleton-loading "></div>
            </div>
          ) : (
            userWallpaperPacks &&
            userWallpaperPacks.map((pack: WallpaperPackType, i: any) => (
              <WallpaperPackItem
                key={pack.id}
                wallpaperPack={pack}
                inLibrary
                clickedDelete={() => handleDeleteClick(pack.id)}
                banner={
                  selectedWallpaperCollection === pack.id
                    ? "Selected"
                    : undefined
                }
                clicked={() => handleCollectionClick(pack.id)}
              />
            ))
          )}
        </div>
      </div>
    );
  };
