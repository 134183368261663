import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addBookmark,
  deleteBookmark,
  getBookmarks,
  updateBookmark,
} from "./bookmarksActions";
import toast from "react-hot-toast";

export type BookmarkType = {
  name: string;
  url: string;
  iconUrl: string;
  folderId: string | undefined;
  userId: string;
  description: string;
  currentFolderName: string | undefined;
  _id: string;
  createdAt: string;
};

export interface BookmarksState {
  loading: boolean;
  bookmarks: BookmarkType[];
  error: boolean;
  success: boolean;
}

const initialState: BookmarksState = {
  loading: false,
  bookmarks: [],
  error: false,
  success: false,
};

const bookmarksSlice = createSlice({
  name: "bookmarks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBookmarks.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        getBookmarks.fulfilled,
        (state, action: PayloadAction<BookmarkType[]>) => {
          state.loading = false;
          state.error = false;
          state.success = true;
          state.bookmarks = action.payload;
        }
      )
      .addCase(getBookmarks.rejected, (state, action) => {
        toast.dismiss();
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(addBookmark.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        addBookmark.fulfilled,
        (state, action: PayloadAction<BookmarkType>) => {
          toast.dismiss();
          toast.success("Saved");
          state.loading = false;
          state.error = false;
          state.success = true;
        }
      )
      .addCase(addBookmark.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, try again later");
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(updateBookmark.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        updateBookmark.fulfilled,
        (state, action: PayloadAction<BookmarkType>) => {
          toast.dismiss();
          toast.success("Saved");
          state.loading = false;
          state.error = false;
          state.success = true;
        }
      )
      .addCase(updateBookmark.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, try again later");
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(deleteBookmark.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        deleteBookmark.fulfilled,
        (state, action: PayloadAction<BookmarkType>) => {
          toast.dismiss();
          toast.success("Bookmark deleted");
          state.loading = false;
          state.error = false;
          state.success = true;
        }
      )
      .addCase(deleteBookmark.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, try again later");
        state.loading = false;
        state.error = true;
        state.success = false;
      });
  },
});

export default bookmarksSlice.reducer;
