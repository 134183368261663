import { useState } from "react";
import {
  availableBlurStyle,
  availableThemeColors,
  changeUserBlurStyle,
  changeUserColor,
} from "../../../styles/styleUtils";
import { DefaultModalSection } from "../../SectionsModal/DefaultModalSection/DefaultModalSection";
import "./CustomizationWidgets.scss";
import {
  getPreferencesFromLocalStorage,
  getWidgetsStateFromLocalStorage,
  updateWidgetsStateInLocalStorage,
} from "../../../utils/helpers";
import { Button } from "../../Button/Button";

type CustomizationWidgetsPropsType = {};

export const CustomizationWidgets = ({}: CustomizationWidgetsPropsType) => {
  const [activeWidgets, setActiveWidgets] = useState(
    getWidgetsStateFromLocalStorage() || []
  );

  const availableWidgets = [
    { id: "clock", name: "Clock", icon: "clock" },
    { id: "weather", name: "Weather", icon: "weather" },
    { id: "quotes", name: "Quotes", icon: "quotes" },
  ];

  const handleWidgetClick = (widget: string) => {
    console.log(activeWidgets);
    if (activeWidgets.includes(widget)) {
      const newActiveWidgets = activeWidgets.filter(
        (activeWidget: string) => activeWidget !== widget
      );
      setActiveWidgets(newActiveWidgets);
      updateWidgetsStateInLocalStorage(newActiveWidgets);
    } else {
      const newActiveWidgets = [...activeWidgets, widget];
      setActiveWidgets(newActiveWidgets);
      updateWidgetsStateInLocalStorage(newActiveWidgets);
    }
  };

  return (
    <div className="customize-widgets-container">
      <DefaultModalSection
        sectionIcon={"theme"}
        sectionHeader
        title={"Customize your widgets"}
        subTitle="Widgets"
      />
      <div className="customize-widgets-list">
        {availableWidgets.map((widget) => (
          <Button
            label={widget.name}
            icon={widget.icon}
            secondary
            className={`smooth-border ${
              activeWidgets.includes(widget.id)
                ? "customize-widgets-button-active"
                : ""
            }`}
            onClick={() => handleWidgetClick(widget.id)}
          />
        ))}
      </div>
    </div>
  );
};
