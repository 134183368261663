import { useEffect, useState } from "react";

import { Bookmarks } from "../Bookmarks/Bookmarks";
import { Apps } from "../Apps/Apps";
import { Spinner } from "../Spinner/Spinner";
import { ContextMenu } from "../ContextMenu/ContextMenu";
import useContextMenu from "../../hooks/useContextMenu";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import {
  fetchWallpaperUrl,
  getOnboardingCompletedLocalStorage,
} from "../../utils/helpers";

import "./MainScreen.scss";
import React from "react";
import { getSvgByName } from "../../assets/getIcon";
import { Dock } from "../Apps/Dock/Dock";
import { getOpenApp } from "../../utils/appsUtils";
import { FavoritesBar } from "../Bookmarks/FavoritesBar/FavoritesBar";
import { WidgetsBar } from "../WidgetsBar/WidgetsBar";
import News from "../News/News";

const HomeSection = React.lazy(() => import("./HomeSection/HomeSection"));

export const MainScreen = () => {
  const [wallpaperUrl, setWallpaperUrl] = useState("");
  const [videoWallpaperUrl, setVideoWallpaperUrl] = useState("");
  const [videoOpacity, setVideoOpacity] = useState(0);
  const { contextMenuState, closeContextMenu } = useContextMenu();
  const [isOpenOnboarding, setIsOpenOnboarding] = useState(false);
  const [loadSections, setLoadSections] = useState(false);
  const [openApp, setOpenApp] = useState<string | undefined>();

  const params = useParams();
  const navigate = useNavigate();
  const modalId = params["modalId"];

  const getWallpaper = async () => {
    const wallpaper: any = await fetchWallpaperUrl();
    if (!wallpaper) {
      return;
    }
    if (wallpaper.isLive) {
      setVideoWallpaperUrl(wallpaper.url);
      setWallpaperUrl("");
    } else {
      setWallpaperUrl(wallpaper.url);
      setVideoWallpaperUrl("");
    }
  };

  useEffect(() => {
    if (modalId === "onboarding" && isOpenOnboarding) {
      setIsOpenOnboarding(true);
    } else if (modalId !== "onboarding" && isOpenOnboarding) {
      setIsOpenOnboarding(false);
    }
  }, [isOpenOnboarding, modalId]);

  useEffect(() => {
    if (videoWallpaperUrl) {
      setVideoOpacity(1);
    }
  }, [videoWallpaperUrl]);

  useEffect(() => {
    getWallpaper();
  }, []);

  useEffect(() => {
    const isOnboardingCompletted = getOnboardingCompletedLocalStorage();
    console.log(isOnboardingCompletted);
    if (!isOnboardingCompletted) {
      navigate("/onboarding/1");
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadSections(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const backgroundImage = `url(${wallpaperUrl})`;

  return (
    <div
      className={`main-screen-container-wrapper ${
        isOpenOnboarding ? "main-screen-container-wrapper__onboarding" : ""
      } ${wallpaperUrl ? "main-screen-container-wrapper-loaded-image" : ""} `}
      style={
        {
          "--background-image": backgroundImage,
        } as any
      }
    >
      {videoWallpaperUrl && (
        <div className="video-container">
          <video
            onLoadedData={() => setVideoOpacity(1)} // This triggers when the video is ready to play
            style={{ opacity: videoOpacity }}
            autoPlay
            key={videoWallpaperUrl} // Add a key that changes when the URL changes
            loop
            muted
            playsInline
            className="video-background"
          >
            <source src={videoWallpaperUrl} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div className="video-overlay"></div>{" "}
        </div>
      )}
      <div className="mobile-info-message">
        <div className="mobile-info-message-header">
          {getSvgByName("millerio")} SleekTab
        </div>
        <h2>We are still working on the mobile app 📱</h2>
        <p>You can enjoy SleekTab on desktop</p>
      </div>
      <div className="main-screen-container">
        <div className="main-screen-dock-section">
          <Dock openApp={openApp} onClick={(appId) => setOpenApp(appId)} />
          {getOpenApp(openApp)}
        </div>
        <div className="main-screen-main-container">
          <FavoritesBar />
          {loadSections && <HomeSection />}
        </div>
        <div className="main-screen-widgets-container">
          <WidgetsBar />
        </div>
      </div>
      {contextMenuState && (
        <ContextMenu
          close={() => closeContextMenu()}
          contextMenu={contextMenuState}
        />
      )}
      <Outlet />
    </div>
  );
};
