import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createFolder,
  deleteFolder,
  getFolderBookmarks,
  getFolders,
  updateFolder,
} from "./foldersActions";
import { BookmarkType } from "../bookmarks/bookmarksSlice";
import toast from "react-hot-toast";

export type FolderType = {
  name: string;
  categoryId?: string | null;
  _id: string;
};

export interface FoldersState {
  loading: boolean;
  folders: FolderType[];
  folderBookmarks: BookmarkType[];
  error: boolean;
  success: boolean;
}

const initialState: FoldersState = {
  loading: false,
  folders: [],
  folderBookmarks: [],
  error: false,
  success: false,
};

const foldersSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFolders.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        getFolders.fulfilled,
        (state, action: PayloadAction<FolderType[]>) => {
          state.loading = false;
          state.error = false;
          state.success = true;
          state.folders = action.payload;
        }
      )
      .addCase(getFolders.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(getFolderBookmarks.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        getFolderBookmarks.fulfilled,
        (state, action: PayloadAction<BookmarkType[]>) => {
          state.loading = false;
          state.error = false;
          state.success = true;
          state.folderBookmarks = action.payload;
        }
      )
      .addCase(getFolderBookmarks.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(createFolder.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        createFolder.fulfilled,
        (state, action: PayloadAction<FolderType[]>) => {
          toast.dismiss();
          toast.success("Folder created");
          state.loading = false;
          state.error = false;
          state.success = true;
        }
      )
      .addCase(createFolder.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, please try again");
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(updateFolder.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        updateFolder.fulfilled,
        (state, action: PayloadAction<FolderType[]>) => {
          toast.dismiss();
          toast.success("Saved");
          state.loading = false;
          state.error = false;
          state.success = true;
        }
      )
      .addCase(updateFolder.rejected, (state, action) => {
        toast.dismiss();
        toast.error("Ups, something went wrong, please try again");
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(deleteFolder.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(
        deleteFolder.fulfilled,
        (state, action: PayloadAction<FolderType[]>) => {
          toast.dismiss();
          toast.success("Folder deleted");
          state.loading = false;
          state.error = false;
          state.success = true;
        }
      )
      .addCase(deleteFolder.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      });
  },
});

export default foldersSlice.reducer;
