import { useNavigate } from "react-router-dom";
import { Button } from "../../../Button/Button";
import { DefaultModalSection } from "../../../SectionsModal/DefaultModalSection/DefaultModalSection";
import "./DashboardLegalSection.scss";

type DashboardLegalSectionPropsType = {};

export const DashboardLegalSection = ({}: DashboardLegalSectionPropsType) => {
  const navigate = useNavigate();
  return (
    <div>
      <DefaultModalSection
        sectionIcon={"legal"}
        title={"Legal"}
        subTitle={"Policies and legal documents"}
      />
      <div className="dashboard-legal-section-buttons">
        <Button
          label={"Cookies Policy"}
          icon="legal"
          secondary
          onClick={() => window.open("/legal/cookies")}
        />
        <Button
          label={"Privacy Policy"}
          icon="legal"
          secondary
          onClick={() => window.open("/legal/privacy")}
        />
        <Button
          label={"Terms of service"}
          icon="legal"
          secondary
          onClick={() => window.open("/legal/termsofservice")}
        />
      </div>
    </div>
  );
};
