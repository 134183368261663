import { useState, FC, useEffect } from "react";

import "./PresentationScreen.scss";
import { PresentationNavbar } from "./PresentationNavbar/PresentationNavbar";
import { PresentationHeroSection } from "./PresentationHeroSection/PresentationHeroSection";
import { PresentationDemoSection } from "./PresentationDemoSection/PresentationDemoSection";
import { PresentationWall } from "./PresentationWall/PresentationWall";

export const PresentationScreen: FC = () => {
  return (
    <div className="presentation-screen-container-wrapper">
      <div className="presentation-screen-info-container-wrapper">
        <div className="presentation-screen-info-container">
          <PresentationNavbar />
          <PresentationHeroSection />
          <PresentationDemoSection />
          <div className="presentation-screen-quote-container">
            <h3>Why ?</h3>
            <p>
              Each app promises to streamline our lives, yet only adds to the
              chaos. Our resources now lie scattered across numerous platforms.
            </p>
            <p>
              SleekTab is a canvas waiting for your personal touch, a platform
              that invites you to{" "}
              <strong>
                build a homepage that mirrors your needs and desires
              </strong>
              , redefining our online experience with a single, powerful, and
              customizable homepage.
            </p>
            <p>
              An <strong>app market</strong> to tailor our homepage to our
              unique cases.
              <br />A <strong>super timeline</strong> featuring the sources and
              content we choose.
              <br />A <strong>smart bookmarks</strong> system to save, share,
              and organize our resources in a simple way, or with AI assistance.
            </p>
            <p>
              <strong>Endless customization</strong> options, interactive
              wallpaper packs, animated backgrounds, and a plethora of
              personalization features,
              <strong>to make the homepage truly our own</strong>.
            </p>
            <p>
              In choosing SleekTab, we're not just selecting an app; we're
              creating <strong>a digital space that reflects who we are</strong>
              , equipped with the power, flexibility, and freedom to make it
              truly our own.
            </p>
          </div>
          <PresentationWall />
          <p>
            We are now in closed Alpha 0.9 and sending invitations. Anything is
            subject to change. For contact please request an invitation by
            joining the beta.
          </p>
          <p className="font-size-xs" ><strong>Copyright © 2024 MillerioTech. All rights reserved.</strong></p>
        </div>
      </div>
    </div>
  );
};
