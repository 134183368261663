import { getSvgByName } from "../../../assets/getIcon";
import "./PresentationCard.scss";

type PresentationCardPropsType = {
  title: string;
  icon: string;
  description: string;
  selected: boolean;
  onClick: () => void;
};

export const PresentationCard = ({
  icon,
  title,
  selected,
  description,
  onClick,
}: PresentationCardPropsType) => {
  return (
    <button
      onClick={onClick}
      className={`presentation-card-container ${
        selected ? "presentation-card-container__selected" : ""
      } border-radius-s`}
    >
      <div className="presentation-card-container-header font-weight-semi-bold font-size-l ">
        {getSvgByName(icon)} {title}
      </div>
      <div className="presentation-card-container-description font-size-s font-weight-semi-bold ">
        {description}
      </div>
      <div className="presentation-card-container-play font-weight-semi-bold font-size-xs ">
      Preview{getSvgByName("play")}
      </div>
    </button>
  );
};
