import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";
import { updatePreferencesInLocalStorage } from "../../../utils/helpers";
import { FavoriteType } from "../../../features/bookmarks/favorites/favoritesSlice";

export const sortFavoritesByLocalStorageOrder = (
  favorites: FavoriteType[],
  order: string[]
) => {
  const orderedFavorites: FavoriteType[] = [];
  order.forEach((id) => {
    const foundFavorite = favorites.find((fav) => fav._id === id);
    if (foundFavorite) {
      orderedFavorites.push(foundFavorite);
    }
  });
  // Add any new favorites that are not yet ordered
  favorites.forEach((fav) => {
    if (!order.includes(fav._id)) {
      orderedFavorites.push(fav);
    }
  });
  return orderedFavorites;
};

export const updateFavoritesbarItemsOrder = async (order: any) => {
  const body = {
    newOrder: order,
  };
  await axios
    .post(`${APIURL}/user/favoritesbar_items_order`, body)
    .then((response) => {
      updatePreferencesInLocalStorage(response.data.preferences);
    })
    .catch((error) => {
      console.log(error);
    });
};
