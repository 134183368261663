import { IconButton } from "../IconButton/IconButtont";
import "./ModalHeader.scss";
import { getSvgByName } from "../../assets/getIcon";

type ModalHeaderPropsType = {
  title: string;
  icon: string;
  hideClose?: boolean;
  onModalClose: () => void;
};

export const ModalHeader = ({
  title,
  icon,
  hideClose,
  onModalClose,
}: ModalHeaderPropsType) => {
  return (
    <div className="modal-header-container-wrapper">
      <div className="modal-header-container">
        <div className="modal-header-container-title">
          {getSvgByName(icon)}
          <h1 className="font-size-m">{title}</h1>
        </div>
        {!hideClose && <IconButton onClick={onModalClose} icon="close" />}
      </div>
    </div>
  );
};
