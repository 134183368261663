import { IconButton } from "../../../IconButton/IconButtont";
import "./BookmarksSidebarItem.scss";

type BookmarksSideBarItemPropsType = {
  label: string;
  icon: any;
  selected: boolean;
  onClick: () => void;
  editFolder?: () => void;
};

export const BookmarksSidebarItem = ({
  label,
  icon,
  selected,
  onClick,
  editFolder,
}: BookmarksSideBarItemPropsType) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={`bookmarks-sidebar-item-wrapper icon-button-item-hoverered ${
        selected ? "bookmarks-sidebar-item-wrapper-selected" : ""
      } `}
      onClick={() => (selected ? null : onClick())}
    >
      <div className="bookmarks-sidebar-item-container">
        {icon}
        <p >{label}</p>
        {editFolder && (
          <IconButton itemHover icon="edit" onClick={editFolder} />
        )}
      </div>
    </div>
  );
};
