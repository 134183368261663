import { useState } from "react";
import "./Assistant.scss";
import { IconButton } from "../../IconButton/IconButtont";
import AssitantLogo from "../../../assets/appsIcons/assistant.png";
import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";
import { FloatingButton } from "../../FloatingButton/FloatingButton";
import { AppWrapper } from "../AppWrapper/AppWrapper";

type AssistantPropsType = {};

type AssistantMessageType = {
  message: string;
  role: "user" | "assistant";
  id: string;
};

export const Assistant = ({}: AssistantPropsType) => {
  const [userMessage, setUserMessage] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [converstaionMessages, setConversationMessages] = useState<
    AssistantMessageType[]
  >([]);

  const maxLimit = 100;

  const handleChange = (event: any) => {
    setUserMessage(event.target.value);
  };

  const addMessageToState = (message: AssistantMessageType) => {
    setConversationMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, message];
      if (updatedMessages.length > 14) {
        updatedMessages.shift();
      }
      return updatedMessages;
    });
  };

  const handleSendMessage = async () => {
    if (userMessage.length > 1 && userMessage.length < maxLimit) {
      setTimeout(() => {
        setIsGenerating(true);
      }, 1000);
      const userMessageData: AssistantMessageType = {
        message: userMessage,
        role: "user",
        id: `user-message-${converstaionMessages}`,
      };
      setUserMessage("");
      addMessageToState(userMessageData);
      try {
        const response = await axios.post(
          `${APIURL}/assistant`,
          { message: userMessage },
          { withCredentials: true }
        );
        addMessageToState({
          message: response.data[0].content[0].text.value,
          role: "assistant",
          id: response.data[0].id,
        });
        setIsGenerating(false);
      } catch (error: any) {
        setIsGenerating(false);
        if (error.response.status === 429) {
          console.log(error.response.data);
        }
        addMessageToState({
          message: error.response.data.message,
          role: "assistant",
          id: error.response.data.id,
        });
      }
    }
  };

  const handleKeyPress = (event: any) => {
    if (userMessage.length > 1 && !isGenerating) {
      if (event.key === "Enter") {
        handleSendMessage();
      }
    }
  };

  return (
    <AppWrapper title={"Assistant"} icon={"assistant"}>
      <div className="assistant-container-wrapper floating-button-icon-wrapper">
        <div className="assistant-container">
          {converstaionMessages.length > 0 && (
            <FloatingButton
              icon={"delete"}
              remove
              onClick={() => setConversationMessages([])}
            />
          )}

          <div className="assistant-messages-container">
            {converstaionMessages.map((message, i) => {
              return (
                <div
                  key={`${message.id}-${i}`}
                  className={`assistant-messages-message border-radius-l font-weight-semi-bold main-app-background font-size-s assistant-messages-message__${message.role} `}
                >
                  <span>{message.message}</span>
                </div>
              );
            })}
            {isGenerating && (
              <div
                className={`assistant-messages-message border-radius-l font-weight-semi-bold main-app-background font-size-s assistant-messages-message__assistant `}
              >
                <img src={AssitantLogo} />
                <span className="typing-indicator">
                  Typing <span className="dot">.</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                </span>
              </div>
            )}
          </div>
          <div className="assistant-input-field-wrapper ">
            <input
              onKeyDown={handleKeyPress}
              autoFocus
              placeholder="Message"
              className="input-field-style "
              maxLength={maxLimit}
              type="text"
              value={userMessage}
              onChange={handleChange}
            />
            <IconButton
              disabled={userMessage.length < 2 || isGenerating}
              icon={"send"}
              onClick={() => handleSendMessage()}
            />
          </div>
        </div>
      </div>
    </AppWrapper>
  );
};
