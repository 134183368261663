import axios from "axios";
import { LucideIcon, Save } from "lucide-react";
import { APIURL } from "../../utils/apiUtils";

export type FixedButtonConfig = {
  label: string;
  id: string;
  icon: LucideIcon;
};

export const fixedButtons: FixedButtonConfig[] = [
  {
    label: "Everything",
    id: "everything",
    icon: Save,
  },
];

export const searchBookmarks = async (query: string) => {
  try {
    const response = await axios.get(`${APIURL}/bookmarks/search?q=${query}`);
    return response.data;
  } catch (error: any) {
    console.log("Could not search bookmarks, ", error);
    return [];
  }
};