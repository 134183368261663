import { ReactNode } from "react";
import { getSvgByName } from "../../../assets/getIcon";
import { UserForm } from "../../UserForm/UserForm";
import "./DefaultModalSection.scss";

type DefaultModalSectionPropsType = {
  sectionIcon: string;
  title: string;
  subTitle: string | ReactNode;
  description?: string;
  formHeader?: string;
  comingSoonMessage?: boolean;
  disabledSubmit?: boolean;
  sectionHeader?: boolean;
  children?: any;
  iconConfig?: any;
  handleFormSubmit?: () => void;
};

export const DefaultModalSection = ({
  sectionIcon,
  title,
  subTitle,
  description,
  formHeader,
  disabledSubmit,
  comingSoonMessage,
  children,
  sectionHeader,
  iconConfig,
  handleFormSubmit,
}: DefaultModalSectionPropsType) => {
  return (
    <div
      className={`default-modal-section-container ${
        sectionHeader ? "default-modal-section-container__section-header" : ""
      } `}
    >
      <div className="default-modal-section-header">
        {getSvgByName(sectionIcon, iconConfig)}
      </div>
      <div className="default-modal-section-content">
        <h2>{title}</h2>
        {typeof subTitle === "string" ? <p>{subTitle}</p> : subTitle}
        {description && (
          <span className="default-modal-section-content-description font-size-xs italic font-weight-semi-bold ">
            {description}
          </span>
        )}
        {comingSoonMessage && (
          <p className="italic font-weight-bold default-modal-section-coming-soon ">
            Coming soon
          </p>
        )}
        {formHeader && handleFormSubmit && (
          <UserForm
            header={formHeader}
            submit={handleFormSubmit}
            disabledSubmit={disabledSubmit}
          >
            {children}
          </UserForm>
        )}
      </div>
    </div>
  );
};
