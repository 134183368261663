import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Modal from "react-modal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Folder } from "lucide-react";

import { BookmarksSidebarItem } from "./BookmarksSidebarItem/BookmarksSidebarItem";

import { ModalHeader } from "../../ModalHeader/ModalHeader";
import { BookmarkFolderModal } from "../BookmarkFolderModal/BookmarkFolderModal";
import { BookmarksCategoryItem } from "./BookmarksCategoryItem/BookmarksCategoryItem";

import { BookmarkCategoryModal } from "../BookmarkCategoryModal/BookmarkCategoryModal";

import {
  getPreferencesFromLocalStorage,
  handleOpenContextMenu,
  modalCustomStyles,
} from "../../../utils/helpers";

import { fixedButtons } from "../utils";

import { sortItemsByOrder, updateUserSidebarOrder } from "./sidebarUtils";

import { getSvgByName } from "../../../assets/getIcon";

import { BookmarkSidebarHeader } from "./BookmarkSidebarHeader/BookmarkSidebarHeader";

import { RootState } from "../../../store";

import "./BookmarksSidebar.scss";

type BookmarksSideBarPropsType = {
  selectedSection?: string;
  setSelectedSection: (item: string) => void;
};

export const BookmarksSidebar = ({
  selectedSection,
  setSelectedSection,
}: BookmarksSideBarPropsType) => {
  const [isOpenFolderModal, setIsOpenFolderModal] = useState(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
  const [clickedFolderId, setClickedFolderId] = useState<string | undefined>();
  const [clickedFolderName, setIsClickedFolderName] = useState("");
  const [clickedCategoryName, setClickedCategoryName] = useState("");
  const [sidebarListItems, setSidebarListItems] = useState([]);
  const [clickedCategoryId, setClickedCategoryId] = useState<
    string | undefined
  >();
  const [clickedFolderCategoryId, setClickedFolderCategoryId] = useState<
  string | undefined
>();

  const selectFolderAndCategoryState = (state: RootState) => ({
    folders: state.folders.folders,
    categories: state.categories.categories,
  });

  const { folders, categories } = useSelector(
    selectFolderAndCategoryState,
    shallowEqual
  );

  useEffect(() => {
    if (!isOpenCategoryModal) {
      setClickedCategoryId(undefined);
      setClickedCategoryName("");
    }
  }, [isOpenCategoryModal]);

  useEffect(() => {
    if (!isOpenFolderModal) {
      setClickedFolderId(undefined);
      setClickedFolderCategoryId(undefined);
      setIsClickedFolderName("");
    }
  }, [isOpenFolderModal]);

  useEffect(() => {
    const localStorageOrder =
      getPreferencesFromLocalStorage()?.sidebarItemsOrder || [];
    let combinedItems: any = [
      ...categories.map((category) => ({ ...category, itemType: "category" })),
      ...folders
        .filter((folder) => !folder.categoryId)
        .map((folder) => ({ ...folder, itemType: "folder" })),
    ];

    if (localStorageOrder.length) {
      const orderedItems = sortItemsByOrder(combinedItems, localStorageOrder);
      const orderedIds = new Set(orderedItems.map((item: any) => item._id));
      const unorderedItems = combinedItems.filter(
        (item: any) => !orderedIds.has(item._id)
      );
      combinedItems = [...orderedItems, ...unorderedItems];
    }

    setSidebarListItems(combinedItems);
  }, [categories, folders]);

  const handleAddFolderToCategory = (categoryId: string, name: string) => {
    setIsOpenFolderModal(true);
    setClickedFolderCategoryId(categoryId);
  };

  const handleEditCategory = (categoryId: string, name: string) => {
    setIsOpenCategoryModal(true);
    setClickedCategoryId(categoryId);
    setClickedCategoryName(name);
  };

  const handleEditFolder = (
    folderId: string,
    name: string,
    categoryId?: string
  ) => {
    setIsOpenFolderModal(true);
    setClickedFolderId(folderId);
    setIsClickedFolderName(name);
    setClickedFolderCategoryId(categoryId);
  };

  const ondEndDrag = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const items = Array.from(sidebarListItems);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    const orderOfIds = items.map((item: any) => item._id);
    setSidebarListItems(items);
    updateUserSidebarOrder(orderOfIds);
  };

  useEffect(() => {
    const handleContextMenuAction = (event: any) => {
      const { detail } = event;
      const component = detail.actionType.split(":")[0];
      const action = detail.actionType.split(":")[1];

      if (component === "sidebar") {
        switch (action) {
          case "open-category-modal":
            setIsOpenCategoryModal(true);
            break;
          case "open-folder-modal":
            setIsOpenFolderModal(true);
            break;
        }
      }
    };

    window.addEventListener("contextMenuAction", handleContextMenuAction);

    return () => {
      window.removeEventListener("contextMenuAction", handleContextMenuAction);
    };
  }, []);

  return (
    <div className="bookmarks-sidebar-wrapper">
      <div
        className="bookmarks-sidebar-container"
        onContextMenu={(e) => handleOpenContextMenu(e, "sidebar")}
      >
        <div className="bookmarks-sidebar-content">
          <div>
            <BookmarkSidebarHeader
              setSearchedSection={(searchTerm) =>
                setSelectedSection(`search:${searchTerm}`)
              }
            />
            {fixedButtons.map((fixedButton, i) => {
              return (
                <BookmarksSidebarItem
                  key={`${i}-${fixedButton.id}`}
                  onClick={() => setSelectedSection(fixedButton.id)}
                  selected={selectedSection === fixedButton.id}
                  label={fixedButton.label}
                  icon={<fixedButton.icon />}
                />
              );
            })}
          </div>
          <DragDropContext onDragEnd={ondEndDrag}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="bookmarks-sidebar-items-dynamic"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sidebarListItems &&
                    sidebarListItems.map((listItem: any, index: any) => (
                      <Draggable
                        key={listItem._id}
                        draggableId={listItem._id}
                        index={index}
                      >
                        {(provided, snapshot) =>
                          listItem.itemType === "category" ? (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <BookmarksCategoryItem
                                provided={provided}
                                key={`${index}-${listItem._id}`}
                                id={listItem._id}
                                name={listItem.name}
                                selectedSidebarSection={selectedSection}
                                editCategory={() =>
                                  handleEditCategory(
                                    listItem._id,
                                    listItem.name
                                  )
                                }
                                addFolder={() =>
                                  handleAddFolderToCategory(
                                    listItem._id,
                                    listItem.name
                                  )
                                }
                                categoryFolders={listItem.folders}
                                selectedFolder={(folderId: string) =>
                                  setSelectedSection(`folder-${folderId}`)
                                }
                                editFolder={(folderId, folderName) => {
                                  handleEditFolder(
                                    folderId,
                                    folderName,
                                    listItem._id
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <BookmarksSidebarItem
                                key={`folder-${listItem._id}${index}`}
                                editFolder={() =>
                                  handleEditFolder(listItem._id, listItem.name)
                                }
                                onClick={() =>
                                  setSelectedSection(`folder-${listItem._id}`)
                                }
                                selected={
                                  selectedSection === `folder-${listItem._id}`
                                }
                                label={listItem.name}
                                icon={<Folder />}
                              />
                            </div>
                          )
                        }
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className={`bookmarks-sidebar-new-buttons-container  `}>
            <button onClick={(e) => handleOpenContextMenu(e, "sidebar")}>
              {getSvgByName("add-folder")}
              <span>Add new</span>
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpenFolderModal}
        onRequestClose={() => setIsOpenFolderModal(false)}
        style={modalCustomStyles}
        contentLabel="Example Modal"
        closeTimeoutMS={100}
      >
        <ModalHeader
          icon={"folder"}
          title={"Folder"}
          onModalClose={() => setIsOpenFolderModal(false)}
        />
        <BookmarkFolderModal
          categoryId={clickedFolderCategoryId}
          onModalClose={() => setIsOpenFolderModal(false)}
          id={clickedFolderId}
          editLogic={clickedFolderId ? true : false}
          currentName={clickedFolderName}
        />
      </Modal>
      <Modal
        isOpen={isOpenCategoryModal}
        onRequestClose={() => setIsOpenCategoryModal(false)}
        style={modalCustomStyles}
        contentLabel="Example Modal"
        closeTimeoutMS={100}
      >
        <ModalHeader
          icon={"category"}
          title={"Category"}
          onModalClose={() => setIsOpenCategoryModal(false)}
        />
        <BookmarkCategoryModal
          editLogic={clickedCategoryId ? true : false}
          id={clickedCategoryId}
          currentName={clickedCategoryName}
          onModalClose={(fromDelete) => {
            setIsOpenCategoryModal(false);
            if (fromDelete) setSelectedSection("everything");
          }}
        />
      </Modal>
    </div>
  );
};
