import { useEffect, useRef } from "react";
import { getSvgByName } from "../../assets/getIcon";
import "./IconButton.scss";

type IconButtonPropsType = {
  icon: string;
  itemHover?: boolean;
  title?: string;
  disabled?: boolean;
  config?: any;
  onKeyDown?: any;
  remove?: boolean;
  onClick: () => void;
};

export const IconButton = ({
  icon,
  itemHover,
  disabled,
  title,
  onKeyDown,
  remove,
  config,
  onClick,
}: IconButtonPropsType) => {
  const selectedIcon = getSvgByName(icon, { ...config });
  
  return (
    <button
      title={title}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      disabled={disabled}
      onKeyDown={onKeyDown}
      className={`icon-button-container ${
        remove ? "icon-button-container-delete" : ""
      } ${itemHover ? "icon-button-container-item-hover" : ""} `}
    >
      {selectedIcon}
    </button>
  );
};
