import { useState } from "react";
import { InputField } from "../../InputField/InputField";
import "./BookmarkCategoryModal.scss";
import { Button } from "../../Button/Button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import {
  createCategory,
  deleteCategory,
  updateCategory,
} from "../../../features/bookmarks/categories/categoriesActions";
import { IconButton } from "../../IconButton/IconButtont";

type BookmarkCategoryModalPropsType = {
  editLogic?: boolean;
  currentName?: string;
  id?: string;
  onModalClose: (fromDelete?: boolean) => void;
};

export const BookmarkCategoryModal = ({
  editLogic,
  currentName,
  id,
  onModalClose,
}: BookmarkCategoryModalPropsType) => {
  const [nameInputValue, setNameInputValue] = useState(currentName || "");

  const dispatch: AppDispatch = useDispatch();

  const handleCreateCategory = () => {
    if (nameInputValue.length > 0) {
      dispatch(createCategory({ categoryName: nameInputValue }));
      onModalClose();
    }
  };

  const handleEditCategory = () => {
    if (currentName !== nameInputValue) {
      if (id) {
        dispatch(
          updateCategory({ categoryName: nameInputValue, categoryId: id })
        );

        onModalClose();
      } else {
        console.log("Category ID missing for update");
      }
    }
  };

  const handleDeleteCategory = () => {
    if (id) {
      dispatch(deleteCategory({ categoryId: id }));
      onModalClose(true);
    } else {
      console.log("Missing id to delete");
    }
  };

  return (
    <div
      className={`category-modal-container-wrapper ${
        !editLogic ? "category-modal-container-wrapper-single-button" : ""
      }`}
    >
      <div className="category-modal-container">
        <InputField
          label="Category name"
          input={nameInputValue}
          autoFocus
          placeholder={"Add name"}
          onChange={(input: string) => setNameInputValue(input)}
        />
        <div className="category-modal-buttons-container">
          {editLogic && (
            <IconButton remove onClick={handleDeleteCategory} icon={"trash"} />
          )}
          <div>
            <Button
              submitButton
              onClick={editLogic ? handleEditCategory : handleCreateCategory}
              label="Save"
              icon="category"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
