import { timeAgo } from "../../../../utils/helpers";
import "./SpeakUpPost.scss";
import { Link } from "react-router-dom";
import { getSvgByName } from "../../../../assets/getIcon";
import { useEffect, useState } from "react";
import { likePost } from "../utils";

export type SpeakUpPostType = {
  username: string;
  pubDate: Date;
  text: string;
  userId: string;
  profilePic?: string;
  votes: number;
  votedBy: [{ userId: string; voteType: "up" | "down" }];
  _id: string;
};

export type SpeakUpPostVoteType = "up" | "down" | undefined;

type SpeakUpPostPropsType = {
  post: SpeakUpPostType;
  roomId: string;
  myPost: boolean;
  isVoted: SpeakUpPostVoteType;
};

export const SpeakUpPost = ({
  post,
  roomId,
  myPost,
  isVoted,
}: SpeakUpPostPropsType) => {
  const [votesCount, setVotesCount] = useState(post.votes);
  const [votedPost, setVotedPost] = useState<SpeakUpPostVoteType>(isVoted);

  const handleLikePost = (voteType: "up" | "down") => {
    likePost(roomId, post._id, voteType);
    let newVotesCount;
    if (voteType === "up") {
      newVotesCount = votesCount + 1;
      setVotedPost("up");
    } else {
      newVotesCount = votesCount - 1;
      setVotedPost("down");
    }
    setVotesCount(newVotesCount);
  };

  return (
    <div className="speakup-room-post">
      <div
        className={`speakup-room-post-votes-container ${
          myPost ? "speakup-room-post-votes-container__my-post" : ""
        } ${votedPost ? "speakup-room-post-votes-container__voted" : ""} `}
      >
        <button
          className={` speakup-room-post-vote-button ${
            votedPost === "up" ? "speakup-room-post-vote-button__voted" : ""
          }`}
          disabled={myPost}
          onClick={() => handleLikePost("up")}
        >
          {getSvgByName("double-chevron")}
        </button>
        <span className="font-weight-bold">{votesCount}</span>
        <button
          className={` speakup-room-post-vote-button ${
            votedPost === "down" ? "speakup-room-post-vote-button__voted" : ""
          }`}
          disabled={myPost}
          onClick={() => handleLikePost("down")}
        >
          {getSvgByName("double-chevron")}
        </button>
      </div>
      <div className="speakup-room-content-container">
        <div className="speakup-room-post-header">
          <Link
            className="font-weight-semi-bold "
            to={`/profile/${post.username}`}
          >
            {post.username}
          </Link>
          <span className="font-size-s date">
            <span> • </span>
            {timeAgo(post.pubDate.toString())}
          </span>
        </div>
        <div className="speakup-room-post-text">{post.text}</div>
        {/* <div className="speakup-room-post-footer">
          <div className="speakup-room-post-footer-vote-container">
            <IconButton icon={"settings"} onClick={() => console.log("s")} />
          </div>
        </div> */}
      </div>
    </div>
  );
};
