import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";

export const loadSpotifySDK = () => {
  const src = "https://sdk.scdn.co/spotify-player.js";
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    document.head.appendChild(script);
  });
};

export const handleSpotifyLogin = async () => {
  window.location.href = `${APIURL}/spotify/auth`;
};

export const getSpotifyDeviceId = () => {
  const deviceId = window.localStorage.getItem("spotify_device_id");
  return deviceId;
};

export const loadPlaylist = async (playlistUri) => {
  const deviceId = getSpotifyDeviceId();
  const token = await getValidSpotifyToken();
  console.log("gg", playlistUri);
  try {
    await axios.post(`${APIURL}/spotify/loadPlaylist`, {
      token,
      playlistUri,
      deviceId,
    });
  } catch (error) {
    console.error("Error in starting playlist playback:", error);
  }
};

export const fetchUserPlaylists = async () => {
  const token = await getValidSpotifyToken();
  try {
    const response = await axios.post(`${APIURL}/spotify/fetchUserPlaylists`, {
      token,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching playlists:", error);
    return [];
  }
};

export const refreshSpotifyToken = async () => {
  const refreshToken = localStorage.getItem("spotify_refresh_token");
  try {
    const response = await axios.post(`${APIURL}/spotify/refresh_token`, {
      refreshToken,
    });

    const expirationTime =
      new Date().getTime() + parseInt(response.data.expires_in) * 1000;
    console.log("refreshing ...");
    localStorage.setItem("spotify_token", response.data.access_token);
    localStorage.setItem("spotify_expires_at", expirationTime.toString());
    return response.data.access_token;
  } catch (error) {
    localStorage.removeItem("spotify_refresh_token");
    localStorage.removeItem("spotify_token");
    console.error("Error refreshing token:", error.response.data);
    throw error;
  }
};

export const getValidSpotifyToken = async () => {
  const expiresAt = localStorage.getItem("spotify_expires_at");
  const currentTime = new Date().getTime();

  const tenMinutes = 10 * 60 * 1000;

  if (!expiresAt || currentTime > Number(expiresAt) - tenMinutes) {
    return await refreshSpotifyToken();
  } else {
    return localStorage.getItem("spotify_token");
  }
};
