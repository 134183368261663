import { useState } from "react";
import "./BookmarkSidebarHeader.scss";
import { getSvgByName } from "../../../../assets/getIcon";
import { IconButton } from "../../../IconButton/IconButtont";
import { InputField } from "../../../InputField/InputField";
import { useClickOutside } from "../../../../hooks/useClickOutside";

type BookmarkSidebarHeaderPropsType = {
  setSearchedSection: (searchTerm: string) => void;
};

export const BookmarkSidebarHeader = ({
  setSearchedSection,
}: BookmarkSidebarHeaderPropsType) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const wrapperRef = useClickOutside(() => {
    setIsSearchOpen(false);
  });

  const searchHandler = () => {
    if (searchInput.length > 2) {
      setSearchedSection(searchInput);
      setSearchInput("");
      setIsSearchOpen(false);
    }
  };

  return (
    <div ref={wrapperRef} className="bookmark-sidebar-header">
      {isSearchOpen ? (
        <div className="bookmark-sidebar-search-container">
          <input
            autoFocus
            onKeyDown={(e) => (e.key === "Enter" ? searchHandler() : null)}
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            className="bookmark-sidebar-search-input"
            placeholder="Search"
          />
          <IconButton
            disabled={searchInput.length < 3}
            icon="send"
            onClick={() => searchHandler()}
          />
        </div>
      ) : (
        <div className="bookmark-sidebar-header-initial">
          {getSvgByName("bookmarks-app")}
          <p className="font-weight-semi-bold">Bookmarks</p>
          <IconButton icon="search" onClick={() => setIsSearchOpen(true)} />
        </div>
      )}
    </div>
  );
};
