// store.ts
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import bookmarksReducer from "./features/bookmarks/bookmarks/bookmarksSlice";
import favoritesReducer from "./features/bookmarks/favorites/favoritesSlice";
import foldersReducer from "./features/bookmarks/folders/foldersSlice";
import categoriesReducer from "./features/bookmarks/categories/categoriesSlice";
import spotifyReducer from "./features/spotify/spotifySlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    bookmarks: bookmarksReducer,
    favorites: favoritesReducer,
    folders: foldersReducer,
    categories: categoriesReducer,
    spotify: spotifyReducer,
  },
  devTools: false,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
