import axios from "axios";
import store from "../store";
import { logOut } from "../features/auth/authActions";
import { sendTokenToExtension } from "./chromeExtension";

export let APIURL;

if (process.env.NODE_ENV === "production") {
  APIURL = process.env.REACT_APP_API_ENDPOINT_PRODUCTION;
} else {
  APIURL = process.env.REACT_APP_API_ENDPOINT_LOCAL;
}

// Helper function to perform token refresh
export async function refreshToken(t) {
  try {
    const response = await axios.post(
      `${APIURL}/user/refresh_token`,
      {},
      { withCredentials: true }
    );
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.accessToken}`;
    sendTokenToExtension(response.data.extensionToken);
    return response.data.accessToken;
  } catch (error) {
    return null;
  }
}

// Axios Request Interceptor
axios.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    return config;
  },
  (error) => {
    console.error("Request error: ", error);
    return Promise.reject(error);
  }
);

// Axios Response Interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequestConfig = error.config;
    const appAuthExpired =
      originalRequestConfig.url === `${APIURL}/user/refresh_token`;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequestConfig._retry
    ) {
      if (appAuthExpired) {
        window.location.href = "/home";
        store.dispatch(logOut());
      } else {
        originalRequestConfig._retry = true;
        const newToken = await refreshToken("Interceptor");
        if (newToken) {
          originalRequestConfig.headers["Authorization"] = `Bearer ${newToken}`;
          return axios(originalRequestConfig);
        } else {
          console.error("Failed to obtain new token for retry");
        }
      }
    }
    return Promise.reject(error);
  }
);
