import "./Spinner.scss";

export const Spinner = ({ size }: { size?: number }) => {
  return (
    <span
      className="spinner "
      style={
        {
          "--spinner-size": size ? `${size}px` : "24px",
        } as any
      }
    ></span>
  );
};
