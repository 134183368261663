import ThumbnailJapanese from "../assets/thumbnails/japanese-town.png";
import ThumbnailJdm from "../assets/thumbnails/jdm.png";
import ThumbnailCyberPunk from "../assets/thumbnails/cyberpunk.png";
import axios from "axios";
import { APIURL } from "./apiUtils";

export const modalCustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    border: "1px solid rgba(255, 255, 255, .2)",
    boxShadow: "rgba(0, 0, 0, .8) 0px 6px 80px 0px",
    color: "white",
    overflow: "hidden",
    borderRadius: "6px",
    padding: "16px",
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0, 0.5)",
    backdropFilter: "blur(30px)",
  },
};

export const modalCustomStylesSections = {
  ...modalCustomStyles,
  content: { ...modalCustomStyles.content, height: "85%" },
};

export const defaultToastStyles = {
  duration: 2000,
  style: {
    background: "white",
    color: "black",
  },
  success: {
    style: {
      color: "green",
    },
  },
  error: {
    style: {
      color: "red",
    },
  },
};

export const formatUrl = (url: string) => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else if (url.startsWith("www.")) {
    return "http://" + url;
  } else {
    return "http://www." + url;
  }
};

export type UserPreferencesType = {
  wallpaperCollection: string;
  cryptoWidget: string[];
  sidebarItemsOrder: string[];
  newsSources: string[];
  collapsedBookmarks: boolean;
  favoritesBarItemsOrder: string[];
};

export const setOnboardingCompletedLocalStorage = () => {
  localStorage.setItem("onboarding_completed", JSON.stringify(true));
};

export const getOnboardingCompletedLocalStorage = () => {
  const onboardingCompleted = localStorage.getItem("onboarding_completed");
  console.log("he", typeof onboardingCompleted);
  if (onboardingCompleted && onboardingCompleted === "true") {
    return true;
  } else {
    return false;
  }
};

export const updatePreferencesInLocalStorage = (
  preferences: UserPreferencesType
) => {
  localStorage.setItem("preferences", JSON.stringify(preferences));
};

export const getPreferencesFromLocalStorage = () => {
  const preferencesString = localStorage.getItem("preferences");
  if (preferencesString) {
    const preferences = JSON.parse(preferencesString);
    return preferences;
  } else {
    return null;
  }
};

export type WidgetStateType = string[];

export const updateWidgetsStateInLocalStorage = (
  widgetState: WidgetStateType
) => {
  localStorage.setItem("widgetState", JSON.stringify(widgetState));
};

export const getWidgetsStateFromLocalStorage = () => {
  const widgetStateString = localStorage.getItem("widgetState");
  if (widgetStateString) {
    const widgetState = JSON.parse(widgetStateString);
    return widgetState;
  } else {
    return null;
  }
};

export type SectionsState = string[];

export const updateSectionsStateInLocalStorage = (
  sectionsState: SectionsState
) => {
  localStorage.setItem("sectionsState", JSON.stringify(sectionsState));
};

export const getSectionsStateFromLocalStorage = () => {
  const sectionsStateString = localStorage.getItem("sectionsState");
  if (sectionsStateString) {
    const sectionsState = JSON.parse(sectionsStateString);
    return sectionsState;
  } else {
    return null;
  }
};

export const extractDomain = (url: string) => {
  try {
    // Add a protocol if missing to ensure the URL constructor works correctly
    if (!url.match(/^https?:\/\//)) {
      url = "http://" + url;
    }

    let hostname = new URL(url).hostname;

    let parts = hostname.split(".");

    if (parts[0] === "www") {
      parts.shift();
    }

    return parts.join(".");
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
};

export const getVideoThumbnail = (name: string) => {
  switch (name) {
    case "jdm":
      return ThumbnailJdm;
    case "cyberpunk":
      return ThumbnailCyberPunk;
    case "japanese-town":
      return ThumbnailJapanese;
    default:
      break;
  }
};

export const handleOpenContextMenu = (e: any, component: string) => {
  const event = new CustomEvent("openContextMenu", {
    detail: {
      component: component,
      position: { x: e.pageX, y: e.pageY },
    },
  });

  window.dispatchEvent(event);
};

export const fetchWallpaperUrl = async () => {
  let selectedWallpaper = "default";
  const localPreferences = localStorage.getItem("preferences");
  if (localPreferences) {
    const preferences: UserPreferencesType = JSON.parse(localPreferences);
    if (preferences.wallpaperCollection) {
      selectedWallpaper = preferences.wallpaperCollection;
    }
  }

  const wallpaperData = await axios
    .get(`${APIURL}/wallpapers/getwallpaper/${selectedWallpaper}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching wallpaper:", error);
      return;
    });
  return wallpaperData;
};

export const timeAgo = (dateString: string) => {
  const postedDate = new Date(dateString);
  const currentDate = new Date();
  const differenceInMilliseconds = currentDate.getTime() - postedDate.getTime();

  const minutesAgo = Math.floor(differenceInMilliseconds / (1000 * 60));
  if (minutesAgo < 60) {
    return minutesAgo + "m";
  } else {
    const hoursAgo = Math.floor(minutesAgo / 60);
    return hoursAgo + "h";
  }
};

export const getCurrentWeekNumber = () => {
  const currentDate: any = new Date();
  const startDate: any = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor(
    (currentDate - startDate) / (24 * 60 * 60 * 1000) + 1
  );
  const weekNumber = Math.ceil(days / 7);
  return weekNumber;
};
