import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { APIURL } from "../../../utils/apiUtils";
import {
  getPreferencesFromLocalStorage,
  modalCustomStyles,
  updatePreferencesInLocalStorage,
} from "../../../utils/helpers";
import { ModalHeader } from "../../ModalHeader/ModalHeader";

import WeatherIcon from "../../../assets/icons/widgets/weather.png";

import "./Weather.scss";
import { InputField } from "../../InputField/InputField";
import toast from "react-hot-toast";
import { FloatingButton } from "../../FloatingButton/FloatingButton";
import { Spinner } from "../../Spinner/Spinner";

export const Weather = () => {
  const [city, setCity] = useState(
    getPreferencesFromLocalStorage()?.weatherWidget?.city || "madrid"
  );
  const [isOpenWeatherModal, setIsOpenWeatherModal] = useState(false);
  const [weatherData, setWeatherData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [searchCityValue, setSerachCityValue] = useState<string>("");
  const [citySearchResults, setCitySearchResults] = useState<
    [] | { name: string; country: string }[]
  >([]);

  useEffect(() => {
    const getWeather = async () => {
      const response = await axios.get(`${APIURL}/widgetWeather/${city}`);
      setWeatherData(response.data);
    };
    getWeather();
  }, [city]);

  const handleUpdateWeatherWidget = async (item: string) => {
    setIsOpenWeatherModal(false);
    setSerachCityValue("");
    setCitySearchResults([]);
    setCity(item);
    const body = {
      selectedCity: item,
    };
    await axios
      .put(`${APIURL}/widgetWeather/preferences`, body)
      .then((response) => {
        updatePreferencesInLocalStorage(response.data.preferences);
      })
      .catch((error) => {
        console.error("Error updaing weather widget", error);
      });
  };

  const getUnitsIcon = (selectedUnits: string) => {
    const unitIcon = selectedUnits === "metric" ? "°" : "℉";
    return unitIcon;
  };

  const handleSearchCity = async () => {
    setIsLoading(true);
    setCitySearchResults([]);
    if (searchCityValue.length > 2) {
      const body = {
        query: searchCityValue,
      };
      setSerachCityValue("");
      await axios
        .post(`${APIURL}/widgetWeather/find`, body)
        .then((response) => {
          setIsLoading(false);
          setCitySearchResults(response.data);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error finding the city", error);
        });
    } else {
      toast.dismiss();
      toast.error("Type at least 3 characters");
    }
  };

  return (
    <div className="widget-weather-container floating-button-icon-wrapper ">
      <FloatingButton onClick={() => setIsOpenWeatherModal(true)} />
      {weatherData && (
        <div className="widget-weather">
          <div className="widget-weather-temp font-weight-semi-bold font-size-xl ">
            <img src={weatherData.icon} />
            <span>
              {Math.floor(weatherData.temperature)}
              {getUnitsIcon(weatherData.units)}
            </span>
          </div>
          <div className="widget-weather-info font-size-s ">
            {/* <span>{weatherData.weather}</span> */}
            {/* <span className="widget-weather-info__city">
              {weatherData.city}
            </span> */}
          </div>
        </div>
      )}
      <Modal
        isOpen={isOpenWeatherModal}
        onRequestClose={() => setIsOpenWeatherModal(false)}
        style={modalCustomStyles}
        contentLabel="Example Modal"
        closeTimeoutMS={100}
      >
        <ModalHeader
          icon={"widget-weather"}
          title={"Edit Weather"}
          onModalClose={() => setIsOpenWeatherModal(false)}
        />
        <div className="widget-weather-modal-content">
          <InputField
            input={searchCityValue}
            placeholder={"Search a city"}
            autoFocus
            label={""}
            searchButton={handleSearchCity}
            onChange={(input) => setSerachCityValue(input)}
          />
          <div className="widget-weather-modal-result-list">
            {isLoading ? (
              <Spinner />
            ) : citySearchResults && citySearchResults.length > 0 ? (
              citySearchResults.map((city, i) => {
                return (
                  <button
                    key={`city${i}`}
                    className="widget-weather-modal-result-item"
                    onClick={() => handleUpdateWeatherWidget(city.name)}
                  >
                    {city.name}, {city.country}
                  </button>
                );
              })
            ) : (
              <div className="widget-weather-modal-not-found">No results found</div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
