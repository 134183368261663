import { useSelector } from "react-redux";
import { DefaultModalSection } from "../../../SectionsModal/DefaultModalSection/DefaultModalSection";
import "./DashboardSectionDevelopers.scss";
import { RootState } from "../../../../store";
import { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { APIURL } from "../../../../utils/apiUtils";
import { TextArea } from "../../../TextArea/TextArea";
import { InputField } from "../../../InputField/InputField";

export const DashboardSectionDevelopers = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const [developerMessage, setDeveloperMessage] = useState("");
  const [contactEmail, setContactEmail] = useState(userInfo?.email || "");

  const handleSubmit = async () => {
    const body = {
      description: `...:::THIS IS A DEVELOPER REQUEST, NOT APP:::... Developer message: ${developerMessage}`,
      email: contactEmail,
    };
    await axios
      .post(`${APIURL}/tickets/apprequests/`, body)
      .then((response) => {
        toast.success("We got your request! We will get in touch soon");
        setDeveloperMessage("");
      })
      .catch((error) => {
        if (error.response.data.error === "Max3Requests") {
          toast.error("You can only have 3 active requests at a time");
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  return (
    <div className="apps-developers-section">
      <DefaultModalSection
        sectionIcon={"code"}
        title={"Developer Section"}
        subTitle={"Create and publish your app on SleekTab"}
        description={
          "If you are a developer and want to publish your app or contribute to SleekTab in any way, please let us know and we will get in touch. We are open to all ideas! "
        }
        formHeader="Developer form"
        handleFormSubmit={handleSubmit}
        disabledSubmit={developerMessage.length < 20 || contactEmail.length < 3}
      >
        <TextArea
          input={developerMessage}
          placeholder={
            "Your idea, proposal, question ..."
          }
          maxLength={1000}
          minLength={20}
          label={"Message"}
          onChange={(input) => setDeveloperMessage(input)}
        />
        <InputField
          input={contactEmail}
          placeholder={"youre@weak.com"}
          label={"Leave us an email to get in touch"}
          onChange={(input) => setContactEmail(input)}
        />
      </DefaultModalSection>
    </div>
  );
};
