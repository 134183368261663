import { CookiesPolicy, PrivacyPolicy, TermsOfService } from "./legalPolicies";

export const getLegalContent = (section: string) => {
  switch (section) {
    case "cookies":
      return CookiesPolicy;
    case "termsofservice":
      return TermsOfService;
    case "privacy":
      return PrivacyPolicy;
    default:
      break;
  }
};
