import axios from "axios";
import "./WallpaperMarket.scss";
import { Button } from "../../Button/Button";
import { InputField } from "../../InputField/InputField";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { DefaultModalSection } from "../../SectionsModal/DefaultModalSection/DefaultModalSection";
import { allowedUsersToUpload, uploadWallpaper } from "./wallpaperMarketUtils";
import { APIURL } from "../../../utils/apiUtils";
import {
  WallpaperPackItem,
  WallpaperPackType,
} from "../../WallpaperPackItem/WallpaperPackItem";

type WallpaperMarketPropsType = {};

export const WallpaperMarket = ({}: WallpaperMarketPropsType) => {
  const [packName, setPackName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [availableWalpapersPacks, setAvailableWallpapersPacks] = useState([]);

  const { userInfo } = useSelector((state: RootState) => state.auth);

  const getAvailableWallpapers = async () => {
    setIsLoading(true)
    await axios
      .get(`${APIURL}/wallpapers`)
      .then((response) => {
        setAvailableWallpapersPacks(response.data.availableWallpaperPacks);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error getting mywallpaper", error);
        setIsLoading(false)
      });
  };

  const handleClickedPack = async (id: any) => {
    await axios
      .post(`${APIURL}/wallpapers/library`, { wallpaperId: id })
      .then((response) => {
        getAvailableWallpapers();
      })
      .catch((error) => {
        console.error("Error installing wallpaper", error);
      });
  };

  useEffect(() => {
    getAvailableWallpapers();
  }, []);

  const handleUploadWallpaper = () => {
    if (userInfo) {
      uploadWallpaper(userInfo.email, packName, isLive);
    }
  };

  return (
    <div className="wallpaper-market-container">
      <DefaultModalSection
        sectionIcon={"store"}
        sectionHeader
        title={"Wallpapers market"}
        subTitle="Discover and share wallpapers with the community"
      />
      <div className="wallpaper-market-list">
        {isLoading ? (
          <div className="wallpaper-loading-container">
            <div className="wallpaper-settings-library-loading border-radius-s skeleton-loading "></div>
            <div className="wallpaper-settings-library-loading border-radius-s skeleton-loading "></div>
            <div className="wallpaper-settings-library-loading border-radius-s skeleton-loading "></div>
            <div className="wallpaper-settings-library-loading border-radius-s skeleton-loading "></div>
          </div>
        ) : (
          availableWalpapersPacks.map((pack: WallpaperPackType, i: number) => (
            <WallpaperPackItem
              key={pack.id}
              wallpaperPack={pack}
              banner={pack.ownedByUser ? "Installed" : undefined}
              clicked={() => handleClickedPack(pack.id)}
            />
          ))
        )}
      </div>
      {/* {userInfo && allowedUsersToUpload.includes(userInfo.email) && (
        <div className="wallpaper-market-upload">
          <h1>Admin upload</h1>
          <InputField
            input={packName}
            placeholder={"gg"}
            label={"Name as in folder"}
            onChange={(input) => setPackName(input)}
          />
          <div className="wallpaper-market-upload-islive">
            <p>Is live ?</p>
            <input
              type="checkbox"
              checked={isLive}
              onChange={() => setIsLive(!isLive)}
            />
          </div>

          <Button label={"Upload"} onClick={handleUploadWallpaper} />
        </div>
      )} */}
    </div>
  );
};
