import { AuthForm } from "../AuthForm/AuthForm";
import { PresentationNavbar } from "../PresentationScreen/PresentationNavbar/PresentationNavbar";
import "./LoginScreen.scss";

type LoginScreenPropsType = {};

export const LoginScreen = ({}: LoginScreenPropsType) => {
  return (
    <div className="login-screen-form-container">
      <PresentationNavbar loginScreen />
      <AuthForm />
      <p className="font-size-xs mobile-info-message">
        <strong>Mobile not available yet. Please use desktop</strong>
      </p>
      <p className="font-size-xs copyright-message ">
        <strong>Copyright © 2024 MillerioTech. All rights reserved.</strong>
      </p>
    </div>
  );
};
