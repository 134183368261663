import {
  availableApps,
  availableWidgets,
  bottomCenter,
  topCenter,
} from "../appsUtils";

import { useState } from "react";
import { getSvgByName } from "../../../assets/getIcon";

import "./Dock.scss";
import { Link } from "react-router-dom";

type DockPropsType = {
  openApp?: string;
  onClick: (item: string) => void;
};
export const Dock = ({ onClick, openApp }: DockPropsType) => {
  return (
    <div className={`dock-container main-app-background `}>
      <div className="dock-container-apps">
        {availableApps.map((item, i) => {
          return (
            <button
              key={`${item.id}${i}`}
              onClick={() => {
                openApp === item.id ? onClick("undefined") : onClick(item.id);
              }}
              title={item.title}
              className={`dock-button ${
                openApp === item.id ? "dock-button__open" : ""
              } `}
            >
              {item.icon}
            </button>
          );
        })}
      </div>
      <div className="dock-container-apps">
        <Link
          title={"Customization"}
          className={`dock-button dock-button-settings `}
          to={"/customization/backgrounds"}
        >
          {getSvgByName("theme")}
        </Link>
        <Link
          title={"Market"}
          className={`dock-button dock-button-settings `}
          to={"/market/backgrounds"}
        >
          {getSvgByName("store")}
        </Link>
        <Link
          title={"Open Dashboard"}
          className={`dock-button dock-button-settings dock-button-dashboard `}
          to={"/dashboard/account"}
        >
          {getSvgByName("settings")}
        </Link>
      </div>
    </div>
  );
};
